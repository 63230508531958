import React from "react";
import { Button, Group } from "@mantine/core";
import { T, Translations } from "../../helpers/translator";
import { IconArrowBarLeft, IconArrowBarRight, IconDeviceFloppy } from "@tabler/icons-react";

const ActionButtons = ({
	step,
	localSteps,
	modelSendToServer,
	updateItem,
	otherFields,
	filesComponent,
	colorScheme,
	canGoForward,
	lang
}) => {
	const isLastStep = step === localSteps.length;

	return (
		<Group justify="center" mb={20} grow>
			<Button
				variant="light"
				color="orange"
				disabled={step === 1}
				leftSection={
					<IconArrowBarLeft size={20} color={colorScheme === "dark" ? "white" : "black"} />
				}
				onClick={() =>
					updateItem({
						modelSendToServer,
						routeState: "backward",
						otherFields,
						filesComponent
					})
				}
			>
				{T("previousStep", lang)}
			</Button>

			{step === 45 ? (
				<Button
					variant="light"
					color="green"
					rightSection={
						<IconDeviceFloppy size={20} color={colorScheme === "dark" ? "white" : "black"} />
					}
					onClick={() =>
						updateItem({
							modelSendToServer,
							routeState: "stay",
							otherFields,
							orangeLine: true,
							filesComponent
						})
					}
				>
					{T("update", lang)}
				</Button>
			) : (
				<Button
					variant="light"
					color="green"
					disabled={isLastStep || !canGoForward}
					rightSection={
						<IconArrowBarRight size={20} color={colorScheme === "dark" ? "white" : "black"} />
					}
					onClick={() =>
						updateItem({
							modelSendToServer,
							routeState: "forward",
							otherFields,
							filesComponent
						})
					}
				>
					{T("nextStep", lang)}
				</Button>
			)}
		</Group>
	);
};

export default ActionButtons;
