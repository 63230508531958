import { useContext, createContext, useState, useEffect } from "react";
import { useUser } from "./userContext";
import { io } from "socket.io-client";
import config from "../config";
import { useLang } from "./language";
import { Avatar } from "@mantine/core";
import { notifications } from "@mantine/notifications";

// Create the Context
const SocketContext = createContext();

// Context Provider Component
export const SocketProvider = ({ children }) => {
	const [connectedUsers, setConnectedUsers] = useState([]);
	const [myNotifications, setMyNotifications] = useState([]);
	const [newNotification, setNewNotification] = useState([]);
	const [socket, setSocket] = useState(null);
	const [myMessages] = useState([]);
	const { user, setUser } = useUser();
	const { lang } = useLang();

	const showNotification = (notification) => {
		if (!("Notification" in window)) {
			console.error("This browser does not support desktop notifications.");
			return;
		}

		// Request permission if not granted
		if (Notification.permission !== "granted") {
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					showNotification(notification);
				}
			});
			return;
		}

		// Create a new notification instance
		const not = new Notification(notification.name?.[lang], {
			body: notification.desc?.[lang]
		});

		// Close the notification after 10 seconds
		setTimeout(() => {
			not.close();
		}, 10 * 1000);

		// Attach event listener to the notification instance
		not.addEventListener("click", () => {
			const url = config.api.MAIN_APP_URL + "/" + notification.route;
			console.log("Opening URL:", url);
			window.open(url, "_blank");
		});
	};

	let granted = false;
	// show an error message
	const showError = () => {
		const error = document.querySelector(".error");
		if (error) {
			error.style.display = "block";
			error.textContent = "You blocked the notifications";
		}
	};

	async function ConnectSocket(userFromServer) {
		let user = JSON.parse(JSON.stringify(userFromServer));
		const socket = io(`${config.api.API_URL}?userId=${user._id}`, {
			transports: ["websocket"]
		});
		setSocket(socket);
	}

	useEffect(() => {
		if (socket) {
			// reconnect on error
			socket.on("connect_error", () => {
				setTimeout(() => {
					socket.connect();
				}, 1000);
			});
			// set user socket and update on database
			socket.on("connect", () => {
				// update user with socket id
				user.socketIds = [...user.socketIds, socket.id];
				setUser(user);
				// user.socketId = socket.id;
				socket.emit("registerUser", user, socket.id);
			});
			socket.on("disconnect", () => {
				setConnectedUsers(connectedUsers);
			});
			// setUser(user);

			socket.on("getConnectedUsers", (users) => {
				setConnectedUsers(users);
			});

			// socket.emit('myMessages', user);
			return () => {
				if (socket) {
					socket.off("connect");
					socket.off("disconnect");
					socket.off("updateNotification");
					socket.off("getConnectedUsers");
				}
			};
		}
		// eslint-disable-next-line
	}, [socket, newNotification.date]);

	useEffect(() => {
		if (socket) {
			socket.on("newNotification", (notification) => {
				let Notification = JSON.parse(notification);
				// Use functional state update to avoid stale state issues
				setMyNotifications((prevNotifications) => [Notification, ...prevNotifications]);

				notifications.show({
					title: Notification.name?.[lang],
					message: Notification.desc?.[lang],
					color:
						Notification.importance === "high"
							? "red"
							: Notification.importance === "medium"
								? "orange"
								: "blue"
				});

				showNotification(Notification);
			});

			socket.on("notificationRemoved", (data) => {
				let ITEM;
				try {
					ITEM = JSON.parse(data);
				} catch (error) {
					console.log("Error parsing data:", error);
					return;
				}

				setMyNotifications((prevNotifications) =>
					prevNotifications.filter((notification) => notification._id !== ITEM.notificationId)
				);
			});

			// Cleanup the event listener on unmount
			return () => {
				socket.off("newNotification");
				socket.off("notificationRemoved");
			};
		}
		// eslint-disable-next-line
	}, [socket]);

	useEffect(() => {
		if (socket && newNotification) {
			// send to server both to send others and register to DB
			socket.emit("createNotification", JSON.stringify(newNotification));
		}
		return () => {
			if (socket) {
				socket.off("createNotification");
			}
		};
		// eslint-disable-next-line
	}, [newNotification.date]);

	useEffect(() => {
		(async () => {
			if (Notification.permission === "granted") {
				granted = true;
			} else if (Notification.permission !== "denied") {
				let permission = await Notification.requestPermission();
				granted = permission === "granted" ? true : false;
			}

			ConnectSocket(user);
		})();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setMyNotifications(user.notifications);
	}, [user]);

	const DissconnectSocket = () => {
		console.log("DissconnectSocket");
		socket.disconnect();
	};

	return (
		<SocketContext.Provider
			value={{
				connectedUsers,
				socket,
				myNotifications,
				setMyNotifications,
				myMessages,
				setNewNotification,
				newNotification
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

// Custom Hook to Use the Context
export const useSocket = () => {
	return useContext(SocketContext);
};
