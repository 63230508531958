import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import { useLang } from "../../helpers/language";
import config from "../../config";
import classes from "../Tabs.module.css";
import Swal from "sweetalert2";
import { T, Translations } from "../../helpers/translator";
import ActionButtons from "./actionButton.jsx";
import InProgressBypassButtons from "./inProgressBypassButtons.jsx";

//! import modules
import Input from "../modules/input";
import { FilesComponent } from "../modules/filesComponent";
import { TableComponent } from "../modules/tableComponent";
import { ToDoComponent } from "../modules/toDoComponent";
import { CartComponent } from "../modules/cartComponent";
import { InteractiveTimeComponent } from "../modules/interactiveTimeComponent";
import "date-fns";
import { useUser } from "../../helpers/userContext";

import {
	IconArrowBack,
	IconArrowBarLeft,
	IconArrowBarRight,
	IconCalendarDown,
	IconCalendarUp,
	IconCircle,
	IconDeviceFloppy,
	IconHourglass,
	IconList,
	IconPhone,
	IconPhoto,
	IconRecordMail,
	IconRotateDot,
	IconShare3,
	IconSquareRoundedCheck,
	IconTable,
	IconTableColumn
} from "@tabler/icons-react";
import {
	Group,
	Button,
	Title,
	Card,
	Tabs,
	Grid,
	CardSection,
	useMantineColorScheme,
	useMantineTheme,
	Text,
	Flex,
	Divider,
	Collapse,
	ScrollArea,
	Avatar,
	Modal,
	Progress,
	Checkbox
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TimelineComponent } from "../modules/timelineComponent";
import moment from "moment";
import "moment/locale/hy-am";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Link } from "react-router-dom";
import { useSocket } from "../../helpers/socket";

const parsePhoneNumber = (number) => `${number?.split(/[-*\/\s]+/).join("")}`;

export default function StepPage({
	inputs,
	simplePage,
	filesComponent,
	tableComponent,
	toDoComponent,
	cartComponent,
	timelineComponent,
	route,
	projectId,
	setStep,
	stepId,
	steps,
	step,
	startDate,
	endDate,
	activeStep,
	setActiveStep,
	localSteps,
	modelSendToServer,
	setBusy,
	isComponentBusy,
	setComponentBusy,
	connectedRoute,
	connectedData,
	connectedID,
	History,
	selectedTimeInterval,
	setSelectedTimeInterval,
	timeLineSelected,
	setTimeLineSelected,
	historySteps,
	otherFields,
	permissions,
	openedInner,
	responsible,
	injectedComponent,
	allResponsible,
	disabledSteps
}) {
	const { lang } = useLang();
	const { user } = useUser();
	const history = useHistory();
	const { setNewNotification } = useSocket();
	const [canGoForward, setCanGoForward] = useState(Boolean);

	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState([]);
	const [opened, { open, close }] = useDisclosure(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const location = useLocation();
	let formatedSearch = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const [reversedHistorySteps, setReversedHistorySteps] = useState([]);

	if (lang === "hy") moment.locale("hy-am");
	if (lang === "ru") moment.locale("ru");
	if (lang === "en") moment.locale("en-gb");
	if (lang === "tr") moment.locale("tr");
	if (lang === "ar") moment.locale("ar");
	if (lang === "zh") moment.locale("zh-cn");
	if (lang === "fa") moment.locale("fa");
	if (lang === "he") moment.locale("he");
	if (lang === "fr") moment.locale("fr");
	if (lang === "es") moment.locale("es");
	if (lang === "pt") moment.locale("pt");

	const [isDesktop, setDesktop] = useState(false);

	function setIsDesktop() {
		if (window.innerWidth > 1000) {
			setDesktop(true);
		} else if (window.innerWidth <= 1000) {
			setDesktop(false);
		}
	}

	// get windows size
	useEffect(() => {
		setReversedHistorySteps([...historySteps].reverse());
		setIsDesktop();
		window.addEventListener("resize", setIsDesktop);
		return () => window.removeEventListener("resize", setIsDesktop);
	}, []);

	useEffect(() => {
		setReversedHistorySteps([...historySteps].reverse());
	}, [historySteps]);

	function concatAndReplaceById(arr1, arr2) {
		// Create a map of elements from arr1 using _id as key
		const map = arr1.reduce((acc, curr) => {
			acc[curr._id] = curr;
			return acc;
		}, {});

		// Iterate through arr2, replace elements if _id matches, otherwise, add it to the map
		const result = arr2.reduce((acc, curr) => {
			if (map[curr._id]) {
				acc.push(map[curr._id]);
			} else {
				acc.push(curr);
			}
			return acc;
		}, []);

		// Concatenate remaining elements from arr1
		for (const id in map) {
			if (!result.find((item) => item._id === id)) {
				result.push(map[id]);
			}
		}

		return result;
	}

	const UpdateStep = async ({
		step,
		modelSendToServer,
		routeState = "forward",
		otherFields,
		orangeLine = false,
		filesComponent
	}) => {
		let SkipNextStepCount, SkipPreviousStepCount;
		let Steps = [];
		let now = new Date();
		// uploadDate equals to now subtract 1 second;
		let uploadDate = new Date(now.getTime() - 1000);
		let localNextStep, localPreviousStep, localCurrentStep;
		if ((localSteps[step]?.disabled || localSteps[step]?.bypass) && !orangeLine) {
			// Loop forward to determine how many steps to skip due to disabled or bypass
			for (let i = step; i < localSteps.length; i++) {
				if (localSteps[i].disabled) {
					SkipNextStepCount = i - step + 1; // Always skip disabled steps
				} else if (localSteps[i].bypass) {
					SkipNextStepCount = i - step + 1; // Skip bypassed steps as well
				} else {
					break;
				}
			}
		}

		if (
			localSteps[step - 2] &&
			(localSteps[step - 2].disabled || localSteps[step - 2].bypass) &&
			!orangeLine
		) {
			// Loop backward to determine how many steps to skip due to disabled or bypass
			for (let i = step - 2; i >= 0; i--) {
				if (localSteps[i].disabled) {
					SkipPreviousStepCount = step - i; // Always skip disabled steps
				} else if (localSteps[i].bypass) {
					SkipPreviousStepCount = step - i; // Skip bypassed steps as well
				} else {
					break;
				}
			}
		}

		if (routeState === "backward") {
			localCurrentStep = step - 1;
			if (SkipPreviousStepCount) {
				localPreviousStep = step - (SkipPreviousStepCount + 1);
			} else {
				localPreviousStep = step - 2;
			}
			try {
				let Previous = await API.update(
					config.api.step,
					modelSendToServer.steps?.[localPreviousStep]._id,
					{
						status: "inProgress",
						startDate: startDate && startDate.length > 0 ? [...startDate, now] : [now],
						history: [
							...modelSendToServer.steps?.[localPreviousStep].history,
							{
								status: "inProgress",
								date: now,
								user: user._id
							}
						]
					}
				);
				let Current = await API.update(
					config.api.step,
					modelSendToServer.steps?.[localCurrentStep]._id,
					{
						endDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
						status: "notStarted",
						...otherFields,
						history: [
							...modelSendToServer.steps?.[localCurrentStep].history,
							{
								status: "notStarted",
								date: now,
								user: user._id
							}
						]
					}
				);
				if (Previous && Current) {
					if (filesComponent) {
						for (const component of filesComponent) {
							if (component.file || (component.files && component.files.length > 0)) {
								try {
									await API.upload(
										Current._id,
										component.files || component.file,
										lang,
										component.destiny,
										component.thumbSizeWidth,
										component.thumbSizeHeight,
										component.responsiveImages,
										component.alt,
										setProgress,
										uploadDate,
										setLoading,
										uploadedFiles,
										setUploadedFiles
									);
								} catch (error) {
									console.error(error);
								}
							} else {
								console.log("upload is not available");
							}
						}
					}
					setStep(step - 1);
					setActiveStep(step - 1);
					return [Previous, Current];
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong! 2"
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong! 3"
				});
			}
		} else if (routeState === "forward") {
			localCurrentStep = step - 1;
			let usersToNotifyIndex;
			if (SkipNextStepCount) {
				localNextStep = step + SkipNextStepCount;
			} else {
				localNextStep = step;
			}
			try {
				// test if next step exists
				let [ifNextStepDoesExists] = await API.get({
					route: config.api.step,
					query: {
						project_id: projectId,
						queue: localNextStep + 1
					}
				});
				// if exists update it, if not create it
				if (ifNextStepDoesExists) {
					usersToNotifyIndex = localNextStep + 1;
					let NextStep = await API.update(config.api.step, ifNextStepDoesExists._id, {
						status: "inProgress",
						queue: localNextStep + 1,
						project_id: projectId,
						bypass: localSteps[localNextStep].bypass,
						// disabled: localSteps[localNextStep].disabled,
						name: {
							en: localSteps[localNextStep].name.en,
							ru: localSteps[localNextStep].name.ru,
							hy: localSteps[localNextStep].name.hy
						},
						startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
						createdByUser: user?._id,
						history: [
							...modelSendToServer.steps?.[localNextStep - 1]?.history,
							{
								status: "inProgress",
								date: now,
								user: user?._id
							}
						]
					});
					Steps.push(NextStep);
				} else {
					if (SkipNextStepCount) {
						for (let i = 0; i <= SkipNextStepCount; i++) {
							usersToNotifyIndex = step + i + 1;
							let NextSteps = await API.post(config.api.step, {
								status: i === SkipNextStepCount || i === 0 ? "inProgress" : "notStarted",
								queue: step + i + 1,
								project_id: projectId,
								name: {
									en: localSteps[step + i].name.en,
									ru: localSteps[step + i].name.ru,
									hy: localSteps[step + i].name.hy
								},
								bypass: localSteps[step + i].bypass,
								// disabled: localSteps[step + i].disabled,
								startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
								createdByUser: user._id,
								history: [
									{
										status: i === SkipNextStepCount || i === 0 ? "inProgress" : "notStarted",
										date: now,
										user: user._id
									}
								]
							});
							Steps.push(NextSteps);
						}
					} else {
						usersToNotifyIndex = localNextStep + 1;
						let NextStep = await API.post(config.api.step, {
							status: "inProgress",
							queue: localNextStep + 1,
							project_id: projectId,
							bypass: localSteps[localNextStep].bypass,
							// disabled: localSteps[localNextStep].disabled,
							name: {
								en: localSteps[localNextStep].name.en,
								ru: localSteps[localNextStep].name.ru,
								hy: localSteps[localNextStep].name.hy
							},
							startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
							createdByUser: user._id,
							history: [
								{
									status: "inProgress",
									date: now,
									user: user._id
								}
							]
						});
						Steps.push(NextStep);
					}
				}

				let Current = await API.update(
					config.api.step,
					modelSendToServer.steps?.[localCurrentStep]?._id,
					{
						status: "done",
						bypass: localSteps[localCurrentStep]?.bypass,
						// disabled: localSteps[localCurrentStep]?.disabled,
						endDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
						...otherFields,
						history: [
							...modelSendToServer.steps?.[localCurrentStep]?.history,
							{
								status: "done",
								date: now,
								user: user._id
							}
						]
					}
				);

				let usersToNotify = allResponsible
					?.filter((step) => step.index === usersToNotifyIndex)
					.flatMap((step) => step.responsible);

				let newNotification = {
					date: new Date(),
					name: Translations.stepStatus,
					desc: {
						en: `${T("stepStatusChangedByUser", "en")} ${user.name["en"]}`,
						ru: `${T("stepStatusChangedByUser", "ru")} ${user.name["ru"]}`,
						hy: `${T("stepStatusChangedByUser", "hy")} ${user.name["hy"]}`,
						tr: `${T("stepStatusChangedByUser", "tr")} ${user.name["tr"]}`,
						ar: `${T("stepStatusChangedByUser", "ar")} ${user.name["ar"]}`,
						zh: `${T("stepStatusChangedByUser", "zh")} ${user.name["zh"]}`,
						fa: `${T("stepStatusChangedByUser", "fa")} ${user.name["fa"]}`,
						he: `${T("stepStatusChangedByUser", "he")} ${user.name["he"]}`,
						fr: `${T("stepStatusChangedByUser", "fr")} ${user.name["fr"]}`,
						es: `${T("stepStatusChangedByUser", "es")} ${user.name["es"]}`,
						pt: `${T("stepStatusChangedByUser", "pt")} ${user.name["pt"]}`
					},
					importance: "high",
					route: `${config.api.project}/${projectId}`,
					createdByUser: user._id,
					targetUsers: usersToNotify?.map((user) => ({
						user: user._id,
						read: false
					}))
				};

				setNewNotification(newNotification);

				Steps.push(Current);
				if (Steps.length > 0) {
					if (filesComponent) {
						for (const component of filesComponent) {
							if (component.file || (component.files && component.files.length > 0)) {
								try {
									await API.upload(
										Current._id,
										component.files || component.file,
										lang,
										component.destiny,
										component.thumbSizeWidth,
										component.thumbSizeHeight,
										component.responsiveImages,
										component.alt,
										setProgress,
										uploadDate,
										setLoading,
										uploadedFiles,
										setUploadedFiles
									);
								} catch (error) {
									console.error(error);
								}
							} else {
								console.log("upload is not available");
							}
						}
					}
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong! 1"
					});
				}
				return Steps;
			} catch (error) {
				console.error(error);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong! 5"
				});
			}
		} else if (routeState === "stay") {
			if (orangeLine) {
				try {
					let Current = await API.update(config.api.step, modelSendToServer.steps?.[step - 1]._id, {
						status: "done",
						startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
						createdByUser: user._id,
						...otherFields,
						history: [
							...modelSendToServer.steps?.[step - 1].history,
							{
								status: "done",
								date: now,
								user: user._id
							}
						]
					});
					Steps.push(Current);
					if (Current) {
						Swal.fire({
							icon: "success",
							title: "Saved!",
							text: "Your changes have been saved!"
						});
						setBusy(true);
						return Steps;
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong! 6"
						});
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong! 7"
					});
				}
			} else {
				try {
					// this is first updating to server the current step to done
					let CurrentDone = await API.update(
						config.api.step,
						modelSendToServer.steps?.[step - 1]._id,
						{
							status: "done",
							endDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
							...otherFields,
							history: [
								...modelSendToServer.steps?.[step - 1].history,
								{
									status: "done",
									date: now,
									user: user._id
								}
							]
						}
					);
					// this is second updating to server the current step to inProgress
					let Current = await API.update(config.api.step, modelSendToServer.steps?.[step - 1]._id, {
						status: "inProgress",
						startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
						createdByUser: user._id,
						...otherFields,
						history: [
							...modelSendToServer.steps?.[step - 1].history,
							{
								status: "inProgress",
								date: now,
								user: user._id
							}
						]
					});
					Steps.push(Current);
					if (Current) {
						Swal.fire({
							icon: "success",
							title: "Saved!",
							text: "Your changes have been saved!"
						});
						setBusy(true);
						return Steps;
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong! 6"
						});
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong! 7"
					});
				}
			}
		} else if (routeState === "reopen") {
			try {
				let Current = await API.update(config.api.step, modelSendToServer.steps?.[step - 1]._id, {
					status: "inProgress",
					startDate: endDate && endDate.length > 0 ? [...endDate, now] : [now],
					createdByUser: user._id,
					...otherFields,
					history: [
						...modelSendToServer.steps?.[step - 1].history,
						{
							status: "inProgress",
							date: now,
							user: user._id
						}
					]
				});
				Steps.push(Current);
				if (Current) {
					Swal.fire({
						icon: "success",
						title: "Saved!",
						text: "Your changes have been saved!"
					});
					setBusy(true);
					return Steps;
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong! 6"
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong! 7"
				});
			}
		}
	};

	const getIconColor = () => (colorScheme === "dark" ? "white" : "black");

	const hasPermissionToUpdate = () =>
		user.ananunaki ||
		(permissions && permissions.find((perm) => perm.name === "step" + step)?.update);

	const isCurrentStepInProgress = () => {
		const current = modelSendToServer.steps?.[step - 1];
		return (
			(current?.bypass || current?.disabled) &&
			current?.history?.length > 0 &&
			current.history.slice(-1)[0]?.status === "inProgress"
		);
	};

	const isPreviousBypassStepDone = () => {
		const prev = modelSendToServer.steps?.[step - 2];
		return (
			(modelSendToServer.steps?.[step - 1]?.bypass ||
				modelSendToServer.steps?.[step - 1]?.disabled) &&
			(prev?.bypass || prev?.disabled) &&
			prev?.history?.slice(-1)[0]?.status === "done"
		);
	};

	const shouldShowReopenButton = () => {
		const current = modelSendToServer.steps?.[step - 1];
		return (
			current?.bypass &&
			!modelSendToServer.steps?.[step - 2]?.bypass &&
			!modelSendToServer.steps?.[step]?.bypass &&
			current?.history?.slice(-1)[0]?.status === "done"
		);
	};

	const updateItem = async ({
		modelSendToServer,
		routeState,
		otherFields,
		orangeLine,
		filesComponent
	}) => {
		Swal.fire({
			title: T("areYouSure", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let Steps = await UpdateStep({
						step,
						modelSendToServer,
						routeState,
						otherFields,
						orangeLine,
						filesComponent
					});
					if (Steps?.length > 0) {
						modelSendToServer.steps = concatAndReplaceById(Steps, modelSendToServer.steps);
						// looping through all steps and updating modelSendToServer.disabledSteps from modelSendToServer.steps.steps.disabled if true
						modelSendToServer.steps = modelSendToServer.steps.map((step) => {
							if (step.disabled) {
								step.disabled = false;
							}
							return step;
						});
						try {
							let localProject = await API.update(route, projectId, modelSendToServer);
							if (localProject) {
								setBusy(true);
								if (routeState === "forward") {
									history.push(`/project/steps/${projectId}`);
								}
							} else {
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: "Something went wrong in updating!"
								});
							}
						} catch (error) {
							console.error(error);
						}
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong in updating 12 !"
						});
					}
				} catch (error) {
					console.error(error);
				}
			}
		});
	};

	const HandlePermissionCheck = (input) => {
		if (input) {
			if (user?.ananunaki) {
				input.crud = {
					create: true,
					read: true,
					update: true,
					delete: true
				};
				return input;
			}
			if (permissions) {
				let foundedInput = permissions?.find((inp) => inp.name === input?.permissionModel);
				if (foundedInput && foundedInput.read) {
					input.crud = {
						create: foundedInput.create !== undefined ? foundedInput.create : false,
						read: foundedInput.read !== undefined ? foundedInput.read : false,
						update: foundedInput.update !== undefined ? foundedInput.update : false,
						delete: foundedInput.delete !== undefined ? foundedInput.delete : false
					};
					return input;
				}
			} else {
				console.error("Permission not found");
			}
		}
	};

	useEffect(() => {
		if (loading) {
			open();
		}
	}, [loading]);

	useEffect(() => {
		let SkipNextStepCount = 0;

		// Find how many steps to skip (disabled or bypass)
		for (let i = step; i < localSteps.length; i++) {
			if (localSteps[i].disabled || localSteps[i].bypass) {
				SkipNextStepCount = i - step + 1;
			} else {
				break; // Stop at first valid step
			}
		}

		const nextStepIndex = step + SkipNextStepCount + 1;

		const hasResponsible =
			allResponsible?.find(
				(item) => item?.index === nextStepIndex && item.responsible.length > 0
			) !== undefined;

		setCanGoForward(hasResponsible);
		// eslint-disable-next-line
	}, [allResponsible]);

	return (
		<>
			<Modal opened={opened} onClose={close} centered withCloseButton={false} title="Loading...">
				{filesComponent?.length > 0 &&
					filesComponent.map((component, index) => {
						if (component.file) {
							if (
								uploadedFiles?.find(
									(uploadedFile) => uploadedFile?.name === component.file?.[0]?.name
								)
							) {
								return (
									<Flex key={index} direction={"column"} align={"center"} justify={"center"}>
										<Text>{component.placeholder}</Text>

										<Flex direction={"row"} w={"100%"} align={"center"}>
											<Text
												style={{
													color: "green"
												}}
											>
												{index + 1}
											</Text>
											<Text ml={20} w={"300px"}>
												{component.file?.[0]?.name.length > 30
													? `${component.file?.[0]?.name.substring(0, 30)}...`
													: component.file?.[0]?.name}
											</Text>
											<Checkbox checked={true} indeterminate={false} ml={20} color="green" />
										</Flex>
									</Flex>
								);
							}
							return (
								<Flex key={index} direction={"column"} align={"center"} justify={"center"}>
									<Text>{component.placeholder}</Text>
									<Flex direction={"row"} w={"100%"} align={"center"}>
										<Text ml={20} w={"300px"}>
											{component.file[0]?.name.length > 30
												? `${component.file[0]?.name.substring(0, 30)}...`
												: component.file[0]?.name}
										</Text>
										<Progress
											value={progress.name === component.file?.[0]?.name ? progress.value : 0}
											ml={20}
											color="green"
											w={"100%"}
										/>
									</Flex>
								</Flex>
							);
						}
						if (component.files && component.files.length > 0) {
							return (
								<Flex key={index} direction={"column"} align={"center"} justify={"center"}>
									<Text>{component.placeholder}</Text>
									{component.files.map((file, ind) => {
										if (uploadedFiles?.find((uploadedFile) => uploadedFile?.name === file.name)) {
											return (
												<Flex key={ind} direction={"row"} w={"100%"} align={"center"}>
													<Text
														style={{
															color: "green"
														}}
													>
														{ind + 1}
													</Text>
													<Text ml={20} w={"300px"}>
														{file.name}
													</Text>
													<Checkbox checked={true} indeterminate={false} ml={20} color="green" />
												</Flex>
											);
										}
										return (
											<Flex key={ind} direction={"row"} w={"100%"} align={"center"}>
												<Text
													style={{
														color: "orange"
													}}
												>
													{ind + 1}
												</Text>
												<Text ml={20} w={"300px"}>
													{file.name}
												</Text>
												<Progress
													value={progress.name === file?.name ? progress.value : 0}
													ml={20}
													color="green"
													w={"100%"}
												/>
											</Flex>
										);
									})}
								</Flex>
							);
						}
					})}
			</Modal>
			<Flex wrap={"wrap"} align={"center"} gap={20} justify={"center"}>
				<ScrollArea w={"100%"} scrollbarSize={8} scrollHideDelay={2500}>
					<Flex
						direction={"column"}
						w={"100%"}
						style={{ overflow: "hidden" }}
						justify={"center"}
						align={"center"}
					>
						<Collapse
							style={{ flex: "1 1 auto" }}
							in={openedInner}
							transitionDuration={250}
							transitionTimingFunction="ease-in-out"
						>
							{/* <Title order={4} style={{ marginBottom: 20 }}>
                {T('timeline')}
              </Title> */}
							<ScrollArea w={"100%"} pb={1} scrollbars="x">
								<Flex direction={"row"}>
									{reversedHistorySteps?.map((item, index) => {
										return (
											<Card
												key={index}
												// title={item.title}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setTimeLineSelected(reversedHistorySteps.length - 1 + index);
													setSelectedTimeInterval(item);
												}}
												w={200}
												style={{
													cursor: "pointer",
													margin: "5px 20px",
													boxSizing: "border-box",
													transition: "all .25s ease-in-out",
													background:
														timeLineSelected === reversedHistorySteps.length - 1 + index
															? "rgba(25,113,194, 0.2)"
															: "rgb(46,46,46)"
												}}
											>
												<Text mb={14}>
													{item.endDate
														? `${T("update", lang)} - ${reversedHistorySteps.length - index}`
														: T("rightNow", lang)}
												</Text>

												<Flex mb={10} align={"center"}>
													<IconCalendarUp size={22} style={{ marginRight: 10 }} />
													<Text m={0} c="dimmed" size="xs">
														{T("starts", lang)}
														<Divider />
														{moment(item.startDate).format("DD MMM, HH:mm:ss")}
													</Text>
												</Flex>

												<Flex mb={10} align={"center"}>
													<IconHourglass size={22} style={{ marginRight: 10 }} />
													<Text m={0} size="xs">
														{T("duration", lang)}
														<Divider />
														{item.endDate ? (
															<>
																{moment(item.endDate).diff(moment(item.startDate), "minutes")}{" "}
																{T("minutes", lang)}
															</>
														) : (
															<InteractiveTimeComponent historySteps={historySteps} />
														)}
													</Text>
												</Flex>

												{item.endDate && (
													<Flex mb={10} align={"center"}>
														<IconCalendarDown size={22} style={{ marginRight: 10 }} />
														<Text m={0} c="dimmed" size="xs">
															{T("ends", lang)}
															<Divider />
															{moment(item.endDate).format("DD MMM, HH:mm:ss")}
														</Text>
													</Flex>
												)}

												{item.endDate && (
													<Flex align={"center"}>
														<IconSquareRoundedCheck size={22} style={{ marginRight: 10 }} />
														<Text m={0} size="xs">
															{T("completed", lang)}
															<Divider />
															{moment(item.endDate).fromNow()}
														</Text>
													</Flex>
												)}
											</Card>
										);
									})}
								</Flex>
							</ScrollArea>
						</Collapse>
					</Flex>
				</ScrollArea>
				<ScrollArea w={"100%"} scrollbarSize={8} scrollHideDelay={2500}>
					<Flex
						direction={"column"}
						w={"100%"}
						style={{ overflow: "hidden" }}
						justify={"center"}
						align={"center"}
					>
						{/* {responsible && responsible.length > 0 ? ( */}
						<ScrollArea w={"100%"} mt={20} pb={1} scrollbars="x">
							<Card shadow="sm">
								<Flex justify={"center"} align={"center"} direction={"row"}>
									<Title order={4} mr={20}>
										{T("responsible", lang)}
									</Title>
									{/*  divider */}
									<Divider orientation="vertical" size="sm" />
									{responsible.map((resp, index) => (
										<Group gap={5} wrap="nowrap" mr={10} ml={5} key={index}>
											{index !== 0 && <Divider orientation="vertical" />}
											<Avatar
												src={`${config.api.API_URL}/${resp.uploads?.find((upl) => upl.destiny === "image")?.path}`}
												size={94}
												radius="sm"
											/>
											<div>
												<Link to={`/user/${resp._id}`}>
													<Title c={"cyan"} fz="lg" fw={500} className={classes.name}>
														{resp?.name?.[lang]}
													</Title>
												</Link>

												{resp?.department?.name?.[lang] && (
													<Text fz="xs" tt="uppercase" fw={700} c="dimmed">
														{resp?.department?.name?.[lang]}
													</Text>
												)}

												{resp?.position?.name?.[lang] && (
													<Text fz="xs" tt="uppercase" fw={700} c="dimmed">
														{resp?.position?.name?.[lang]}
													</Text>
												)}

												{resp?.phone && (
													<Group wrap="nowrap" gap={5} mt={5}>
														<Button
															variant="subtle"
															color="teal"
															size="xs"
															ml={5}
															leftSection={<IconPhone size="1.2rem" />}
															onClick={() => window.open(`tel:${parsePhoneNumber(resp?.phone)}`)}
														>
															{resp?.phone
																?.replace("+374", "0")
																.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4")}
														</Button>
													</Group>
												)}

												{resp?.email && (
													<Group wrap="nowrap" gap={5} mt={3}>
														<Button
															variant="subtle"
															color="blue"
															size="xs"
															ml={5}
															leftSection={<IconRecordMail size="1.2rem" />}
															onClick={() => window.open(`mailto:${resp?.email}`)}
														>
															{resp?.email}
														</Button>
													</Group>
												)}
											</div>
										</Group>
									))}
								</Flex>
							</Card>
						</ScrollArea>
						{/* ) : (
							<Title order={4} m={20} c={"red.6"}>
								{T("noResponsible", lang)}
							</Title>
						)} */}
					</Flex>
				</ScrollArea>
				<ScrollArea h={"100%"} w={"100%"} scrollbarSize={8} scrollHideDelay={2500}>
					<Tabs
						defaultValue="first"
						value={formatedSearch.tab}
						variant="unstyled"
						style={{
							width: "100%",
							height: "100%"
						}}
						classNames={classes}
						onChange={(value) => history.push(`/${route}/steps/${projectId}?tab=${value}`)}
					>
						<Tabs.List>
							<Tabs.Tab value="first" color="blue" leftSection={<IconList size={20} />}>
								{T("mainInformation", lang)}
							</Tabs.Tab>
							{filesComponent ? (
								<Tabs.Tab value="second" color="orange" leftSection={<IconPhoto size={20} />}>
									{filesComponent.length === 1 && filesComponent[0] && filesComponent[0].placeholder
										? filesComponent[0].placeholder
										: T("files", lang)}
								</Tabs.Tab>
							) : null}
							{cartComponent ? (
								<Tabs.Tab value="third" color="cyan" leftSection={<IconTableColumn size={20} />}>
									{cartComponent.title
										? cartComponent.title
										: cartComponent.data?.length === 1 && cartComponent.data?.[0].placeholder
											? cartComponent.data?.[0].placeholder
											: T("cart", lang)}
								</Tabs.Tab>
							) : null}
							{toDoComponent ? (
								<Tabs.Tab value="forth" color="violet" leftSection={<IconRotateDot size={20} />}>
									{toDoComponent.length === 1 && toDoComponent[0].placeholder
										? toDoComponent[0].placeholder
										: T("toDo", lang)}
								</Tabs.Tab>
							) : null}
							{tableComponent ? (
								<Tabs.Tab value="sixth" color="green" leftSection={<IconTable size={20} />}>
									{tableComponent.length === 1 && tableComponent[0].placeholder
										? tableComponent[0].placeholder
										: T("table", lang)}
								</Tabs.Tab>
							) : null}
							{timelineComponent ? (
								<Tabs.Tab value="timeline" color="green" leftSection={<IconTable size={20} />}>
									{timelineComponent.length === 1 && timelineComponent[0].placeholder
										? timelineComponent[0].placeholder
										: T("timeline", lang)}
								</Tabs.Tab>
							) : null}
						</Tabs.List>
						<Tabs.Panel value="first">
							<Card
								shadow="sm"
								p="md"
								style={{
									borderTopLeftRadius: 0
								}}
							>
								<Grid
									grow
									p={20}
									style={{
										background: colorScheme === "dark" ? Theme.colors.dark[6] : ""
									}}
								>
									{inputs
										? inputs.map((input, index) => {
												let checkedInput = HandlePermissionCheck(input);
												if (checkedInput) {
													if (checkedInput.lang) {
														if (checkedInput.lang === lang) {
															return (
																<Grid.Col
																	key={index}
																	span={{
																		base: 12,
																		md: checkedInput.width ? checkedInput.width : 6,
																		lg: checkedInput.width ? checkedInput.width : 3
																	}}
																>
																	<Input
																		key={index}
																		id={index}
																		label={checkedInput.label}
																		field={checkedInput.field}
																		placeholder={checkedInput.placeholder}
																		price={checkedInput.price}
																		priceSetter={checkedInput.priceSetter}
																		timeInterval={checkedInput.timeInterval}
																		settimeInterval={checkedInput.settimeInterval}
																		value={checkedInput.value}
																		width={checkedInput.width}
																		setter={checkedInput.setter}
																		type={checkedInput.type}
																		lang={checkedInput.lang}
																		usageType={checkedInput.usageType}
																		optionListValue={checkedInput.optionListValue}
																		radioValue={checkedInput.radioValue}
																		disabled={checkedInput.disabled}
																		selectSomething={checkedInput.selectSomething}
																		leftIcon={checkedInput.leftIcon}
																		rightIcon={checkedInput.rightIcon}
																		crud={checkedInput.crud}
																		textAreaProps={checkedInput.textAreaProps}
																	/>
																</Grid.Col>
															);
														}
													} else {
														return (
															<Grid.Col key={index} span={{ base: 12, md: 6, lg: 3 }}>
																<Input
																	key={index}
																	id={index}
																	label={checkedInput.label}
																	field={checkedInput.field}
																	placeholder={checkedInput.placeholder}
																	price={checkedInput.price}
																	priceSetter={checkedInput.priceSetter}
																	timeInterval={checkedInput.timeInterval}
																	settimeInterval={checkedInput.settimeInterval}
																	value={checkedInput.value}
																	setter={checkedInput.setter}
																	type={checkedInput.type}
																	radioValue={checkedInput.radioValue}
																	width={checkedInput.width}
																	usageType={checkedInput.usageType}
																	optionListValue={checkedInput.optionListValue}
																	disabled={checkedInput.disabled}
																	leftIcon={checkedInput.leftIcon}
																	rightIcon={checkedInput.rightIcon}
																	crud={checkedInput.crud}
																	textAreaProps={checkedInput.textAreaProps}
																/>
															</Grid.Col>
														);
													}
												}
												return null;
											})
										: null}
									{injectedComponent}
								</Grid>

								<CardSection p={20} mt={20}>
									{step === activeStep && hasPermissionToUpdate() && (
										<ActionButtons
											step={step}
											localSteps={localSteps}
											modelSendToServer={modelSendToServer}
											updateItem={updateItem}
											otherFields={otherFields}
											filesComponent={filesComponent}
											colorScheme={colorScheme}
											canGoForward={canGoForward}
											lang={lang}
										/>
									)}

									{isCurrentStepInProgress() && hasPermissionToUpdate() && (
										<InProgressBypassButtons
											step={step}
											localSteps={localSteps}
											updateItem={updateItem}
											modelSendToServer={modelSendToServer}
											otherFields={otherFields}
											filesComponent={filesComponent}
											colorScheme={colorScheme}
											canGoForward={canGoForward}
											lang={lang}
										/>
									)}

									{isPreviousBypassStepDone() &&
										hasPermissionToUpdate() &&
										!isCurrentStepInProgress() && (
											<Group justify="center" mb={20} grow>
												<Button
													variant="light"
													color="orange"
													disabled={!localSteps[step - 2].bypass || localSteps[step - 2].disabled}
													leftSection={<IconArrowBarLeft size={20} color={getIconColor()} />}
													onClick={() =>
														updateItem({
															modelSendToServer,
															routeState: "backward",
															otherFields,
															orangeLine: true,
															filesComponent
														})
													}
												>
													{T("previousStep", lang)}
												</Button>
											</Group>
										)}

									{shouldShowReopenButton() && (
										<Group justify="center" mb={20} grow>
											<Button
												variant="light"
												color="green"
												disabled={localSteps[step].bypass || localSteps[step].disabled}
												rightSection={<IconCircle size={20} color={getIconColor()} />}
												onClick={() =>
													updateItem({
														modelSendToServer,
														routeState: "reopen",
														otherFields,
														orangeLine: true,
														filesComponent
													})
												}
											>
												{T("reopen", lang)}
											</Button>
										</Group>
									)}

									<Button
										variant="light"
										color="red"
										leftSection={<IconShare3 size={20} color={getIconColor()} />}
										onClick={() => history.push(`/project/edit/${projectId}`)}
									>
										{T("viewProject", lang)}
									</Button>
								</CardSection>
							</Card>
						</Tabs.Panel>
						{filesComponent && (
							<Tabs.Panel value="second">
								<Card shadow="sm" p="md">
									<Grid
										p={20}
										m={0}
										style={{
											backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : ""
										}}
									>
										{filesComponent
											.filter((component, index) => {
												if (component) {
													return true;
												}
												return false;
											})
											.map((component, index) => {
												let checkedComponent = HandlePermissionCheck(component);
												if (checkedComponent) {
													let result = (
														<Grid.Col
															span={{
																base: 12,
																md: 6
															}}
															key={index}
														>
															<FilesComponent
																key={index}
																id={stepId}
																destiny={component.destiny}
																file={component.file}
																files={component.files}
																filesUploadLimit={component.filesUploadLimit}
																acceptedFiles={component.acceptedFiles}
																lang={component.lang}
																alt={component.alt}
																objectToAttachAnUpload={component.objectToAttachAnUpload}
																setAlt={component.setAlt}
																setBusy={setBusy}
																setFile={component.setFile}
																setFiles={component.setFiles}
																route={route}
																adminMode={component.adminMode}
																thumbSizeWidth={component.thumbSizeWidth}
																thumbSizeHeight={component.thumbSizeHeight}
																responsiveImages={component.responsiveImages}
																width={component.width}
																height={component.height}
																filesMaxSize={component.filesMaxSize}
																disabled={component.disabled}
																date={component.date}
																placeholder={component.placeholder}
																user={user}
																crud={component.crud}
																permissionModel={checkedComponent.permissionModel}
																permissions={permissions}
															/>
														</Grid.Col>
													);
													return result;
												}
												return null;
											})}
									</Grid>
								</Card>
							</Tabs.Panel>
						)}
						{cartComponent?.data && (
							<Tabs.Panel value="third">
								{cartComponent?.data?.map((component, index) => {
									let checkedComponent = HandlePermissionCheck(component);
									if (checkedComponent) {
										return (
											<CartComponent
												key={index}
												index={index}
												title={component.title}
												data={component.data}
												columns={component.columns}
												setter={component.setter}
												value={component.value}
												pagination={component.pagination}
												width={component.width}
												customStyles={component.customStyles}
												placeholder={component.placeholder}
												disabled={component.disabled}
												user={user}
												crud={component.crud}
												permissionModel={checkedComponent.permissionModel}
												permissions={permissions}
											/>
										);
									}
									return null;
								})}
							</Tabs.Panel>
						)}
						{toDoComponent && (
							<Tabs.Panel value="forth">
								{toDoComponent.map((component, index) => {
									let checkedComponent = HandlePermissionCheck(component);
									if (checkedComponent) {
										return (
											<ToDoComponent
												key={index}
												title={component.title}
												data={component.data}
												columns={component.columns}
												setter={component.setter}
												value={component.value}
												pagination={component.pagination}
												width={component.width}
												customStyles={component.customStyles}
												limit={component.limit}
												permissionModel={component.permissionModel}
												permissions={permissions}
											/>
										);
									}
									return null;
								})}
							</Tabs.Panel>
						)}
						{tableComponent && (
							<Tabs.Panel value="sixth">
								{tableComponent.map((component, index) => {
									let checkedComponent = HandlePermissionCheck(component);
									if (checkedComponent) {
										return (
											<TableComponent
												key={index}
												title={component.title}
												data={component.data}
												columns={component.columns}
												setter={component.setter}
												conditionalRowStyles={component.conditionalRowStyles}
												crud={component.crud}
												user={user}
												permissionModel={component.permissionModel}
												permissions={permissions}
											/>
										);
									}
									return null;
								})}
							</Tabs.Panel>
						)}
						{timelineComponent && (
							<Tabs.Panel
								value="timeline"
								style={{
									display: "flex",
									flexDirection: isDesktop ? "row" : "column"
								}}
							>
								{timelineComponent.map((component, index) => {
									let checkedComponent = HandlePermissionCheck(component);
									if (checkedComponent) {
										return (
											<TimelineComponent
												key={index}
												placeholder={component.placeholder}
												data={component.data}
												crud={component.crud}
												permissionModel={checkedComponent.permissionModel}
												permissions={permissions}
												user={user}
											/>
										);
									}
									return null;
								})}
							</Tabs.Panel>
						)}
					</Tabs>
				</ScrollArea>
			</Flex>
		</>
	);
}
