import React, { useState, useRef, useEffect } from "react";
import config from "../../config";
// import Dropzone from "react-dropzone";
import * as API from "../../helpers/api";
import Swal from "sweetalert2";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { T, Translations } from "../../helpers/translator";

import {
	Group,
	Text,
	rem,
	Button,
	Image,
	Card,
	useMantineColorScheme,
	Title,
	CloseIcon,
	useMantineTheme,
	Modal,
	FileInput,
	ScrollArea,
	SimpleGrid,
	Flex,
	RingProgress
} from "@mantine/core";
import {
	IconUpload,
	IconPhoto,
	IconX,
	IconShare2,
	IconPictureInPictureOn,
	IconVectorBezier2
} from "@tabler/icons-react";
import { Dropzone } from "@mantine/dropzone";
import { useLang } from "../../helpers/language";
import { IconShare3 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";

export function FilesComponent({
	id,
	alt,
	setAlt,
	file,
	files,
	setFile,
	setFiles,
	route,
	acceptedFiles,
	placeholder,
	destiny,
	thumbSizeWidth,
	thumbSizeHeight,
	responsiveImages,
	slider,
	filesMaxSize,
	width,
	height,
	filesUploadLimit,
	disabled,
	date,
	old,
	user,
	permissionModel,
	permissions,
	setBusy
}) {
	const [fullScreen, setFullScreen] = useState(false);
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const openRef = useRef(null);
	var fileId = "";
	const { lang } = useLang();
	const [modalStartIndex, setModalStartIndex] = useState(0);
	const [crud, setCrud] = useState(null);
	const [fileType, setFileType] = useState(null);
	const [opened, { open, close }] = useDisclosure(false);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [loaders, setLoaders] = useState([]);

	let now = new Date();
	// uploadDate equals to now subtract 1 second;
	let uploadDate = new Date(now.getTime() - 1000);

	useEffect(() => {
		if (permissions) {
			const tempCrud = permissions.find((permission) => permission.name === permissionModel);
			setCrud(tempCrud);

			if (acceptedFiles && acceptedFiles.length > 0) {
				const fileTypeMap = [
					{ key: "image", value: "image" },
					{ key: "video", value: "video" },
					{ key: "pdf", value: "pdf" },
					{ key: "doc", value: "doc" },
					{ key: "xls", value: "xls" },
					{ key: "ppt", value: "ppt" },
					{ key: "exe", value: "exe" },
					{ key: "zip", value: "zip" },
					{ key: "csv", value: "csv" },
					{ key: "dwg", value: "autocad" },
					{ key: "kml", value: "kml" },
					{ key: "kmz", value: "kmz" }
				];

				// Flatten accepted files to one string array and find the first matching type
				const detectedType = fileTypeMap.find(({ key }) =>
					acceptedFiles.some((fileType) => fileType.includes(key))
				);

				if (detectedType) {
					setFileType(detectedType.value);
				}
			}
		}
	}, [permissions]);

	async function removeFile({ id }) {
		if (id) {
			Swal.fire({
				title: T("areYouSure", lang),
				text: T("youCannotRevertThisAction", lang),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: T("yes", lang)
			}).then(async (result) => {
				if (result.isConfirmed) {
					setLoading(true);
					if (files) {
						try {
							await API.remove(config.api.upload, id);
							setLoading(false);
							setFiles(
								files.filter((file) => {
									return file._id !== id;
								})
							);
							notifications.show({
								title: T("fileHasBeenRemoved", lang),
								color: "green"
							});
						} catch (err) {
							console.error(err);
							Swal.fire({
								title: "Error!",
								text: Translations.swal.error[lang],
								icon: "error",
								confirmButtonText: "Ok"
							});
							setLoading(false);
						}
					} else if (file) {
						try {
							await API.remove(config.api.upload, file._id);
							setLoading(false);
							setFile(null);
							notifications.show({
								title: T("fileHasBeenRemoved", lang),
								color: "green"
							});
						} catch (err) {
							console.error(err);
							Swal.fire({
								title: "Error!",
								text: Translations.swal.error[lang],
								icon: "error",
								confirmButtonText: "Ok"
							});
							setLoading(false);
						}
					}
				}
			});
		}
	}

	async function handleSave(currentFiles, lang, destiny, alt) {
		setLoading(true);
		setLoaders(currentFiles);

		if (currentFiles.length > 0) {
			let fileOrFiles = setFiles ? "files" : "file";
			let tempArray = fileOrFiles === "files" ? [...files] : null;

			for (const file of currentFiles) {
				try {
					let uploadResult = await API.upload(
						id,
						file,
						lang,
						destiny,
						thumbSizeWidth,
						thumbSizeHeight,
						responsiveImages,
						alt,
						setProgress,
						progress,
						uploadDate,
						uploadedFiles,
						setUploadedFiles
					);

					if (uploadResult) {
						let result = { ...uploadResult.upload };

						notifications.show(config.notificationSettings.save("success", lang));

						if (fileOrFiles === "files") {
							tempArray.push(result);
						} else {
							setFile(result);
						}
					} else {
						notifications.show(config.notificationSettings.save("error", lang));
					}
				} catch (error) {
					console.error(error);
					notifications.show(config.notificationSettings.save("error", lang));
				}
			}

			if (fileOrFiles === "files") {
				setFiles(tempArray);
			}
		} else {
			notifications.show(config.notificationSettings.save("error", lang));
		}

		setLoading(false);
	}

	const openInNewTab = (e) => {
		let win = window.open(`${config.api.API_URL}/${file.path}`, "_blank");
		win.focus();
	};

	useEffect(() => {}, [file, files]);

	if (!crud) return null;

	if (loading) {
		return (
			<Card
				p={20}
				style={{
					maxWidth: width ? width + 40 : "100%",
					height: height ? height + 130 : "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
				}}
			>
				<Title order={3} mb={10} style={{ textAlign: "center" }}>
					{placeholder
						? placeholder
						: destiny
							? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
							: " Files"}
				</Title>

				{loaders?.map((file, key) => {
					return (
						<Flex
							style={{
								position: "absolute",
								display: progress?.find((item) => item.name === file.name) ? "flex" : "none"
							}}
							direction="column"
							align="center"
							justify="center"
							h={"100%"}
							w={"100%"}
						>
							<Text c="cyan" fw={700} ta="center" size="xl">
								{T("uploading", lang)}
							</Text>

							<RingProgress
								roundCaps
								sections={[
									{
										value: progress?.find((item) => item.name === file.name)?.value,
										color: `hsl(${(progress?.find((item) => item.name === file.name)?.value || 0) * 1.2}, 100%, 50%)`
									}
								]}
								label={
									<Text
										c={`hsl(${(progress?.find((item) => item.name === file.name)?.value || 0) * 1.2}, 100%, 50%)`}
										fw={700}
										ta="center"
										size="xl"
									>
										{isNaN(
											Math.floor(parseInt(progress.find((item) => item.name === file.name)?.value))
										)
											? 0
											: Math.floor(
													parseInt(progress.find((item) => item.name === file.name)?.value)
												)}{" "}
										%
									</Text>
								}
							/>

							<Text c="cyan" fw={700} ta="center" size="xl">
								{key + 1} / {loaders.length}
							</Text>
						</Flex>
					);
				})}
			</Card>
		);
	} else if (((!file && !files) || (!file && files.length < 1)) && !disabled) {
		return (
			<Card
				p={20}
				style={{
					maxWidth: width ? width + 40 : "100%",
					height: height ? height + 130 : "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
				}}
			>
				<Title order={3} mb={10} style={{ textAlign: "center" }}>
					{placeholder
						? placeholder
						: destiny
							? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
							: " Files"}
				</Title>
				<>
					<Dropzone
						openRef={openRef}
						onDrop={(files) => handleSave(files, lang, destiny, alt)}
						onReject={(files) => Swal.fire("Error!", "File is too big!", "error")}
						maxSize={5 * 1024 ** 2}
						accept={acceptedFiles}
						multiple={filesUploadLimit > 1 ? true : false}
					>
						<Group justify="center" gap="xl" mih={220} style={{ pointerEvents: "none" }}>
							<Dropzone.Accept>
								<IconUpload
									style={{
										width: rem(52),
										height: rem(52),
										color: "var(--mantine-color-blue-6)"
									}}
									stroke={1.5}
								/>
							</Dropzone.Accept>
							<Dropzone.Reject>
								<IconX
									style={{
										width: rem(52),
										height: rem(52),
										color: "var(--mantine-color-red-6)"
									}}
									stroke={1.5}
								/>
							</Dropzone.Reject>
							<Dropzone.Idle>
								<IconPhoto
									style={{
										width: rem(52),
										height: rem(52),
										color: "var(--mantine-color-dimmed)"
									}}
									stroke={1.5}
								/>
							</Dropzone.Idle>

							<div style={{ textAlign: "center", lineHeight: "1.5em" }}>
								<Text size="xl" inline>
									{T("dropzoneText", lang)}
								</Text>
								{filesUploadLimit && (
									<Text size="sm" c="dimmed" inline mt={7}>
										{`${T("attach", lang)} ${filesUploadLimit > 1 ? "" : "1"} ${
											filesUploadLimit > 1 ? T("files", lang) : T("file", lang)
										}, ${Translations?.maxSize[lang]} ${filesMaxSize}MB`}
									</Text>
								)}
							</div>
						</Group>
					</Dropzone>

					<Group justify="center" mt="md">
						<Button onClick={() => openRef.current?.()}>{Translations?.selectFiles?.[lang]}</Button>
					</Group>
				</>
			</Card>
		);
	} else if (disabled) {
		return (
			<Card
				p={20}
				style={{
					maxWidth: width ? width + 40 : "100%",
					height: height ? height + 130 : "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
				}}
			>
				<Title order={3} mb={10} style={{ textAlign: "center" }}>
					{placeholder
						? placeholder
						: destiny
							? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
							: " Files"}
				</Title>
			</Card>
		);
	} else if (fileType === "pdf") {
		if (file && file.path && !(files && files.length > 0)) {
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>
					<Group justify="center">
						<Button
							onClick={openInNewTab}
							disabled={slider || disabled}
							display={slider ? "none" : "inline"}
							variant="light"
							leftSection={
								<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("open", lang)}
						</Button>
						<embed
							type="application/pdf"
							width={width ? width : "100%"}
							height={height ? height : "100%"}
							src={`${config.api.API_URL}/${file.path}`}
							style={{ borderRadius: "5px", marginBottom: "10px" }}
						/>
						<Button
							onClick={() => removeFile({ id: file?._id, index: 0 })}
							disabled={disabled}
							color="red"
							id={fileId}
							variant="light"
							leftSection={
								<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("remove", lang)}
						</Button>
					</Group>
				</Card>
			);
		} else if (files && files.length > 0) {
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>
					<>
						<ScrollArea h={"calc(100% - 35px)"} w={"100%"}>
							<SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
								{files.map((file, key) => {
									return (
										<div style={{ position: "relative" }} key={key}>
											<embed
												type="application/pdf"
												width={"100%"}
												height={"140px"}
												src={`${config.api.API_URL}/${file.path}`}
												style={{
													borderRadius: "5px",
													marginBottom: "10px"
												}}
												onClick={(e) => {
													open(true);
													setModalStartIndex(files.indexOf(file));
												}}
											/>
											{!fullScreen && (
												<>
													<Button
														onClick={() => removeFile({ id: file._id })}
														disabled={disabled}
														color="red"
														id={fileId}
														style={{
															position: "absolute",
															top: 5,
															right: 5,
															opacity: 0.8,
															width: "20px",
															height: "20px",
															padding: "0"
														}}
													>
														<CloseIcon
															size={14}
															color={colorScheme === "dark" ? "white" : "black"}
															m={0}
														/>
													</Button>

													<Button
														onClick={(e) => {
															open(true);
															setModalStartIndex(files.indexOf(file));
														}}
														disabled={disabled}
														color="cyan"
														id={fileId}
														style={{
															position: "absolute",
															bottom: 5,
															right: 5,
															opacity: 0.8,
															width: "30px",
															height: "30px",
															padding: "0"
														}}
													>
														<IconShare2
															size={14}
															color={colorScheme === "dark" ? "white" : "black"}
															m={0}
														/>
													</Button>
												</>
											)}
										</div>
									);
								})}
							</SimpleGrid>
							<FileInput
								variant="filled"
								placeholder={T("addFiles", lang)}
								leftSection={
									<IconPictureInPictureOn
										style={{ width: rem(18), height: rem(18) }}
										stroke={1.5}
									/>
								}
								onChange={(files) => handleSave(files, lang, destiny, alt)}
								multiple={filesUploadLimit > 1 ? true : false}
								style={{
									position: "absolute",
									bottom: 10,
									left: 10,
									color: "var(--mantine-color-blue-6)"
								}}
							/>
						</ScrollArea>
						<Modal
							opened={opened}
							onClose={close}
							centered
							fullScreen
							overlayProps={{
								backgroundOpacity: 0.55,
								blur: 3
							}}
						>
							<ImageGallery
								startIndex={modalStartIndex}
								slideDuration={200}
								showThumbnails={false}
								items={files.map((file, key) => {
									file.renderItem = () => {
										return (
											<embed
												type="application/pdf"
												key={key}
												style={{
													width: "100%",
													height: "calc(100vh - 100px)"
												}}
												src={`${config.api.API_URL}/${file.path}`}
											/>
										);
									};
									return file;
								})}
								onScreenChange={(fullScreen) => {
									if (fullScreen) {
										document.body.style.overflow = "hidden";
										setFullScreen(true);
									} else {
										document.body.style.overflow = "auto";
										setFullScreen(false);
									}
								}}
							/>
						</Modal>
					</>
					<Group justify="center">
						<Button
							onClick={openInNewTab}
							disabled={slider || disabled}
							display={slider ? "none" : "inline"}
							variant="light"
							leftSection={
								<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("open", lang)}
						</Button>

						<Button
							onClick={() => removeFile({ id: file?._id })}
							disabled={disabled}
							color="red"
							id={fileId}
							variant="light"
							leftSection={
								<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("remove", lang)}
						</Button>
					</Group>
				</Card>
			);
		}
	} else if (fileType === "autocad") {
		if (file && file.path && !(files && files.length > 0)) {
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>
					<Flex align="center" justify="center" direction={"column"}>
						<IconVectorBezier2 size={100} color={colorScheme === "dark" ? "white" : "black"} />
						<p>
							{file.name}.{file.path.slice(-3)}
						</p>
					</Flex>
					<Group justify="center">
						<Button
							onClick={openInNewTab}
							disabled={slider || disabled}
							display={slider ? "none" : "inline"}
							variant="light"
							leftSection={
								<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("open", lang)}
						</Button>

						<Button
							onClick={() => removeFile({ id: file?._id, index: 0 })}
							disabled={disabled}
							color="red"
							id={fileId}
							variant="light"
							leftSection={
								<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("remove", lang)}
						</Button>
					</Group>
				</Card>
			);
		} else if (files && files.length > 0) {
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>
					<>
						<ScrollArea h={"calc(100% - 35px)"} w={"100%"}>
							<SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
								{files.map((file, key) => {
									return (
										<div style={{ position: "relative" }} key={key}>
											{!fullScreen && (
												<>
													<Button
														onClick={() => removeFile({ id: file._id })}
														disabled={disabled}
														color="red"
														id={fileId}
														style={{
															position: "absolute",
															top: 5,
															right: 5,
															opacity: 0.8,
															width: "20px",
															height: "20px",
															padding: "0"
														}}
													>
														<CloseIcon
															size={14}
															color={colorScheme === "dark" ? "white" : "black"}
															m={0}
														/>
													</Button>

													<Button
														onClick={(e) => {
															open(true);
															setModalStartIndex(files.indexOf(file));
														}}
														disabled={disabled}
														color="cyan"
														id={fileId}
														style={{
															position: "absolute",
															bottom: 5,
															right: 5,
															opacity: 0.8,
															width: "30px",
															height: "30px",
															padding: "0"
														}}
													>
														<IconShare2
															size={14}
															color={colorScheme === "dark" ? "white" : "black"}
															m={0}
														/>
													</Button>
												</>
											)}
										</div>
									);
								})}
							</SimpleGrid>
							<FileInput
								variant="filled"
								placeholder={T("addFiles", lang)}
								leftSection={
									<IconPictureInPictureOn
										style={{ width: rem(18), height: rem(18) }}
										stroke={1.5}
									/>
								}
								onChange={(files) => handleSave(files, lang, destiny, alt)}
								multiple={filesUploadLimit > 1 ? true : false}
								style={{
									position: "absolute",
									bottom: 10,
									left: 10,
									color: "var(--mantine-color-blue-6)"
								}}
							/>
						</ScrollArea>
						<Modal
							opened={opened}
							onClose={close}
							centered
							fullScreen
							overlayProps={{
								backgroundOpacity: 0.55,
								blur: 3
							}}
						>
							<ImageGallery
								startIndex={modalStartIndex}
								slideDuration={200}
								showThumbnails={false}
								items={files.map((file, key) => {
									file.renderItem = () => {
										return (
											<embed
												type="application/pdf"
												key={key}
												style={{
													width: "100%",
													height: "calc(100vh - 100px)"
												}}
												src={`${config.api.API_URL}/${file.path}`}
											/>
										);
									};
									return file;
								})}
								onScreenChange={(fullScreen) => {
									if (fullScreen) {
										document.body.style.overflow = "hidden";
										setFullScreen(true);
									} else {
										document.body.style.overflow = "auto";
										setFullScreen(false);
									}
								}}
							/>
						</Modal>
					</>
					<Group justify="center">
						<Button
							onClick={openInNewTab}
							disabled={slider || disabled}
							display={slider ? "none" : "inline"}
							variant="light"
							leftSection={
								<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("open", lang)}
						</Button>

						<Button
							onClick={() => removeFile({ id: file?._id })}
							disabled={disabled}
							color="red"
							id={fileId}
							variant="light"
							leftSection={
								<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("remove", lang)}
						</Button>
					</Group>
				</Card>
			);
		}
	} else if (
		fileType === "zip" ||
		fileType === "doc" ||
		fileType === "docx" ||
		fileType === "xls" ||
		fileType === "xlsx" ||
		fileType === "kml" ||
		fileType === "kmz" ||
		fileType === "exe" ||
		fileType === "csv"
	) {
		return (
			<Card
				p={20}
				style={{
					maxWidth: width ? width + 40 : "100%",
					height: height ? height + 130 : "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
				}}
			>
				<Title order={3} mb={10} style={{ textAlign: "center" }}>
					{placeholder
						? placeholder
						: destiny
							? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
							: " Files"}
				</Title>

				<Text>{file?.name}</Text>
				<Group justify="center">
					<Button
						onClick={openInNewTab}
						disabled={slider || disabled}
						display={slider ? "none" : "inline"}
						variant="light"
						leftSection={
							<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
						}
					>
						{T("open", lang)}
					</Button>
					<Button
						onClick={() => removeFile({ id: file?._id, index: 0 })}
						disabled={disabled}
						color="red"
						id={fileId}
						variant="light"
						leftSection={<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />}
					>
						{T("remove", lang)}
					</Button>
				</Group>
			</Card>
		);
	} else if (fileType === "image") {
		if (file) {
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>

					<div style={{ position: "relative" }}>
						<Image
							src={`${config.api.API_URL}/${file.path}`}
							alt={`${file.alt || file.path || ""}`}
							w={width ? width : "100%"}
							h={height ? height : "100%"}
							radius="sm"
							objectFit={"contain"}
						/>
						{!fullScreen && (
							<>
								<Button
									onClick={(e) => {
										open(true);
									}}
									disabled={disabled}
									color="cyan"
									id={fileId}
									style={{
										position: "absolute",
										bottom: 5,
										right: 5,
										opacity: 0.8,
										width: "30px",
										height: "30px",
										padding: "0"
									}}
								>
									<IconShare2 size={14} color={colorScheme === "dark" ? "white" : "black"} m={0} />
								</Button>
							</>
						)}
					</div>
					<Modal
						opened={opened}
						onClose={close}
						centered
						fullScreen
						overlayProps={{
							backgroundOpacity: 0.55,
							blur: 3
						}}
					>
						<Image
							src={`${config.api.API_URL}/${file.path}`}
							alt={`${file.alt || file.path || ""}`}
							radius="sm"
							objectFit={"contain"}
							style={{
								width: "auto",
								margin: " 0 auto",
								height: "calc(100vh - 100px)"
							}}
						/>
					</Modal>

					<Group justify="center">
						<Button
							onClick={openInNewTab}
							disabled={slider || disabled}
							display={slider ? "none" : "inline"}
							variant="light"
							leftSection={
								<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("open", lang)}
						</Button>
						<Button
							onClick={() => removeFile({ id: file?._id, index: 0 })}
							disabled={disabled}
							color="red"
							id={fileId}
							variant="light"
							leftSection={
								<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
							}
						>
							{T("remove", lang)}
						</Button>
					</Group>
				</Card>
			);
		} else if (files && files.length > 0) {
			files = files.filter((file) => file);
			return (
				<Card
					p={20}
					style={{
						maxWidth: width ? width + 40 : "100%",
						height: height ? height + 130 : "100%",
						margin: "0 auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
					}}
				>
					<Title order={3} mb={10} style={{ textAlign: "center" }}>
						{placeholder
							? placeholder
							: destiny
								? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
								: " Files"}
					</Title>

					<ScrollArea h={"100%"} w={"100%"}>
						<SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
							{files.map((file, key) => {
								return (
									<div key={key} style={{ position: "relative" }}>
										<Image
											src={`${config.api.API_URL}/${file?.path}`}
											alt={`${file?.alt || file?.path || ""}`}
											w={"100%"}
											h={"140px"}
											radius="sm"
											style={{
												objectFit: "cover"
											}}
										/>
										{!fullScreen && (
											<>
												<Button
													onClick={() => removeFile({ id: file?._id })}
													disabled={disabled}
													color="red"
													id={fileId}
													style={{
														position: "absolute",
														top: 5,
														right: 5,
														opacity: 0.8,
														width: "20px",
														height: "20px",
														padding: "0"
													}}
												>
													<CloseIcon
														size={14}
														color={colorScheme === "dark" ? "white" : "black"}
														m={0}
													/>
												</Button>

												<Button
													onClick={(e) => {
														open(true);
														setModalStartIndex(files.indexOf(file));
													}}
													disabled={disabled}
													color="cyan"
													id={fileId}
													style={{
														position: "absolute",
														bottom: 5,
														right: 5,
														opacity: 0.8,
														width: "30px",
														height: "30px",
														padding: "0"
													}}
												>
													<IconShare2
														size={14}
														color={colorScheme === "dark" ? "white" : "black"}
														m={0}
													/>
												</Button>
											</>
										)}
									</div>
								);
							})}
						</SimpleGrid>
						<FileInput
							variant="filled"
							placeholder={T("addFiles", lang)}
							leftSection={
								<IconPictureInPictureOn style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
							}
							onChange={(files) => handleSave(files, lang, destiny, alt)}
							multiple={filesUploadLimit > 1 ? true : false}
							style={{
								position: "absolute",
								bottom: 10,
								left: 10,
								color: "var(--mantine-color-blue-6)"
							}}
						/>
					</ScrollArea>
					<Modal
						opened={opened}
						onClose={close}
						centered
						fullScreen
						overlayProps={{
							backgroundOpacity: 0.55,
							blur: 3
						}}
					>
						<ImageGallery
							startIndex={modalStartIndex}
							slideDuration={200}
							showThumbnails={true}
							items={files.map((file, key) => {
								if (!file) return null;
								file.thumbnailHeight = "100px";
								file.thumbnail = `${config.api.API_URL}/${file.path}`;
								file.renderItem = () => {
									return (
										<Image
											src={`${config.api.API_URL}/${file.path}`}
											alt={`${file.name}`}
											w={"auto"}
											m={"0 auto"}
											h={"calc(100vh - 200px)"}
											objectFit={"contain"}
											key={key}
										/>
									);
								};
								file.renderThumbInner = () => {
									return (
										<Image
											src={`${config.api.API_URL}/${file.path}`}
											alt={`${file.name}`}
											style={{
												height: "100px"
											}}
										/>
									);
								};
								return file;
							})}
							onScreenChange={(fullScreen) => {
								if (fullScreen) {
									document.body.style.overflow = "hidden";
									setFullScreen(true);
								} else {
									document.body.style.overflow = "auto";
									setFullScreen(false);
								}
							}}
						/>
					</Modal>
				</Card>
			);
		}
	} else if (fileType === "video") {
		return (
			<Card
				p={20}
				style={{
					maxWidth: width ? width + 40 : "100%",
					height: height ? height + 130 : "100%",
					margin: "0 auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					border: `1px solid ${colorScheme === "dark" ? Theme.colors.gray[7] : "white"}`
				}}
			>
				<Title order={3} mb={10} style={{ textAlign: "center" }}>
					{placeholder
						? placeholder
						: destiny
							? destiny.charAt(0).toUpperCase() + destiny.slice(1) + " " + T("file", lang)
							: " Files"}
				</Title>
				<Group justify="center">
					<Button
						onClick={openInNewTab}
						disabled={slider || disabled}
						display={slider ? "none" : "inline"}
						variant="light"
						leftSection={
							<IconShare3 size={14} color={colorScheme === "dark" ? "white" : "black"} />
						}
					>
						{T("open", lang)}
					</Button>

					<video width={width ? width : "100%"} height={height ? height : "100%"} controls>
						<source src={`${config.api.API_URL}/${file.path}`} type={file.type} />
						Your browser does not support the video tag.
					</video>
					<Button
						onClick={() => removeFile({ id: file?._id, index: 0 })}
						disabled={disabled}
						color="red"
						id={fileId}
						variant="light"
						leftSection={<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />}
					>
						{T("remove", lang)}
					</Button>
				</Group>
			</Card>
		);
	}
}
