import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	Grid,
	useMantineColorScheme,
	Box,
	Text,
	Slider,
	Flex,
	Button,
	ScrollArea,
	Collapse,
	Accordion,
	Menu,
	CloseIcon,
	Avatar,
	Paper,
	Divider,
	Badge,
	ButtonGroup,
	Stepper,
	Indicator,
	Tooltip
} from "@mantine/core";
import { MantineReactTable, useMantineReactTable, MRT_BottomToolbar } from "mantine-react-table";
import { DELETE } from "../../../helpers/CRUD";
import moment from "moment";
import { debounce, fill, filter } from "lodash";
import { useState } from "react";
import NProgress from "nprogress";
import { useUser } from "../../../helpers/userContext";
import * as API from "../../../helpers/api";
import config from "../../../config";
import { T, Translations } from "../../../helpers/translator";
import { useLang } from "../../../helpers/language";
import Input from "../../modules/input";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
	IconAlertCircle,
	IconChevronDown,
	IconChevronRight,
	IconChevronUp,
	IconEdit,
	IconPlayerPause,
	IconPlayerPlay,
	IconPlayerStop,
	IconSearch,
	IconShare3,
	IconTimeDuration0,
	IconTrash,
	IconViewfinder
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

let FirstRender = false;
let SecondRender = false;

function checkDeadline(deadline) {
	const today = moment();
	const deadlineMoment = moment(deadline, moment.ISO_8601, true);

	if (!deadlineMoment.isValid()) {
		return null;
	}

	const years = today.diff(deadlineMoment, "years");
	deadlineMoment.add(years, "years");

	const isBirthDay = today.isSame(deadlineMoment, "day");
	const upcomingBirthdayDays = deadlineMoment.clone().add(1, "year").diff(today, "days");

	if (isBirthDay) {
		return 1;
	} else if (upcomingBirthdayDays < 30) {
		return 2;
	} else {
		return 0;
	}
}

const Search = () => {
	let history = useHistory();
	const location = useLocation();
	const [isBusy, setBusy] = useState(true);
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [showTable, setShowTable] = useState(true);
	const [projects, setProjects] = useState([]);

	const [projectType, setProjectType] = useState("");
	const [projectTypes, setProjectTypes] = useState([]);

	const [community, setCommunity] = useState("");
	const [communities, setCommunities] = useState([]);
	const [GlobalFilter, setGlobalFilter] = useState("");
	const [isError, setIsError] = useState(false);

	const [step3SelectedResponsibles, setStep3SelectedResponsibles] = useState("");
	const [selectedSteps, setSelectedSteps] = useState([]);
	const [stepStatus, setStepStatus] = useState("");
	const [CollectionCount, setCollectionCount] = useState(0);
	const [branch, setBranch] = useState(null);
	const [branches, setBranches] = useState([]);

	const pageIndex = 0;
	const pageSize = 10;
	let formattedLocation = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const [pagination, setPagination] = useState({
		pageIndex: Number(
			pageIndex || pageIndex === 0 || (formattedLocation.pageIndex && formattedLocation.pageIndex)
		),
		pageSize: Number(pageSize || (formattedLocation.pageSize && formattedLocation.pageSize)) //customize the default page size
	});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [voltage, setVoltage] = useState(null);
	const [voltages, setVoltages] = useState([]);
	const [projectPartition, setProjectPartition] = useState("");
	const [disabledSteps, setDisabledSteps] = useState([]);
	const [users, setUsers] = useState([]);
	const [importance, setImportance] = useState("");
	const [specificDateRange, setSpecificDateRange] = useState(null);
	const [opened, Toggle] = useDisclosure(false);
	const [specificFieldInStep, setSpecificFieldInStep] = useState(null);

	const { user } = useUser();
	let CRUD = {
		create: false,
		read: false,
		update: false,
		delete: false
	};
	const height = "calc(100vh - 0px)";
	const mantineTableContainerPropsHeight = "calc(100vh - 310px)";
	const defaultColumn = {
		maxSize: 1000,
		minSize: 40,
		size: 180 //default size is usually 180
	};
	const showSkeletons = true;
	const enableColumnActions = false;
	const enableEditing = false;
	const enableColumnFilterModes = false;
	const enableColumnOrdering = false;
	const enableFacetedValues = false;
	const enableGrouping = false;
	const enableColumnFilters = true;
	const enablePinning = false;
	const enableRowSelection = false;
	const enableRowActions = true;
	const enableSorting = false;
	const enableDensityToggle = false;
	const mantineSearchTextInputProps = null;
	const enableColumnResizing = false;
	const enableStickyHeader = true;
	const enableStickyFooter = true;

	const mantinePaginationProps = {
		radius: "sm",
		size: "sm"
	};

	const paginationDisplayMode = "pages";
	const positionToolbarAlertBanner = "bottom";
	let totalContentCount = 0;
	const enableClickToCopy = false;
	const initialState = {
		sorting: [],
		filters: [],
		grouping: [],
		pinnedColumns: [],
		hiddenColumns: [],
		selectedRows: [],
		searchQuery: "",
		showColumnFilters: true,
		showGlobalFilter: false
	};
	const [Sorting, setSorting] = useState([]);
	const [ColumnFilters, setColumnFilters] = useState([]);

	user.role?.permissions?.forEach((permission) => {
		if (user?.ananunaki) {
			CRUD = {
				create: true,
				read: true,
				update: true,
				delete: true
			};
		}
		if (permission.name === "project") {
			CRUD.read = permission.crud.read;
			CRUD.create = permission.crud.create;
			CRUD.update = permission.crud.update;
			CRUD.delete = permission.crud.delete;
		}
	});

	// hardcoded information
	const projectPartitions = [
		{
			_id: "0",
			name: {
				en: "Main",
				ru: "Основной",
				hy: "Հիմնական",
				tr: "Ana",
				ar: "رئيسي",
				zh: "主要",
				fa: "اصلی",
				he: "ראשי",
				fr: "Principal",
				es: "Principal",
				pt: "Principal"
			}
		},
		{
			_id: "1",
			name: {
				en: "Partition",
				ru: "Подразделение",
				hy: "Հատոր",
				tr: "Bölüm",
				ar: "تقسيم",
				zh: "分区",
				fa: "پارتیشن",
				he: "מחיצה",
				fr: "Partition",
				es: "Partición",
				pt: "Partição"
			}
		},
		{
			_id: "2",
			name: {
				en: "Project change",
				ru: "Изменение проекта",
				hy: "Նախագծի փոփոխություն",
				tr: "Proje değişikliği",
				ar: "تغيير المشروع",
				zh: "项目变更",
				fa: "تغییر پروژه",
				he: "שינוי פרויקט",
				fr: "Changement de projet",
				es: "Cambio de proyecto",
				pt: "Mudança de projeto"
			}
		}
	];

	const importances = [
		{
			_id: "0",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "2",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const disabledStepsArray = [
		{
			name: {
				hy: "Տիմ մուտք պետք չէ",
				en: "No team entry required",
				ru: "Вход команды не требуется",
				tr: "Takım girişi gerekli değil",
				ar: "لا يلزم إدخال الفريق",
				zh: "不需要团队输入",
				fa: "ورود تیم لازم نیست",
				he: "אין צורך בכניסת צוות",
				fr: "Pas d'entrée d'équipe requise",
				es: "No se requiere entrada de equipo",
				pt: "Nenhuma entrada de equipe necessária"
			},
			value: [10, 13, 14, 17, 18, 19, 20, 39, 40, 41, 42, 43, 44]
		},
		{
			name: {
				hy: "Տեղազննում պետք չէ",
				en: "No site inspection required",
				ru: "Осмотр сайта не требуется",
				tr: "Site denetimi gerekli değil",
				ar: "لا يلزم التفتيش على الموقع",
				zh: "不需要现场检查",
				fa: "بازرسی سایت لازم نیست",
				he: "אין צורך בבדיקת אתר",
				fr: "Pas d'inspection de site requise",
				es: "No se requiere inspección del sitio",
				pt: "Nenhuma inspeção no local necessária"
			},
			value: [1]
		},
		{
			name: {
				hy: "Գրունտ պետք չէ",
				en: "No soil required",
				ru: "Почва не требуется",
				tr: "Toprak gerekli değil",
				ar: "لا يلزم التربة",
				zh: "不需要土壤",
				fa: "خاک لازم نیست",
				he: "אין צורך באדמה",
				fr: "Pas de sol requis",
				es: "No se requiere suelo",
				pt: "Nenhum solo necessário"
			},
			value: [2]
		},
		{
			name: {
				hy: "Նախահաշիվ պետք չէ",
				en: "No preliminary estimate required",
				ru: "Предварительная оценка не требуется",
				tr: "Ön tahmin gerekli değil",
				ar: "لا يلزم تقدير أولي",
				zh: "不需要初步估计",
				fa: "برآورد اولیه لازم نیست",
				he: "אין צורך בהערכה מוקדמת",
				fr: "Pas d'estimation préliminaire requise",
				es: "No se requiere estimación preliminar",
				pt: "Nenhuma estimativa preliminar necessária"
			},
			value: [28, 29, 30, 31, 32, 33]
		},
		{
			name: {
				hy: "Նախնական ՀԷՑ հանձնելու կարիք չկա",
				en: "No need to submit to preliminary HEC",
				ru: "Нет необходимости подавать в предварительный ГЭК",
				tr: "Ön HEC'ye sunmaya gerek yok",
				ar: "لا حاجة لتقديم إلى HEC الأولي",
				zh: "无需提交初步HEC",
				fa: "نیازی به ارسال به HEC اولیه نیست",
				he: "אין צורך להגיש ל-HEC ראשוני",
				fr: "Pas besoin de soumettre au HEC préliminaire",
				es: "No es necesario presentar al HEC preliminar",
				pt: "Não há necessidade de enviar ao HEC preliminar"
			},
			value: [15, 16]
		},
		{
			name: {
				hy: "Գծագրում պետք չէ",
				en: "No drawing required",
				ru: "Чертеж не требуется",
				tr: "Çizim gerekli değil",
				ar: "لا يلزم الرسم",
				zh: "不需要绘图",
				fa: "نقشه کشی لازم نیست",
				he: "אין צורך בציור",
				fr: "Pas de dessin requis",
				es: "No se requiere dibujo",
				pt: "Nenhum desenho necessário"
			},
			value: [4, 5, 6, 7, 8, 9, 11, 12]
		}
	];

	const stepStatuses = [
		{
			_id: "0",
			name: Translations.stepIsNotStartedYet,
			value: "notStarted"
		},
		{
			_id: "1",
			name: Translations.inProgress,
			value: "inProgress"
		},
		{
			_id: "2",
			name: Translations.done,
			value: "done"
		}
	];

	const addNew = async () => {
		setButtonLoading(true);
		let result;
		let specificPageRoute = "" + "project";
		if ("project" === "project") specificPageRoute += "/add";
		try {
			result = await API.post("project", { temporary: true });
			history.push(`/${specificPageRoute}/${result._id}?temporary=true`);
			setButtonLoading(false);
		} catch (error) {
			console.error(error);
			setButtonLoading(false);
		}
	};

	const handleSearch = async () => {
		NProgress.start();
		setShowTable(false);

		try {
			let stepIds = [];

			if (step3SelectedResponsibles) {
				const steps = await API.get({
					route: config.api.step,
					query: {
						queue: 3,
						"steps.index": 4,
						"steps.responsible": step3SelectedResponsibles
					},
					uploadType: true,
					populate: [
						"steps",
						"city",
						"village",
						"branch",
						{
							path: "branch",
							populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
						},
						"voltage",
						{
							path: "voltage",
							populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
						},
						"projectType",
						{
							path: "projectType",
							populate: { path: "steps.responsible" }
						},
						"community"
					]
				});
				stepIds = steps.map((step) => step._id);
			}

			let query = {
				projectType: projectType || undefined,
				community: community || undefined,
				branch: branch || undefined,
				voltage: voltage || undefined,
				projectPartition: projectPartition || undefined,
				importance: Number(importance) || undefined,
				disabledSteps: disabledSteps.length > 0 ? disabledSteps : undefined,
				...(stepIds.length > 0 && { steps: { $in: stepIds } })
			};

			// Remove undefined values from query
			Object.keys(query).forEach((key) => query[key] === undefined && delete query[key]);

			if (selectedSteps.length > 0) {
				let result = await API.get({
					route: config.api.project,
					sort: { ID: -1 },
					uploadType: true,
					populate: [
						"steps",
						{
							path: "steps",
							populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
						},
						"city",
						"village",
						"branch",
						{
							path: "branch",
							populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
						},
						"voltage",
						{
							path: "voltage",
							populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
						},
						"projectType",
						{
							path: "projectType",
							populate: { path: "steps.responsible" }
						},
						"community"
					],
					count: true
				});

				let projects = result.items;
				setShowTable(true);
				if (projects?.length > 0 && stepStatus) {
					let filteredProjects = [...projects];
					let tempFilteredProjects = [];

					// Filter by selected steps and step status
					const parsedStepStatus = stepStatuses.find((item) => item._id === stepStatus)?.value;
					filteredProjects.forEach((project) => {
						let steps = project.steps;
						let filteredSteps;
						// if some date range filters accepted
						if (!specificDateRange) {
							filteredSteps = steps.filter(
								(step) =>
									selectedSteps.includes(step?.queue?.toString()) &&
									step.status === parsedStepStatus
							);
						} else {
							filteredSteps = steps.filter((step) => {
								const lastDate = step.endDate[step.endDate.length - 1];
								return (
									selectedSteps.includes(step?.queue?.toString()) &&
									step.status === parsedStepStatus &&
									moment(lastDate).isBetween(
										specificDateRange.from,
										specificDateRange.to,
										null,
										"[]"
									)
								);
							});
						}
						if (filteredSteps.length > 0) {
							tempFilteredProjects.push(project);
						}
					});

					if (tempFilteredProjects.length > 0) {
						setCollectionCount(tempFilteredProjects.length);
						totalContentCount = tempFilteredProjects.length;
						tempFilteredProjects = tempFilteredProjects.slice(
							pagination?.pageIndex * pagination?.pageSize,
							pagination?.pageIndex * pagination?.pageSize + pagination?.pageSize
						);
						setProjects(tempFilteredProjects);
						setTimeout(() => Toggle.close(), 500);
					} else {
						setProjects([]);
					}
				}
				if (projects?.length > 0 && specificFieldInStep) {
					let filteredProjects = [...projects];
					let tempFilteredProjects = [];

					// Filter by selected steps and somthing selected in that step
					filteredProjects.forEach((project) => {
						let steps = project.steps;
						let filteredSteps = [];
						filteredSteps = steps.filter((step) => selectedSteps.includes(step?.queue?.toString()));
						if (specificFieldInStep === "activateStep7_8_9") {
							filteredSteps?.forEach((step) => {
								const targetIndexes = [7, 8, 9];

								const isEveryStepDisabled = targetIndexes.every((index) => {
									const stepItem = step.steps.find((item) => item.index === index);
									return stepItem && stepItem.disabled === true;
								});

								if (isEveryStepDisabled) {
									tempFilteredProjects.push(project);
								}
							});
						} else if (specificFieldInStep === "activateStep8") {
							filteredSteps?.forEach((step) => {
								step.steps.forEach((item) => {
									if (item.index === 8 && item.disabled === true) {
										tempFilteredProjects.push(project);
									}
								});
							});
						}
					});

					if (tempFilteredProjects.length > 0) {
						setCollectionCount(tempFilteredProjects.length);
						totalContentCount = tempFilteredProjects.length;
						tempFilteredProjects = tempFilteredProjects.slice(
							pagination?.pageIndex * pagination?.pageSize,
							pagination?.pageIndex * pagination?.pageSize + pagination?.pageSize
						);
						setProjects(tempFilteredProjects);
						setTimeout(() => Toggle.close(), 500);
					} else {
						setProjects([]);
					}
				} else {
					setProjects([]);
				}
			} else {
				try {
					let result = await API.get({
						route: config.api.project,
						query,
						sort: { ID: -1 },
						uploadType: true,
						populate: [
							"steps",
							{
								path: "steps",
								populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
							},
							"city",
							"village",
							"branch",
							{
								path: "branch",
								populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
							},
							"voltage",
							{
								path: "voltage",
								populate: [{ path: "steps.responsible" }, { path: "steps.users" }]
							},
							"projectType",
							{
								path: "projectType",
								populate: { path: "steps.responsible" }
							},
							"community"
						],
						limit: pagination?.pageSize ? pagination?.pageSize : "",
						skip: pagination?.pageIndex * pagination?.pageSize,
						count: true
					});

					let projects = result.items;
					setCollectionCount(result.count);
					totalContentCount = result.count;
					setShowTable(true);

					if (projects?.length > 0) {
						setProjects(projects);
						setTimeout(() => Toggle.close(), 500);
					} else {
						setProjects([]);
					}
				} catch (error) {
					setIsError(true);
					console.error("Error fetching data:", error);
					setProjects([]);
				}
			}
		} catch (error) {
			setIsError(true);
			console.error("Error fetching data:", error);
			setProjects([]);
		}

		NProgress.done();
	};

	const columns = [
		{
			accessorKey: "projectType",
			header: T("type", lang),
			permissionModel: "type",
			size: 100,
			filterVariant: "select",
			//custom conditional format and styling
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				if (cell) {
					return (
						<Text size="sm" weight={500}>
							{cell?.name?.[lang]}
						</Text>
					);
				} else {
					return <Text>{T("undefined", lang)}</Text>;
				}
			}
		},
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 100
		},
		{
			accessorKey: "orderNumber",
			header: T("orderNumber", lang),
			permissionModel: "orderNumber",
			size: 100,
			//custom conditional format and styling
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				if (cell) {
					return (
						<Text size="sm" weight={500}>
							{cell}
						</Text>
					);
				} else {
					return <Text>{T("undefined", lang)}</Text>;
				}
			}
		},
		{
			accessorKey: "address",
			header: T("address", lang),
			permissionModel: "address",
			size: 240,
			//custom conditional format and styling
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				if (cell) {
					return (
						<Text size="sm" weight={500}>
							{cell.length > 30 ? cell.slice(0, 30) + "..." : cell}
						</Text>
					);
				} else {
					return <Text>{T("undefined", lang)}</Text>;
				}
			}
		},
		{
			accessorKey: "steps",
			header: T("steps", lang),
			permissionModel: "steps",
			filterVariant: "multi-select",
			grow: true,
			size: 300,
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				let result,
					responsibles = [];
				cell?.forEach((item, index) => {
					if (item.status === "inProgress") {
						result = { ...item, index };
						return item;
					}
				});
				if (cell.length > 2) {
					let step3 = cell.find((item) => item.queue === 3);
					if (step3) {
						let responsible = step3.steps?.find((item) => item.index === 4);
						responsibles = responsible?.responsible;
					}
				}
				// result && result.index ? result.index : "Not started";
				let value = result && result.index + 1 ? result.index + 1 : 0;
				let label = value
					? config.steps[result.index]?.name?.[lang]
					: config.steps[0]?.name?.[lang];
				return (
					<Flex
						direction="column"
						w={"100%"}
						h={"70px"}
						style={{
							zIndex: "1"
						}}
					>
						{responsibles?.length > 0 ? (
							<Flex wrap={"wrap"} gap={10} mb={10}>
								{responsibles?.map((res) => (
									<Badge variant="light" key={res.id ?? `${res.firstName}-${res.lastName}`} mx={10}>
										{res.firstName?.[lang]} {res.lastName?.[lang]}
									</Badge>
								))}
							</Flex>
						) : (
							<Flex wrap={"wrap"} gap={10} mb={10}>
								<Badge color="red" mx={10}>
									{T("noUsersFound", lang)}
								</Badge>
							</Flex>
						)}
						<Text size="sm" mb={10}>
							{label} - ( {value} )
						</Text>
						<Slider
							max={45}
							defaultValue={1}
							step={1}
							min={1}
							label={null}
							value={value}
							marks={[
								{ value: 1, label: "0%" },
								{ value: 10, label: "20%" },
								{ value: 23, label: "50%" },
								{ value: 35, label: "80%" },
								{ value: 45, label: "100%" }
							]}
						/>
					</Flex>
				);
			}
		},
		{
			accessorKey: "importance",
			header: T("importance", lang),
			permissionModel: "importance",
			size: 200,
			filterVariant: "multi-select",
			//custom conditional format and styling
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				if (cell) {
					return (
						<Box
							sx={(theme) => ({
								backgroundColor:
									cell === 0
										? theme.colors.green[9]
										: cell === 1
											? theme.colors.yellow[9]
											: cell === 2
												? theme.colors.red[9]
												: theme.colors.gray[9],
								borderRadius: "10px",
								color: "#fff",
								maxWidth: "150px",
								padding: "5px",
								textAlign: "center"
							})}
						>
							{cell === 2
								? T("high", lang)
								: cell === 1
									? T("medium", lang)
									: cell === 0
										? T("low", lang)
										: T("undefined", lang)}
						</Box>
					);
				} else {
					return <Text>{T("undefined", lang)}</Text>;
				}
			}
		},
		{
			accessorKey: "startDate",
			permissionModel: "startDate",
			header: T("startDate", lang),
			enableClickToCopy: true,
			filterVariant: "date",
			size: 100,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = moment(row.startDate).format("MM.DD.YYYY");
				let isBirthDay = checkDeadline(row.startDate, colorScheme);
				if (isBirthDay === null) return null;
				if (isBirthDay === 0) {
					return result;
				}
				return (
					<Flex align={"center"} gap={10}>
						<Text c={isBirthDay === 1 ? "red.6" : isBirthDay === 2 ? "teal.6" : "grey.6"}>
							{result}
						</Text>
					</Flex>
				);
			}
		},
		{
			accessorKey: "endDate",
			permissionModel: "endDate",
			header: T("endDate", lang),
			enableClickToCopy: true,
			filterVariant: "date",
			size: 100,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = moment(row.endDate).format("MM.DD.YYYY");
				let isBirthDay = checkDeadline(row.endDate, colorScheme);
				if (isBirthDay === null) return null;
				if (isBirthDay === 0) {
					return result;
				}
				return (
					<Flex align={"center"} gap={10}>
						<Avatar
							color={isBirthDay === 1 ? "red.6" : isBirthDay === 2 ? "teal.6" : "grey.6"}
							radius="sm"
						>
							<IconTimeDuration0 size="1.5rem" />
						</Avatar>
						<Text c={isBirthDay === 1 ? "red.6" : isBirthDay === 2 ? "teal.6" : "grey.6"}>
							{result}
						</Text>
					</Flex>
				);
			}
		}
	];

	useEffect(() => {
		const observer = new ResizeObserver(
			debounce(() => {
				console.log("Resize detected");
			}, 100) // Adjust debounce timing
		);

		observer.observe(document.body);
		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		(async () => {
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setUsers(rawUsers);
			// communities
			let rawCommunities = await API.get({ route: config.api.community });
			if (rawCommunities) setCommunities(rawCommunities);
			// all voltages
			let rawVoltages = await API.get({ route: config.api.voltage });
			if (rawVoltages) setVoltages(rawVoltages);
			// all projectTypes
			let rawProjectTypes = await API.get({ route: config.api.projectType });
			if (rawProjectTypes) setProjectTypes(rawProjectTypes);
			// all branches
			let rawBranches = await API.get({ route: config.api.branch });
			if (rawBranches) setBranches(rawBranches);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
		history.push(newUrl);
		// eslint-disable-next-line
	}, [pagination?.pageIndex, pagination?.pageSize]);

	useEffect(() => {
		(async () => {
			await handleSearch();
		})();
		FirstRender = true;
		// eslint-disable-next-line
	}, [
		location.search,
		window.location.pathname,
		GlobalFilter,
		ColumnFilters,
		ColumnFilters.length,
		isBusy
	]);

	const clearAllFilters = () => {
		setProjectType(null);
		setCommunity(null);
		setBranch(null);
		setVoltage(null);
		setProjectPartition(null);
		setDisabledSteps([]);
		setSelectedSteps([]);
		setStepStatus(null);
		setStep3SelectedResponsibles(null);
		setImportance(null);
		setShowTable(true);
		setGlobalFilter("");
		setBusy(true);
		// clear url from all futlers and search
		history.push
			? history.push(location.pathname)
			: (window.location.href = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`);
	};

	useEffect(() => {}, [projects]);

	const table = useMantineReactTable({
		defaultColumn,
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		columns: columns ? columns : [],
		data: projects ? projects : [],
		enableColumnFilterModes,
		enableColumnOrdering,
		enableFacetedValues,
		enableDensityToggle,
		enableGrouping,
		enableColumnActions,
		enablePinning,
		enableRowSelection,
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40 //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 15, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 15 //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		enableRowActions,
		initialState: {
			...initialState,
			density: "xs",
			showGlobalFilter: true,
			sorting: Sorting
		},
		paginationDisplayMode,
		positionToolbarAlertBanner,
		mantinePaginationProps,
		enableClickToCopy,
		mantineSearchTextInputProps,

		enableColumnResizing,
		enableStickyHeader,
		enableStickyFooter,
		enableEditing,
		enableSorting,
		manualPagination: true,
		manualFiltering: true,
		enableColumnFilters,
		manualSorting: false,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		showSkeletons,
		mantinePaperProps: {
			h: "100%" // Ensure the paper container takes full height
		},
		mantineTableContainerProps: {
			h: mantineTableContainerPropsHeight // Ensure the body container takes full height
		},
		mantineToolbarAlertBannerProps: isError
			? { color: "red", children: "Error loading data" }
			: undefined,
		rowCount: CollectionCount,
		// fixed header and footer
		renderDetailPanel: ({ row }) => {
			let Row = row?.original;
			let steps = Row?.steps;
			let responsibles = [];
			let timAgreement = "";
			let agreementAquipment = "";
			let keyText = "";
			steps?.forEach((item, index) => {
				if (steps.length > 2) {
					let step3 = steps.find((item) => item.queue === 3);
					let step14 = steps?.find((item) => item.queue === 14);
					let step12 = steps?.find((item) => item.queue === 12);
					let step13 = steps?.find((item) => item.queue === 13);
					let step19 = steps?.find((item) => item.queue === 19);
					let step42 = steps?.find((item) => item.queue === 42);
					if (step3) {
						let responsible = step3.steps?.find((item) => item.index === 4);
						responsibles = responsible?.responsible;
					}
					if (step14?.status === "inProgress") {
						timAgreement =
							step14?.startDate + Row.voltage.steps.find((item) => item.index === 14)?.deadline;
						timAgreement = moment(step14.startDate)
							.add(Row.voltage.steps.find((item) => item.index === 14)?.deadline, "days")
							.toISOString();
					}
					if (step12?.status === "inProgress") {
						agreementAquipment =
							step12?.startDate + Row.voltage.steps.find((item) => item.index === 12)?.deadline;
						agreementAquipment = moment(step12.startDate)
							.add(Row.voltage.steps.find((item) => item.index === 12)?.deadline, "days")
							.toISOString();
					}
					if (step42?.status === "inProgress" || step42?.status === "done") {
						keyText = step42?.keyText;
					} else if (step19?.status === "inProgress" || step19?.status === "done") {
						keyText = step19?.keyText;
					} else if (step13?.status === "inProgress" || step13?.status === "done") {
						keyText = step13?.keyText;
					}
				}
			});

			return (
				<Grid style={{ gap: "10px", width: "100%" }}>
					<Grid.Col span={12}>
						<Flex gap={10} justify={"space-between"} align={"center"} mb={10}>
							{(user.ananunaki || CRUD.update) && (
								<>
									<ButtonGroup>
										<Button
											leftSection={
												<IconPlayerPlay
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											w={200}
											variant="light"
											disabled={true}
										>
											{T("play", lang)}
										</Button>

										<Button
											leftSection={
												<IconPlayerPause
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											w={200}
											color="orange"
											variant="light"
											disabled={true}
										>
											{T("pause", lang)}
										</Button>
										<Button
											leftSection={
												<IconPlayerStop
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											w={200}
											color="danger"
											variant="light"
											disabled={true}
										>
											{T("stop", lang)}
										</Button>
									</ButtonGroup>

									<Button
										leftSection={
											<IconEdit size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										rightSection={<IconChevronRight size={14} />}
										style={{
											display: "flex",
											justifyContent: "space-between"
										}}
										onClick={() => {
											history.push(`/project/edit/${row?.original?._id}`);
										}}
										variant="light"
									>
										{T("edit", lang)}
									</Button>
								</>
							)}
						</Flex>
						<Card
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "16px",
								padding: "16px"
							}}
							w={"100%"}
						>
							<Flex justify={"space-between"}>
								<Box>
									<Text size="sm" weight={500}>
										{T("startDate", lang)}
									</Text>
									<Text size="sm" c={moment().isAfter(Row.startDate) ? "red" : "green"}>
										{moment(Row.startDate).calendar()}
									</Text>
								</Box>
								{responsibles?.length > 0 && (
									<Link to={`/user/${responsibles[0]._id}`}>
										<Box>
											<Text size="sm" weight={500}>
												{T("designer", lang)}
											</Text>
											<Text size="sm" c={"blue"}>
												{responsibles
													.map((res) => `${res.firstName?.[lang]} ${res.lastName?.[lang]}`)
													.join(", ")}
											</Text>
										</Box>
									</Link>
								)}
								{timAgreement && (
									<Box>
										<Text size="sm" weight={500}>
											{T("timAgreement", lang)} {T("date", lang)}
										</Text>
										<Text size="sm" c={"cyan"}>
											{moment(timAgreement).calendar()}
										</Text>
									</Box>
								)}
								{agreementAquipment && (
									<Box>
										<Text size="sm" weight={500}>
											{T("agreementAquipment", lang)} {T("date", lang)}
										</Text>
										<Text size="sm" c={"cyan"}>
											{moment(agreementAquipment).calendar()}
										</Text>
									</Box>
								)}
								{keyText && (
									<Box>
										<Text size="sm" weight={500}>
											{T("keyText", lang)}
										</Text>
										<Text size="sm" c={"cyan"}>
											{keyText}
										</Text>
									</Box>
								)}
								<Box>
									<Text size="sm" weight={500}>
										{T("endDate", lang)}
									</Text>
									<Text size="sm" c={moment().isAfter(Row.endDate) ? "red" : "green"}>
										{moment(Row.endDate).calendar()}
									</Text>
								</Box>
							</Flex>
						</Card>
					</Grid.Col>
					<Grid.Col span={12}>
						<Flex gap={10} justify={"space-between"} align={"center"}>
							<Text size="lg" weight={500} p={16}>
								{T("steps", lang)}
							</Text>
							{(user.ananunaki || CRUD.read) && (
								<Button
									color="cyan"
									leftSection={
										<IconViewfinder size={14} color={colorScheme === "dark" ? "white" : "black"} />
									}
									rightSection={<IconChevronRight size={14} />}
									onClick={() => {
										history.push(`/project/steps/${row?.original?._id}`);
									}}
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
									variant="light"
								>
									{T("view", lang)}
								</Button>
							)}
						</Flex>

						<Card
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "16px",
								padding: "16px"
							}}
							w={"100%"}
						>
							{/* // default value is the last item */}
							<Stepper>
								{config.steps.map((step, index) => {
									if (index < steps.length) {
										return (
											<Stepper.Step
												key={index}
												active={step.status === "inProgress"}
												label={step.name[lang]}
												icon={
													step?.disabled ? (
														<span style={{ color: "red" }}>index + 1</span>
													) : step?.bypass ? (
														<span style={{ color: "orange" }}>
															{steps?.[index]?.status === "inProgress" ? (
																<Indicator
																	offset={-10}
																	inline
																	position="top-center"
																	processing
																	color="red"
																	size={12}
																	style={{ zIndex: 1 }}
																>
																	{index + 1}
																</Indicator>
															) : (
																index + 1
															)}
														</span>
													) : steps?.[index]?.status === "done" ? (
														<span style={{ color: "green" }}>{index + 1}</span>
													) : (
														<span style={{ color: "green" }}>
															{steps?.[index]?.status === "inProgress" ? (
																<Indicator
																	offset={-10}
																	inline
																	processing
																	position="top-center"
																	color="red"
																	size={12}
																	style={{ zIndex: 1 }}
																>
																	{index + 1}
																</Indicator>
															) : null}
														</span>
													)
												}
												completedIcon={<span style={{ color: "red" }}>{index + 1}</span>}
											>
												Step 1 content: Create an account
											</Stepper.Step>
										);
									} else {
										return null; // Don't render if index exceeds steps length
									}
								})}
							</Stepper>
						</Card>
					</Grid.Col>
				</Grid>
			);
		},
		onPaginationChange: (props) => {
			let localPagination = {
				pageIndex: Number(
					pageIndex ||
						pageIndex === 0 ||
						(formattedLocation.pageIndex && formattedLocation.pageIndex)
				),
				pageSize: Number(pageSize || (formattedLocation.pageSize && formattedLocation.pageSize))
			};
			if (props.pageIndex !== 0) {
				if (FirstRender) SecondRender = true;
				FirstRender = true;
				setPagination(props);
			} else if (props.pageIndex === 0 && localPagination.pageIndex > 0) {
				if (FirstRender && SecondRender) {
					if (FirstRender) SecondRender = true;
					FirstRender = true;
					setPagination({
						...props,
						pageIndex: 0
					});
				} else {
					if (FirstRender) SecondRender = true;
					FirstRender = true;
					setPagination({
						...props,
						pageIndex: localPagination.pageIndex
					});
				}
			} else {
				if (FirstRender) SecondRender = true;
				FirstRender = true;
				setPagination({ ...props });
			}
		},
		state: {
			ColumnFilters,
			GlobalFilter,
			pagination,
			showAlertBanner: isError
		},
		renderRowActionMenuItems: ({ row }) => {
			return (
				<>
					{(user.ananunaki || CRUD.read) && (
						<Menu.Item>
							<Link to={`/project/steps/${row?.original?._id}`}>
								<Button
									w={130}
									color="cyan"
									leftSection={
										<IconViewfinder size={14} color={colorScheme === "dark" ? "white" : "black"} />
									}
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "space-between"
									}}
									variant="light"
								>
									{T("view", lang)}
								</Button>
							</Link>
						</Menu.Item>
					)}
					{(user.ananunaki || CRUD.update) && (
						<Menu.Item>
							<Link to={`/project/edit/${row?.original?._id}`}>
								<Button
									w={130}
									leftSection={
										<IconEdit size={14} color={colorScheme === "dark" ? "white" : "black"} />
									}
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "space-between"
									}}
									variant="light"
								>
									{T("edit", lang)}
								</Button>
							</Link>
						</Menu.Item>
					)}
					{(user.ananunaki || CRUD.delete) && (
						<Menu.Item>
							<Button
								w={130}
								color="red"
								leftSection={
									<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
								}
								variant="light"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between"
								}}
								onClick={() => {
									DELETE({
										route: "project",
										id: row?.original?._id,
										// history,
										lang,
										setBusy,
										isBusy
									});
								}}
							>
								{T("remove", lang)}
							</Button>
						</Menu.Item>
					)}
				</>
			);
		},
		renderTopToolbar: ({ table }) => {
			return (
				<Flex p="md" justify="space-between">
					<Flex gap="xs" wrap={"wrap"} align={"center"} mr={10}>
						{(user.ananunaki || CRUD.create) && (
							<Button
								w={70}
								color="green"
								loading={buttonLoading}
								onClick={addNew}
								variant="filled"
							>
								{T("add")}
							</Button>
						)}
					</Flex>

					<Divider orientation="vertical" />

					<ScrollArea h={130}>
						<Flex gap="xs" wrap={"wrap"} align={"center"} ml={10}>
							<Tooltip
								label="Բարձր կարևորության նախագծեր"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={importance === "2" ? "teal" : "cyan"}
									variant={importance === "2" ? "filled" : "light"}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("importance") === "2") {
											clearAllFilters();
											searchParams.delete("importance");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setImportance(null);
										} else {
											clearAllFilters();
											setImportance("2"); // high importance
											searchParams.set("importance", "2");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Բարձր կարևորության նախագծեր
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Միջին կարևորության նախագծեր"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("importance") === "1" ? "teal" : "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("importance") === "1"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("importance") === "1") {
											clearAllFilters();
											searchParams.delete("importance");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setImportance(null);
										} else {
											clearAllFilters();
											setImportance("1"); // medium importance
											searchParams.set("importance", "1");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Միջին կարևորության նախագծեր
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Տեղազնված չէ"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-1") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-1") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-1") === "true") {
											clearAllFilters();
											searchParams.delete("filter-1");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-1", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["1"]);
											setStepStatus("1");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Տեղազնված չէ
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Ավարտված նախագծեր"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("completed") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("completed") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("completed") === "true") {
											clearAllFilters();
											searchParams.delete("completed");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("completed", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["45"]);
											setStepStatus("2");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Ավարտված նախագծեր
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Ընթացիկ նախագծեր"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("in-progress") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("in-progress") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("in-progress") === "true") {
											clearAllFilters();
											searchParams.delete("in-progress");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("in-progress", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([
												"1",
												"2",
												"3",
												"4",
												"5",
												"6",
												"7",
												"8",
												"9",
												"10",
												"11",
												"12",
												"13",
												"14",
												"15",
												"16",
												"17",
												"18",
												"19",
												"20",
												"21",
												"22",
												"23",
												"24",
												"25",
												"26",
												"27",
												"28",
												"29",
												"30",
												"31",
												"32",
												"33",
												"34",
												"35",
												"36",
												"37",
												"38"
											]);
											setStepStatus("1");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Ընթացիկ նախագծեր
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Տիմ չուղարկված, բայց տեղազննված"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-2") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-2") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-2") === "true") {
											clearAllFilters();
											searchParams.delete("filter-2");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-2", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["3", "4", "5", "6", "10", "13"]);
											setStepStatus("1");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Տիմ չուղարկված, բայց տեղազննված
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Շինարարական բաժնի առաջադրանք"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-5") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-5") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-5") === "true") {
											clearAllFilters();
											searchParams.delete("filter-5");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-5", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["2"]);
											setStepStatus("1"); // step inprogress
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Շինարարական բաժնի առաջադրանք
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Ծավալների փուլում"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-6") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-6") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-6") === "true") {
											clearAllFilters();
											searchParams.delete("filter-6");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-6", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["21", "27"]); // visitation
											setStepStatus("1"); // step inprogress
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Ծավալների փուլում
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Նախահաշվի փուլում"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-7") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-7") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-7") === "true") {
											clearAllFilters();
											searchParams.delete("filter-7");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-7", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["28", "31"]); // visitation
											setStepStatus("1"); // step inprogress
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Նախահաշվի փուլում
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Փաթեթի կազման փուլում"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-8") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-8") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-8") === "true") {
											clearAllFilters();
											searchParams.delete("filter-8");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-8", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["34", "35"]); // visitation
											setStepStatus("1"); // step inprogress
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Փաթեթի կազման փուլում
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Երևան ՇԹ փուլում"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-9") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-9") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-9") === "true") {
											clearAllFilters();
											searchParams.delete("filter-9");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-9", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["39", "44"]); // visitation
											setStepStatus("1"); // step inprogress
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Երևան ՇԹ փուլում
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Այսօր Հաստատված նախահաշիվներ"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-12") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-12") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-12") === "true") {
											clearAllFilters();
											searchParams.delete("filter-12");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
											setSpecificDateRange(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-12", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["33"]); // visitation
											setStepStatus("2"); // step done
											setSpecificDateRange({
												from: moment().startOf("day").toISOString(),
												to: moment().endOf("day").toISOString()
											});
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Այսօր Հաստատված նախահաշիվներ
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="ԷՀԳ եզրակացություն"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-10") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-10") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-10") === "true") {
											clearAllFilters();
											searchParams.delete("filter-10");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setSpecificFieldInStep(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-10", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["6"]);
											setSpecificFieldInStep("activateStep7_8_9");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										ԷՀԳ եզրակացություն
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Գեոդեզիա"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-11") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-11") === "true"
											? "filled"
											: "light"
									}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-11") === "true") {
											clearAllFilters();
											searchParams.delete("filter-11");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setSpecificFieldInStep(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-11", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps(["6"]);
											setSpecificFieldInStep("activateStep8");
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Գեոդեզիա
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Նախնական ՀԷՑ ուղարկվող նախագծեր"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-3") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-3") === "true"
											? "filled"
											: "light"
									}
									disabled={true}
									onClick={() => {
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-3") === "true") {
											clearAllFilters();
											searchParams.delete("filter-3");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setImportance("");
										} else {
											clearAllFilters();
											searchParams.set("filter-3", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setImportance("2"); // high importance
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Նախնական ՀԷՑ ուղարկվող նախագծեր
									</span>
								</Button>
							</Tooltip>
							<Tooltip
								label="Տիմ մուտք պետք չէ"
								withArrow
								position="top"
								transitionProps={{ transition: "fade", duration: 200 }}
							>
								<Button
									w={200}
									color={
										new URLSearchParams(location.search).get("filter-4") === "true"
											? "teal"
											: "cyan"
									}
									variant={
										new URLSearchParams(location.search).get("filter-4") === "true"
											? "filled"
											: "light"
									}
									disabled={true}
									onClick={() => {
										// Տիմ մուտք պետք չէ (Step 13, 14, 17, 18, 19, 20, 39, 40, 41, 42, 43, 44 փակ), Step 12 ավարտված և 16 չի ավարտվել
										const searchParams = new URLSearchParams(location.search);
										if (searchParams.get("filter-4") === "true") {
											clearAllFilters();
											searchParams.delete("filter-4");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([]);
											setStepStatus(null);
										} else {
											clearAllFilters();
											searchParams.set("filter-4", "true");
											history.push({
												pathname: location.pathname,
												search: searchParams.toString()
											});
											setSelectedSteps([
												"12",
												"13",
												"14",
												"17",
												"18",
												"19",
												"20",
												"39",
												"40",
												"41",
												"42",
												"43",
												"44"
											]);
											setStepStatus("2"); // step done
										}
									}}
								>
									<span
										style={{
											display: "inline-block",
											width: "100%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										Տիմ մուտք պետք չէ
									</span>
								</Button>
							</Tooltip>
						</Flex>
					</ScrollArea>

					<Divider orientation="vertical" />

					<Flex gap="xs" wrap={"wrap"} align={"center"} ml={10}>
						<Button
							onClick={() => {
								Toggle.toggle();
							}}
							color={"green"}
							variant={"light"}
							rightSection={opened ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
						>
							{T("filters", lang)}
						</Button>
						<Button
							rightSection={<IconSearch size={18} />}
							color="orange"
							variant="light"
							onClick={handleSearch}
						>
							{T("search", lang)}
						</Button>
						<Button
							rightSection={<IconTrash size={18} />}
							color="red"
							variant="light"
							onClick={clearAllFilters}
						>
							{T("clear", lang)}
						</Button>
					</Flex>
				</Flex>
			);
		},
		renderBottomToolbar: ({ table }) => {
			return (
				<Grid>
					<Grid.Col span={6} p={0}>
						<Flex gap="xs" align={"center"} justify={"start"} ml={20} h="100%">
							{projects?.length > 0 && (
								<Card h={40} p={10}>
									<Flex gap="xs" justify={"center"} align={"center"}>
										<Text c={"teal.6"}>
											{T("showing")} {pagination?.pageIndex * pagination?.pageSize + 1} -{" "}
											{pagination?.pageIndex * pagination?.pageSize + projects?.length}{" "}
										</Text>
										<Text c={"cyan.6"}>
											{T("total")} {CollectionCount}
										</Text>
									</Flex>
								</Card>
							)}
						</Flex>
					</Grid.Col>
					<Grid.Col span={6}>
						<MRT_BottomToolbar table={table} />
					</Grid.Col>
				</Grid>
			);
		}
	});

	return (
		<>
			<Flex direction={"column"}>
				<Collapse
					style={{ flex: "1 1 auto" }}
					in={opened}
					orientation="vertical"
					transitionDuration={250}
					transitionTimingFunction="ease-in-out"
				>
					<Flex justify="flex-start" align="center" gap={20} p={10} wrap="wrap">
						{projectTypes && projectTypes.length > 0 && (
							<Input
								value={projectType}
								setter={setProjectType}
								optionListValue={projectTypes}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("projectType", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{communities && communities.length > 0 && (
							<Input
								value={community}
								setter={setCommunity}
								optionListValue={communities}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("community", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{branches && branches.length > 0 && (
							<Input
								value={branch}
								setter={setBranch}
								optionListValue={branches}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("branch", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{users && users.length > 0 && (
							<Input
								value={step3SelectedResponsibles}
								setter={setStep3SelectedResponsibles}
								optionListValue={users}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("designer", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{voltages && voltages.length > 0 && (
							<Input
								value={voltage}
								setter={setVoltage}
								optionListValue={voltages}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("voltageLevel", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{projectPartitions && projectPartitions.length > 0 && (
							<Input
								value={projectPartition}
								setter={setProjectPartition}
								optionListValue={projectPartitions}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("projectPartition", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{importances && importances.length > 0 && (
							<Input
								value={importance}
								setter={setImportance}
								optionListValue={importances}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("importance", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{disabledStepsArray && disabledStepsArray.length > 0 && (
							<Input
								width={"210px"}
								value={disabledSteps}
								setter={setDisabledSteps}
								optionListValue={disabledStepsArray}
								type="multiSelect"
								usageType="multiSelect"
								lang={lang}
								label={T("bypassSteps", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{config.steps && config.steps.length > 0 && (
							<Input
								width={"210px"}
								value={selectedSteps}
								setter={setSelectedSteps}
								optionListValue={config.steps}
								type="multiSelect"
								usageType="multiSelect"
								lang={lang}
								label={T("selectedSteps", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{stepStatuses && stepStatuses.length > 0 && (
							<Input
								value={stepStatus}
								setter={setStepStatus}
								optionListValue={stepStatuses}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("stepStatus", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
					</Flex>
				</Collapse>
			</Flex>
			{showTable ? (
				<div style={{ height: height, overflow: "hidden" }}>
					<MantineReactTable table={table} />
				</div>
			) : (
				<ScrollArea style={{ width: "100%", height: "100%" }}></ScrollArea>
			)}
		</>
	);
};

export default Search;
