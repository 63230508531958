import React from "react";
import { MantineProvider, Table, Checkbox } from "@mantine/core";

import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

export function PermissionsTableComponent({
	value = [],
	setter,
	columns,
	data,
	placeholder,
	disabled,
	componentKey // Renamed from 'key'
}) {
	const handleChange = (row, input, crud) => {
		data.forEach((item) => {
			if (item._id === row._id) {
				item.inputs.forEach((inp) => {
					if (inp._id === input._id) {
						const state = !input[crud];
						if (crud === "update" || crud === "create" || crud === "delete") {
							if (!input.read) {
								input[crud] = state;
								input.read = state;
							} else {
								input[crud] = state;
							}
						} else if (crud === "read" && input.update && input.read) {
							input.create = state;
							input.read = state;
							input.update = state;
							input.delete = state;
						} else {
							input[crud] = state;
						}
					}
				});
			}
		});
		setter([...data]);
	};

	const renderDetailPanel = ({ row }) => {
		return (
			<Table mt={10} mb={10} ml={"4.2vw"} mr={30}>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>Name</Table.Th>
						<Table.Th>Create</Table.Th>
						<Table.Th>Read</Table.Th>
						<Table.Th>Update</Table.Th>
						<Table.Th>Delete</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{row.original?.inputs?.map((input, index) => (
						<Table.Tr key={index}>
							<Table.Td>{input.name}</Table.Td>
							<Table.Td>
								{"create" in input && (
									<Checkbox
										checked={!!input.create}
										onChange={() => handleChange(row.original, input, "create")}
										disabled={disabled}
									/>
								)}
							</Table.Td>
							<Table.Td>
								<Checkbox
									checked={!!input.read}
									onChange={() => handleChange(row.original, input, "read")}
									disabled={disabled}
								/>
							</Table.Td>
							<Table.Td>
								<Checkbox
									checked={!!input.update}
									onChange={() => handleChange(row.original, input, "update")}
									disabled={disabled}
								/>
							</Table.Td>
							<Table.Td>
								{"delete" in input && (
									<Checkbox
										checked={!!input.delete}
										onChange={() => handleChange(row.original, input, "delete")}
										disabled={disabled}
									/>
								)}
							</Table.Td>
						</Table.Tr>
					))}
				</Table.Tbody>
			</Table>
		);
	};

	const table = useMantineReactTable({
		columns,
		data: data || [],
		renderDetailPanel,
		enableSorting: false,
		enableColumnActions: false,
		positionToolbarAlertBanner: "bottom",
		initialState: {
			pagination: {
				pageSize: 100
			}
		}
	});

	return (
		<MantineProvider key={componentKey}>
			<MantineReactTable table={table} />
		</MantineProvider>
	);
}
