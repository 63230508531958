import React from "react";
import { Stepper, rem, useMantineTheme, Indicator } from "@mantine/core";
import { T } from "../../../helpers/translator";

function CustomStepper({
	localSteps,
	activeStep,
	handleStepChange,
	step,
	lang,
	modelSendToServer,
	permissions,
	toggleInner
}) {
	const theme = useMantineTheme();
	let firstTimeClickedOnStepButton = 0;

	return (
		<Stepper
			active={activeStep - 1}
			onStepClick={(selectedStepIndex) => {
				handleStepChange(localSteps[selectedStepIndex]);
				if (step === selectedStepIndex + 1) {
					if (firstTimeClickedOnStepButton === 0) {
						firstTimeClickedOnStepButton = 1;
						toggleInner.toggle();
					} else if (firstTimeClickedOnStepButton === 1) toggleInner.toggle();
				} else {
					firstTimeClickedOnStepButton = 0;
					toggleInner.open();
				}
			}}
			w={"100%"}
			radius="md"
			styles={{
				stepBody: {
					display: "none"
				},
				step: {
					padding: "1px",
					borderRadius: rem(10)
				},
				stepIcon: {
					borderWidth: rem(1)
				},
				separator: {
					marginLeft: "1vw",
					marginRight: "1vw",
					height: rem(3),
					width: rem(10)
				}
			}}
		>
			{localSteps.map((localStep, index) => {
				let stepDoneColor = theme.colors.green[6];
				let stepDoneSelected = theme.colors.blue[7];
				let CompletedIcon = <>{index + 1}</>;
				if (
					modelSendToServer?.steps?.[index]?.bypass &&
					modelSendToServer?.steps?.[index]?.history?.length > 0
				) {
					stepDoneColor = theme.colors.orange[5];
					if (modelSendToServer?.steps?.[index]?.history?.slice(-1)[0]?.status === "inProgress") {
						stepDoneColor = theme.colors.orange[8];
						CompletedIcon = (
							<Indicator
								offset={-10}
								inline
								processing
								color="red"
								size={10}
								style={{
									zIndex: 10
								}}
							>
								{index + 1}
							</Indicator>
						);
					}
				} else if (modelSendToServer?.steps?.[index]?.history?.length > 0) {
					if (modelSendToServer?.steps?.[index]?.history?.slice(-1)[0]?.status === "inProgress") {
						stepDoneColor = theme.colors.blue[7];
						CompletedIcon = (
							<Indicator
								offset={-10}
								inline
								processing
								color="red"
								size={12}
								style={{
									zIndex: 10
								}}
							>
								{index + 1}
							</Indicator>
						);
					}
				}

				return permissions?.map((input) => {
					if ("step" + localStep._id === input.name) {
						if (input.create || input.update || input.delete || input.read) {
							if (localStep.disabled) {
								return (
									<Stepper.Step
										key={index}
										icon={<span style={{ color: theme.colors.red[5] }}>{index + 1}</span>}
										completedIcon={<span style={{ color: theme.colors.red[5] }}>{index + 1}</span>}
										label={`${T("step", lang)} - ${localStep._id} - ${T("disabled", lang)}`}
										color="red"
										description={localStep.name[lang]}
										styles={{
											step: {
												backgroundColor: theme.colors.red[5],
												cursor: "not-allowed"
											}
										}}
									/>
								);
							} else if (step === index + 1) {
								return (
									<Stepper.Step
										key={index}
										icon={CompletedIcon}
										completedIcon={CompletedIcon}
										label={`${T("step", lang)} - ${localStep._id}`}
										description={localStep.name[lang]}
										styles={{
											step: {
												backgroundColor: stepDoneSelected
											}
										}}
									/>
								);
							} else {
								return (
									<Stepper.Step
										key={index}
										color={stepDoneColor}
										icon={CompletedIcon}
										completedIcon={CompletedIcon}
										label={`${T("step", lang)} - ${localStep._id}`}
										description={localStep.name[lang]}
									/>
								);
							}
						}
					}
				});
			})}
		</Stepper>
	);
}

export default CustomStepper;
