export const Translations = {
	notCorrectLoginCredentials: {
		en: "Not correct login credentials",
		ru: "Неправильные учетные данные для входа",
		hy: "Սխալ մուտքանուն կամ գաղտնաբառ",
		tr: "Yanlış giriş bilgileri",
		ar: "بيانات تسجيل الدخول غير صحيحة",
		zh: "登录凭证不正确",
		fa: "اطلاعات ورود نادرست است",
		he: "פרטי התחברות שגויים",
		fr: "Identifiants de connexion incorrects",
		es: "Credenciales de inicio de sesión incorrectas",
		pt: "Credenciais de login incorretas"
	},
	globalFilter: {
		en: "Global filter",
		ru: "Глобальный фильтр",
		hy: "Ընդհանուր ֆիլտր",
		tr: "Genel filtre",
		ar: "مرشح عالمي",
		zh: "全局过滤器",
		fa: "فیلتر جهانی",
		he: "מסנן גלובלי",
		fr: "Filtre global",
		es: "Filtro global",
		pt: "Filtro global"
	},
	timAgreement: {
		hy: "ՏԻՄ համաձայնեցում",
		en: "TIM agreement",
		ru: "TIM согласование",
		tr: "TIM anlaşması",
		ar: "اتفاق TIM",
		zh: "TIM协议",
		fa: "توافق TIM",
		he: "הסכם TIM",
		fr: "Accord TIM",
		es: "Acuerdo TIM",
		pt: "Acordo TIM"
	},
	newTicket: {
		en: "New ticket",
		ru: "Новый билет",
		hy: "Նոր տոմս",
		tr: "Yeni bilet",
		ar: "تذكرة جديدة",
		zh: "新票",
		fa: "بلیت جدید",
		he: "כרטיס חדש",
		fr: "Nouveau ticket",
		es: "Nuevo ticket",
		pt: "Novo ticket"
	},
	fileHasBeenRemoved: {
		en: "File has been removed",
		ru: "Файл был удален",
		hy: "Ֆայլը ջնջվել է",
		tr: "Dosya kaldırıldı",
		ar: "تمت إزالة الملف",
		zh: "文件已删除",
		fa: "فایل حذف شده است",
		he: "הקובץ הוסר",
		fr: "Le fichier a été supprimé",
		es: "El archivo ha sido eliminado",
		pt: "O arquivo foi removido"
	},
	directMessages: {
		en: "Direct Messages",
		ru: "Прямые сообщения",
		hy: "Ուղիղ նամակներ",
		tr: "Doğrudan Mesajlar",
		ar: "رسائل مباشرة",
		zh: "直接消息",
		fa: "پیام های مستقیم",
		he: "הודעות ישירות",
		fr: "Messages directs",
		es: "Mensajes directos",
		pt: "Mensagens diretas"
	},
	channels: {
		en: "Channels",
		ru: "Каналы",
		hy: "Ալիքներ",
		tr: "Kanallar",
		ar: "قنوات",
		zh: "频道",
		fa: "کانال ها",
		he: "ערוצים",
		fr: "Canaux",
		es: "Canales",
		pt: "Canais"
	},
	channel: {
		en: "Channel",
		ru: "Канал",
		hy: "Ալիք",
		tr: "Kanal",
		ar: "قناة",
		zh: "频道",
		fa: "کانال",
		he: "ערוץ",
		fr: "Canal",
		es: "Canal",
		pt: "Canal"
	},
	groupChats: {
		en: "Group Chats",
		ru: "Групповые чаты",
		hy: "Խումբային չատեր",
		tr: "Grup Sohbetleri",
		ar: "محادثات جماعية",
		zh: "群聊",
		fa: "گروه چت ها",
		he: "צ'אטים קבוצתיים",
		fr: "Chats de groupe",
		es: "Chats de grupo",
		pt: "Chats em grupo"
	},
	answer: {
		en: "Answer",
		ru: "Ответ",
		hy: "Պատասխան",
		tr: "Cevap",
		ar: "إجابة",
		zh: "回答",
		fa: "پاسخ",
		he: "תשובה",
		fr: "Réponse",
		es: "Respuesta",
		pt: "Resposta"
	},
	calling: {
		en: "Calling",
		ru: "Вызов",
		hy: "Զանգել",
		tr: "Arama",
		ar: "مكالمة",
		zh: "呼叫",
		fa: "تماس",
		he: "שיחה",
		fr: "Appel",
		es: "Llamando",
		pt: "Chamando"
	},
	decline: {
		en: "Decline",
		ru: "Отклонить",
		hy: "Մերժել",
		tr: "Reddet",
		ar: "رفض",
		zh: "拒绝",
		fa: "رد کردن",
		he: "לדחות",
		fr: "Décliner",
		es: "Rechazar",
		pt: "Recusar"
	},
	incomingVideoCall: {
		en: "Incoming video call",
		ru: "Входящий видеозвонок",
		hy: "Ստացված տեսանյութային զանգ",
		tr: "Gelen video araması",
		ar: "مكالمة فيديو واردة",
		zh: "来电视频通话",
		fa: "تماس تصویری ورودی",
		he: "שיחת וידאו נכנסת",
		fr: "Appel vidéo entrant",
		es: "Llamada de video entrante",
		pt: "Chamada de vídeo recebida"
	},
	incomingCall: {
		en: "Incoming call",
		ru: "Входящий звонок",
		hy: "Ստացված զանգ",
		tr: "Gelen arama",
		ar: "مكالمة واردة",
		zh: "来电",
		fa: "تماس ورودی",
		he: "שיחה נכנסת",
		fr: "Appel entrant",
		es: "Llamada entrante",
		pt: "Chamada recebida"
	},
	startVideoCall: {
		en: "Start video call",
		ru: "Начать видеозвонок",
		hy: "Սկսել տեսանյութային զանգ",
		tr: "Video aramayı başlat",
		ar: "بدء مكالمة فيديو",
		zh: "开始视频通话",
		fa: "شروع تماس تصویری",
		he: "התחל שיחת וידאו",
		fr: "Démarrer l'appel vidéo",
		es: "Iniciar llamada de video",
		pt: "Iniciar chamada de vídeo"
	},
	startCall: {
		en: "Start call",
		ru: "Начать звонок",
		hy: "Սկսել զանգ",
		tr: "Aramayı başlat",
		ar: "بدء المكالمة",
		zh: "开始通话",
		fa: "شروع تماس",
		he: "התחל שיחה",
		fr: "Démarrer l'appel",
		es: "Iniciar llamada",
		pt: "Iniciar chamada"
	},
	placed: {
		en: "Placed",
		ru: "Размещено",
		hy: "Տեղադրված է",
		tr: "Yerleştirildi",
		ar: "وضعت",
		zh: "放置",
		fa: "قرار داده شده",
		he: "מוצב",
		fr: "Placé",
		es: "Colocado",
		pt: "Colocado"
	},
	filters: {
		en: "Filters",
		ru: "Фильтры",
		hy: "Ֆիլտրներ",
		tr: "Filtreler",
		ar: "مرشحات",
		zh: "过滤器",
		fa: "فیلترها",
		he: "מסננים",
		fr: "Filtres",
		es: "Filtros",
		pt: "Filtros"
	},
	noUsersFound: {
		en: "No users found",
		ru: "Пользователи не найдены",
		hy: "Օգտատերեր չեն գտնվել",
		tr: "Kullanıcı bulunamadı",
		ar: "لم يتم العثور على مستخدمين",
		zh: "未找到用户",
		fa: "کاربری یافت نشد",
		he: "לא נמצאו משתמשים",
		fr: "Aucun utilisateur trouvé",
		es: "No se encontraron usuarios",
		pt: "Nenhum usuário encontrado"
	},
	reopen: {
		en: "Reopen",
		ru: "Восстановить",
		hy: "Բացել նորից",
		tr: "Yeniden aç",
		ar: "إعادة فتح",
		zh: "重新打开",
		fa: "باز کردن مجدد",
		he: "פתח שוב",
		fr: "Rouvrir",
		es: "Reabrir",
		pt: "Reabrir"
	},
	nothingFound: {
		en: "Nothing found",
		ru: "Ничего не найдено",
		hy: "Ոչինչ չի գտնվել",
		tr: "Hiçbir şey bulunamadı",
		ar: "لم يتم العثور على شيء",
		zh: "没有找到任何内容",
		fa: "چیزی یافت نشد",
		he: "לא נמצא כלום",
		fr: "Rien trouvé",
		es: "No se encontró nada",
		pt: "Nada encontrado"
	},
	add: {
		en: "Add",
		ru: "Добавить",
		hy: "Ավելացնել",
		tr: "Ekle",
		ar: "إضافة",
		zh: "添加",
		fa: "افزودن",
		he: "הוסף",
		fr: "Ajouter",
		es: "Añadir",
		pt: "Adicionar"
	},
	notPlaced: {
		en: "Not placed",
		ru: "Не размещено",
		hy: "Չի տեղադրվել",
		tr: "Yerleştirilmedi",
		ar: "لم توضع",
		zh: "未放置",
		fa: "قرار داده نشده",
		he: "לא מוצב",
		fr: "Non placé",
		es: "No colocado",
		pt: "Não colocado"
	},
	processing: {
		en: "Processing",
		ru: "Обработка",
		hy: "Մշակում",
		tr: "İşleniyor",
		ar: "معالجة",
		zh: "处理中",
		fa: "در حال پردازش",
		he: "מעבד",
		fr: "Traitement",
		es: "Procesando",
		pt: "Processando"
	},
	uploading: {
		en: "Uploading",
		ru: "Загрузка",
		hy: "Վերբեռնում",
		tr: "Yükleniyor",
		ar: "تحميل",
		zh: "上传中",
		fa: "در حال بارگذاری",
		he: "מעלה",
		fr: "Téléchargement",
		es: "Subiendo",
		pt: "Carregando"
	},
	oneOf: {
		en: "One of",
		ru: "Один из",
		hy: "Մեկը",
		tr: "Bir",
		ar: "واحد من",
		zh: "之一",
		fa: "یکی از",
		he: "אחד מ",
		fr: "Un de",
		es: "Uno de",
		pt: "Um de"
	},
	platformSpecificSettings: {
		en: "Platform specific settings",
		ru: "Настройки, специфичные для платформы",
		hy: "Համակարգի հատուկ կարգավորումներ",
		tr: "Platforma özgü ayarlar",
		ar: "إعدادات محددة للمنصة",
		zh: "平台特定设置",
		fa: "تنظیمات خاص پلتفرم",
		he: "הגדרות ספציפיות לפלטפורמה",
		fr: "Paramètres spécifiques à la plateforme",
		es: "Configuración específica de la plataforma",
		pt: "Configurações específicas da plataforma"
	},
	forwardedFrom: {
		en: "Forwarded from",
		ru: "Переслано от",
		hy: "Տեղափոխված է",
		tr: "Şuradan iletildi",
		ar: "تم إعادة توجيهه من",
		zh: "从转发",
		fa: "ارسال شده از",
		he: "הועבר מתוך",
		fr: "Transféré de",
		es: "Reenviado desde",
		pt: "Encaminhado de"
	},
	forwardMessage: {
		en: "Forward message",
		ru: "Переслать сообщение",
		hy: "Տեղափոխել նամակը",
		tr: "Mesajı ilet",
		ar: "إعادة توجيه الرسالة",
		zh: "转发消息",
		fa: "پیام را ارسال کنید",
		he: "העבר הודעה",
		fr: "Transférer le message",
		es: "Reenviar mensaje",
		pt: "Encaminhar mensagem"
	},
	disableSteps: {
		en: "Disable steps",
		ru: "Отключить шаги",
		hy: "Անջատել քայլերը",
		tr: "Adımları devre dışı bırak",
		ar: "تعطيل الخطوات",
		zh: "禁用步骤",
		fa: "مراحل را غیرفعال کنید",
		he: "השבת שלבים",
		fr: "Désactiver les étapes",
		es: "Deshabilitar pasos",
		pt: "Desativar etapas"
	},
	pause: {
		en: "Pause",
		ru: "Пауза",
		hy: "Դադարեք",
		tr: "Duraklat",
		ar: "إيقاف مؤقت",
		zh: "暂停",
		fa: "توقف",
		he: "השהה",
		fr: "Pause",
		es: "Pausa",
		pt: "Pausa"
	},
	stop: {
		en: "Stop",
		ru: "Стоп",
		hy: "Կանգնել",
		tr: "Durdur",
		ar: "توقف",
		zh: "停止",
		fa: "توقف",
		he: "עצור",
		fr: "Arrêter",
		es: "Detener",
		pt: "Parar"
	},
	activateStep8: {
		en: "Assignment to the geodetic department ( 8 )",
		ru: "Задание геодезическому отделу ( 8 )",
		hy: "Գեոդեզիական բաժնին առաջադրանք ( 8 )",
		tr: "Jeodezi departmanına atama ( 8 )",
		ar: "تعيين إلى قسم الجيوديسيا ( 8 )",
		zh: "分配到大地测量部门 ( 8 )",
		fa: "تخصیص به بخش ژئودزی ( 8 )",
		he: "הקצאה למחלקת הגיאודזיה ( 8 )",
		fr: "Affectation au département géodésique ( 8 )",
		es: "Asignación al departamento geodésico ( 8 )",
		pt: "Atribuição ao departamento geodésico ( 8 )"
	},
	disabledSteps: {
		en: "Disabled steps",
		ru: "Отключенные шаги",
		hy: "Անաշխատ քայլեր",
		tr: "Devre dışı bırakılan adımlar",
		ar: "الخطوات المعطلة",
		zh: "禁用步骤",
		fa: "مراحل غیرفعال",
		he: "שלבים מושבתים",
		fr: "Étapes désactivées",
		es: "Pasos deshabilitados",
		pt: "Etapas desativadas"
	},
	activateStep7_8_9: {
		en: "Assignment to the Department of Power Transmission Lines ( 7, 8, 9 )",
		ru: "Задание отделу проектирования линий электропередачи ( 7, 8, 9 )",
		hy: "ԷՀԳ բաժնին առաջադրանք ( 7, 8, 9 )",
		tr: "Güç İletim Hatları Departmanına atama ( 7, 8, 9 )",
		ar: "تعيين إلى قسم خطوط نقل الطاقة ( 7, 8, 9 )",
		zh: "分配到电力传输线路部门 ( 7, 8, 9 )",
		fa: "تخصیص به بخش خطوط انتقال نیرو ( 7, 8, 9 )",
		he: "הקצאה למחלקת קווי העברת חשמל ( 7, 8, 9 )",
		fr: "Affectation au département des lignes de transmission d'énergie ( 7, 8, 9 )",
		es: "Asignación al departamento de líneas de transmisión de energía ( 7, 8, 9 )",
		pt: "Atribuição ao departamento de linhas de transmissão de energia ( 7, 8, 9 )"
	},
	viewSteps: {
		en: "View steps",
		ru: "Просмотр шагов",
		hy: "Դիտել քայլերը",
		tr: "Adımları görüntüle",
		ar: "عرض الخطوات",
		zh: "查看步骤",
		fa: "مشاهده مراحل",
		he: "הצג שלבים",
		fr: "Voir les étapes",
		es: "Ver pasos",
		pt: "Ver etapas"
	},
	newMessage: {
		en: "New message",
		ru: "Новое сообщение",
		hy: "Նոր նամակ",
		tr: "Yeni mesaj",
		ar: "رسالة جديدة",
		zh: "新消息",
		fa: "پیام جدید",
		he: "הודעה חדשה",
		fr: "Nouveau message",
		es: "Nuevo mensaje",
		pt: "Nova mensagem"
	},
	viewProject: {
		en: "View project",
		ru: "Просмотр проекта",
		hy: "Դիտել նախագծը",
		tr: "Proje görüntüle",
		ar: "عرض المشروع",
		zh: "查看项目",
		fa: "مشاهده پروژه",
		he: "הצג פרויקט",
		fr: "Voir le projet",
		es: "Ver proyecto",
		pt: "Ver projeto"
	},
	activeTime: {
		en: "Active time",
		ru: "Активное время",
		hy: "Գործող ժամ",
		tr: "Aktif zaman",
		ar: "وقت نشط",
		zh: "活跃时间",
		fa: "زمان فعال",
		he: "זמן פעיל",
		fr: "Temps actif",
		es: "Tiempo activo",
		pt: "Tempo ativo"
	},
	stepStatusChangedByUser: {
		en: "Step status changed by user",
		ru: "Статус шага изменен пользователем",
		hy: "Քայլի կարգը փոխվել է օգտատերով",
		tr: "Kullanıcı tarafından adım durumu değiştirildi",
		ar: "تم تغيير حالة الخطوة بواسطة المستخدم",
		zh: "用户更改了步骤状态",
		fa: "وضعیت مرحله توسط کاربر تغییر یافت",
		he: "מצב השלב שונה על ידי המשתמש",
		fr: "Le statut de l'étape a été modifié par l'utilisateur",
		es: "El estado del paso fue cambiado por el usuario",
		pt: "O status da etapa foi alterado pelo usuário"
	},
	newTicketCreatedBy: {
		en: "New ticket created by",
		ru: "Новый билет создан",
		hy: "Նոր տոմս ստեղծվել է",
		tr: "Yeni bilet oluşturuldu",
		ar: "تم إنشاء تذكرة جديدة بواسطة",
		zh: "由...创建的新票",
		fa: "بلیت جدید توسط ایجاد شده است",
		he: "כרטיס חדש נוצר על ידי",
		fr: "Nouveau ticket créé par",
		es: "Nuevo ticket creado por",
		pt: "Novo ticket criado por"
	},
	inactiveTime: {
		en: "Inactive time",
		ru: "Неактивное время",
		hy: "Չգործող ժամ",
		tr: "Pasif zaman",
		ar: "وقت غير نشط",
		zh: "非活跃时间",
		fa: "زمان غیرفعال",
		he: "זמן לא פעיל",
		fr: "Temps inactif",
		es: "Tiempo inactivo",
		pt: "Tempo inativo"
	},
	lastLogin: {
		en: "Last login",
		ru: "Последний вход",
		hy: "Վերջին մուտք",
		tr: "Son giriş",
		ar: "آخر تسجيل دخول",
		zh: "上次登录",
		fa: "آخرین ورود",
		he: "התחברות אחרונה",
		fr: "Dernière connexion",
		es: "Último inicio de sesión",
		pt: "Último login"
	},
	detailedFilters: {
		en: "Detailed filters",
		ru: "Подробные фильтры",
		hy: "Մանրամասն ֆիլտրներ",
		tr: "Detaylı filtreler",
		ar: "مرشحات مفصلة",
		zh: "详细过滤器",
		fa: "فیلترهای دقیق",
		he: "מסננים מפורטים",
		fr: "Filtres détaillés",
		es: "Filtros detallados",
		pt: "Filtros detalhados"
	},
	preDefinedFilters: {
		en: "Pre-defined filters",
		ru: "Предопределенные фильтры",
		hy: "Նախապահված ֆիլտրներ",
		tr: "Ön tanımlı filtreler",
		ar: "مرشحات محددة مسبقًا",
		zh: "预定义过滤器",
		fa: "فیلترهای پیش تعریف شده",
		he: "מסננים מוגדרים מראש",
		fr: "Filtres prédéfinis",
		es: "Filtros predefinidos",
		pt: "Filtros pré-definidos"
	},
	responsibleAndUsers: {
		en: "Responsible and Users",
		ru: "Ответственные и пользователи",
		hy: "Պատասխանատուներ և օգտատերեր",
		tr: "Sorumlu ve Kullanıcılar",
		ar: "المسؤولون والمستخدمون",
		zh: "负责人和用户",
		fa: "مسئولین و کاربران",
		he: "אחראים ומשתמשים",
		fr: "Responsables et utilisateurs",
		es: "Responsables y usuarios",
		pt: "Responsáveis e usuários"
	},
	assignmentToTheConstructionDepartment: {
		en: "Assignment to the construction department",
		ru: "Предоставление задания строительному отделу",
		hy: "Շինարարական բաժնին առաջադրանքի տրամադրում",
		tr: "İnşaat departmanına atama",
		ar: "تعيين إلى قسم البناء",
		zh: "分配到建设部门",
		fa: "تخصیص به بخش ساخت و ساز",
		he: "הקצאה למחלקת הבנייה",
		fr: "Affectation au département de la construction",
		es: "Asignación al departamento de construcción",
		pt: "Atribuição ao departamento de construção"
	},
	branchesCitiesVillages: {
		en: "Branches, Cities, Villages",
		ru: "Филиалы, Города, Деревни",
		hy: "Պահեստներ, Քաղաքներ, Գյուղեր",
		tr: "Şubeler, Şehirler, Köyler",
		ar: "الفروع، المدن، القرى",
		zh: "分支机构、城市、村庄",
		fa: "شعب، شهرها، روستاها",
		he: "סניפים, ערים, כפרים",
		fr: "Branches, Villes, Villages",
		es: "Sucursales, Ciudades, Pueblos",
		pt: "Filiais, Cidades, Aldeias"
	},
	deleteForEveryone: {
		en: "Delete for everyone",
		ru: "Удалить для всех",
		hy: "Ջնջել բոլորի համար",
		tr: "Herkes için sil",
		ar: "حذف للجميع",
		zh: "为所有人删除",
		fa: "حذف برای همه",
		he: "מחק לכולם",
		fr: "Supprimer pour tout le monde",
		es: "Eliminar para todos",
		pt: "Excluir para todos"
	},
	main: {
		en: "Main",
		ru: "Главный",
		hy: "Գլխավոր",
		tr: "Ana",
		ar: "رئيسي",
		zh: "主要",
		fa: "اصلی",
		he: "ראשי",
		fr: "Principal",
		es: "Principal",
		pt: "Principal"
	},
	secondary: {
		en: "Secondary",
		ru: "Второстепенный",
		hy: "Երկրորդական",
		tr: "İkincil",
		ar: "ثانوي",
		zh: "次要",
		fa: "ثانویه",
		he: "משני",
		fr: "Secondaire",
		es: "Secundario",
		pt: "Secundário"
	},
	providing: {
		en: "Providing",
		ru: "Предоставление",
		hy: "Տրամադրում",
		tr: "Sağlama",
		ar: "توفير",
		zh: "提供",
		fa: "ارائه",
		he: "מתן",
		fr: "Fournir",
		es: "Proporcionar",
		pt: "Fornecendo"
	},
	entry: {
		en: "Entry",
		ru: "Вход",
		hy: "Մուտք",
		tr: "Giriş",
		ar: "دخول",
		zh: "入口",
		fa: "ورود",
		he: "כניסה",
		fr: "Entrée",
		es: "Entrada",
		pt: "Entrada"
	},
	delivery: {
		en: "Delivery",
		ru: "Доставка",
		hy: "Հանձնում",
		tr: "Teslimat",
		ar: "توصيل",
		zh: "交付",
		fa: "تحویل",
		he: "משלוח",
		fr: "Livraison",
		es: "Entrega",
		pt: "Entrega"
	},
	oldProject: {
		en: "Old Project",
		ru: "Старый проект",
		hy: "Հին նախագիծ",
		tr: "Eski Proje",
		ar: "مشروع قديم",
		zh: "旧项目",
		fa: "پروژه قدیمی",
		he: "פרויקט ישן",
		fr: "Ancien projet",
		es: "Proyecto antiguo",
		pt: "Projeto antigo"
	},
	newProject: {
		en: "New Project",
		ru: "Новый проект",
		hy: "Նոր նախագիծ",
		tr: "Yeni Proje",
		ar: "مشروع جديد",
		zh: "新项目",
		fa: "پروژه جدید",
		he: "פרויקט חדש",
		fr: "Nouveau projet",
		es: "Nuevo proyecto",
		pt: "Novo projeto"
	},
	costOfBuildingPermit: {
		en: "Cost of building permit",
		ru: "Стоимость разрешения на строительство",
		hy: "Շին թույլտվության արժեք",
		tr: "İnşaat izni maliyeti",
		ar: "تكلفة تصريح البناء",
		zh: "建筑许可证费用",
		fa: "هزینه مجوز ساخت",
		he: "עלות היתר בנייה",
		fr: "Coût du permis de construire",
		es: "Costo del permiso de construcción",
		pt: "Custo do alvará de construção"
	},
	costOfBuildingPart: {
		en: "Cost of building part",
		ru: "Стоимость строительной части",
		hy: "Շինարարական մասի արժեք",
		tr: "Bina kısmının maliyeti",
		ar: "تكلفة جزء البناء",
		zh: "建筑部分费用",
		fa: "هزینه بخش ساختمان",
		he: "עלות חלק הבניין",
		fr: "Coût de la partie construction",
		es: "Costo de la parte de construcción",
		pt: "Custo da parte de construção"
	},
	keyText: {
		en: "Key Text",
		ru: "Ключевой текст",
		hy: "Բանալի",
		tr: "Anahtar Metin",
		ar: "النص الأساسي",
		zh: "关键文本",
		fa: "متن کلیدی",
		he: "טקסט מפתח",
		fr: "Texte clé",
		es: "Texto clave",
		pt: "Texto chave"
	},
	reply: {
		en: "Reply",
		tr: "Cevapla",
		ar: "رد",
		zh: "回复",
		fa: "پاسخ",
		he: "להגיב",
		fr: "Répondre",
		es: "Responder",
		pt: "Responder",
		hy: "Պատասխանել",
		ru: "Ответить"
	},
	delete: {
		en: "Delete",
		tr: "Sil",
		ar: "حذف",
		zh: "删除",
		fa: "حذف",
		he: "למחוק",
		fr: "Supprimer",
		es: "Eliminar",
		hy: "Ջնջել",
		ru: "Удалить",
		pt: "Apagar"
	},
	continue: {
		en: "Continue",
		tr: "Devam et",
		ar: "متابعة",
		zh: "继续",
		fa: "ادامه",
		he: "להמשיך",
		fr: "Continuer",
		hy: "Շարունակել",
		ru: "Продолжить",
		es: "Continuar",
		pt: "Continuar"
	},
	chatDirect: {
		en: "Chat Direct",
		tr: "Doğrudan Sohbet",
		ar: "محادثة مباشرة",
		zh: "直接聊天",
		fa: "چت مستقیم",
		hy: "Անմիջապես խոսք",
		ru: "Прямой чат",
		he: "צ'אט ישיר",
		fr: "Discussion directe",
		es: "Chat directo",
		pt: "Chat direto"
	},
	selectChatPartner: {
		en: "Select chat partner",
		tr: "Sohbet ortağını seç",
		ar: "اختر شريك الدردشة",
		zh: "选择聊天伙伴",
		fa: "انتخاب شریک چت",
		he: "בחר שותף לצ'אט",
		fr: "Sélectionnez un partenaire de chat",
		es: "Seleccionar compañero de chat",
		hy: "Ընտրեք խոսքի պատասխան",
		ru: "Выберите партнера для чата",
		pt: "Selecione um parceiro de chat"
	},
	private: {
		en: "Private",
		tr: "Özel",
		ar: "خاص",
		zh: "私人",
		fa: "خصوصی",
		he: "פרטי",
		fr: "Privé",
		hy: "Անհատական",
		ru: "Частный",
		es: "Privado",
		pt: "Privado"
	},
	group: {
		en: "Group",
		tr: "Grup",
		ar: "مجموعة",
		zh: "组",
		fa: "گروه",
		he: "קבוצה",
		fr: "Groupe",
		es: "Grupo",
		hy: "Խումբ",
		ru: "Группа",
		pt: "Grupo"
	},
	messages: {
		en: "Messages",
		tr: "Mesajlar",
		ar: "رسائل",
		zh: "消息",
		fa: "پیام‌ها",
		he: "הודעות",
		fr: "Messages",
		ru: "Сообщения",
		hy: "Նամակներ",
		es: "Mensajes",
		pt: "Mensagens"
	},
	userArchive: {
		en: "User Archive",
		tr: "Kullanıcı Arşivi",
		ar: "أرشيف المستخدم",
		zh: "用户档案",
		fa: "آرشیو کاربر",
		ru: "Архив пользователя",
		hy: "Օգտագործողի արխիվ",
		he: "ארכיון משתמש",
		fr: "Archive utilisateur",
		es: "Archivo de usuario",
		pt: "Arquivo do usuário"
	},
	noNotifications: {
		en: "No notifications",
		tr: "Bildirim yok",
		ar: "لا إشعارات",
		zh: "无通知",
		fa: "اعلانی نیست",
		ru: "Нет уведомлений",
		hy: "Ոչ մի ծանուցում",
		he: "אין התראות",
		fr: "Aucune notification",
		es: "Sin notificaciones",
		pt: "Sem notificações"
	},
	created: {
		en: "Created",
		tr: "Oluşturuldu",
		ar: "تم الإنشاء",
		zh: "已创建",
		fa: "ایجاد شد",
		he: "נוצר",
		ru: "Создано",
		hy: "Ստեղծվել է",
		fr: "Créé",
		es: "Creado",
		pt: "Criado"
	},
	days: {
		en: "days",
		tr: "gün",
		ar: "أيام",
		zh: "天",
		fa: "روز",
		he: "ימים",
		fr: "jours",
		es: "días",
		pt: "dias",
		hy: "օրեր",
		ru: "дни"
	},
	ports: {
		en: "Ports",
		tr: "Portlar",
		ar: "منافذ",
		zh: "端口",
		fa: "پورت‌ها",
		he: "יציאות",
		fr: "Ports",
		es: "Puertos",
		pt: "Portos",
		hy: "նավահանգիստներ",
		ru: "порты"
	},
	speed: {
		en: "Speed",
		tr: "Hız",
		ar: "سرعة",
		zh: "速度",
		fa: "سرعت",
		he: "מהירות",
		fr: "Vitesse",
		es: "Velocidad",
		pt: "Velocidade",
		hy: "արագություն",
		ru: "скорость"
	},
	dates: {
		en: "Dates",
		tr: "Tarihler",
		ar: "تواريخ",
		zh: "日期",
		fa: "تاریخ‌ها",
		he: "תאריכים",
		fr: "Dates",
		es: "Fechas",
		pt: "Datas",
		hy: "ամսաթվեր",
		ru: "даты"
	},
	age: {
		en: "Age",
		tr: "Yaş",
		ar: "عمر",
		zh: "年龄",
		fa: "سن",
		he: "גיל",
		fr: "Âge",
		es: "Edad",
		pt: "Idade",
		hy: "տարիք",
		ru: "возраст"
	},
	userUpdated: {
		en: "Updated",
		tr: "Güncellendi",
		ar: "تم التحديث",
		zh: "已更新",
		fa: "به‌روزرسانی شد",
		he: "עודכן",
		fr: "Mis à jour",
		es: "Actualizado",
		pt: "Atualizado",
		hy: "թարմացվել է",
		ru: "обновлено"
	},
	userDeleted: {
		en: "Deleted",
		tr: "Silindi",
		ar: "تم الحذف",
		zh: "已删除",
		fa: "حذف شد",
		he: "נמחק",
		fr: "Supprimé",
		es: "Eliminado",
		pt: "Excluído",
		hy: "ջնջվել է",
		ru: "удалено"
	},
	density: {
		en: "Density",
		tr: "Yoğunluk",
		ar: "الكثافة",
		zh: "密度",
		fa: "چگالی",
		he: "צפיפות",
		fr: "Densité",
		es: "Densidad",
		pt: "Densidade",
		hy: "խտություն",
		ru: "плотность"
	},
	extraSmall: {
		en: "Extra Small",
		tr: "Çok Küçük",
		ar: "صغير جدًا",
		zh: "超小",
		fa: "خیلی کوچک",
		he: "קטן מאוד",
		fr: "Très petit",
		es: "Extra pequeño",
		pt: "Extra pequeno",
		hy: "շատ փոքր",
		ru: "очень маленький"
	},
	small: {
		en: "Small",
		tr: "Küçük",
		ar: "صغير",
		zh: "小",
		fa: "کوچک",
		he: "קטן",
		fr: "Petit",
		es: "Pequeño",
		pt: "Pequeno",
		hy: "փոքր",
		ru: "маленький"
	},
	ticketClosed: {
		en: "Ticket closed",
		tr: "Bilet kapandı",
		ar: "تم إغلاق التذكرة",
		zh: "工单已关闭",
		fa: "بلیت بسته شد",
		he: "כרטיס נסגר",
		fr: "Ticket fermé",
		es: "Ticket cerrado",
		pt: "Ticket fechado",
		hy: "տոմսը փակված է",
		ru: "заявка закрыта"
	},
	medium: {
		en: "Medium",
		tr: "Orta",
		ar: "متوسط",
		zh: "中",
		fa: "متوسط",
		he: "בינוני",
		fr: "Moyen",
		es: "Medio",
		pt: "Médio",
		hy: "միջին",
		ru: "средний"
	},
	large: {
		en: "Large",
		tr: "Büyük",
		ar: "كبير",
		zh: "大",
		fa: "بزرگ",
		he: "גדול",
		fr: "Grand",
		es: "Grande",
		pt: "Grande",
		hy: "մեծ",
		ru: "большой"
	},
	setCostOfBuildingPermit: {
		en: "Set cost of building permit",
		tr: "İnşaat izni maliyetini ayarla",
		ar: "تعيين تكلفة تصريح البناء",
		zh: "设置建筑许可证费用",
		fa: "هزینه مجوز ساخت را تنظیم کنید",
		he: "הגדר עלות של רשיון בנייה",
		fr: "Définir le coût du permis de construire",
		es: "Establecer el costo del permiso de construcción",
		pt: "Definir o custo do alvará de construção",
		hy: "Սահմանել շինարարման թույլտվության արժեքը",
		ru: "Установить стоимость разрешения на строительство"
	},
	designer: {
		en: "Designer",
		tr: "Tasarımcı",
		ar: "مصمم",
		zh: "设计师",
		fa: "طراح",
		he: "מעצב",
		fr: "Designer",
		es: "Diseñador",
		pt: "Designer",
		hy: "Նախագծող",
		ru: "Дизайнер"
	},
	type: {
		en: "Type",
		tr: "Tip",
		ar: "نوع",
		zh: "类型",
		fa: "نوع",
		he: "סוג",
		fr: "Type",
		es: "Tipo",
		pt: "Tipo",
		hy: "Տեսակ",
		ru: "Тип"
	},
	clearFilters: {
		en: "Clear filters",
		tr: "Filtreleri temizle",
		ar: "مسح المرشحات",
		zh: "清除过滤器",
		fa: "پاک کردن فیلترها",
		he: "נקה מסננים",
		fr: "Effacer les filtres",
		es: "Borrar filtros",
		pt: "Limpar filtros",
		hy: "Մաքրել ֆիլտրները",
		ru: "Очистить фильтры"
	},
	extraLarge: {
		en: "Extra Large",
		tr: "Çok Büyük",
		ar: "كبير جدًا",
		zh: "超大",
		fa: "خیلی بزرگ",
		he: "גדול מאוד",
		fr: "Très grand",
		es: "Extra grande",
		pt: "Extra grande",
		hy: "շատ մեծ",
		ru: "очень большой"
	},
	writeUsIfThereAreAnyQuestions: {
		en: "Write us if there are any questions",
		tr: "Sorularınız varsa bize yazın",
		ar: "اكتب لنا إذا كانت لديك أي أسئلة",
		zh: "如果有任何问题，请写信给我们",
		fa: "اگر سوالی دارید برای ما بنویسید",
		he: "כתבו לנו אם יש שאלות",
		fr: "Écrivez-nous si vous avez des questions",
		es: "Escríbanos si tiene alguna pregunta",
		pt: "Escreva para nós se tiver alguma dúvida",
		hy: "Գրեք մեզ, եթե հարցեր ունեք",
		ru: "Напишите нам, если у вас есть вопросы"
	},
	loginSuccess: {
		en: "Login success",
		tr: "Giriş başarılı",
		ar: "تم تسجيل الدخول بنجاح",
		zh: "登录成功",
		fa: "ورود موفقیت‌آمیز",
		he: "הכניסה הצליחה",
		fr: "Connexion réussie",
		es: "Inicio de sesión exitoso",
		pt: "Login bem-sucedido",
		hy: "Մուտքն հաջող է",
		ru: "Успешный вход"
	},
	loginSuccessMessage: {
		en: "You have successfully logged in",
		tr: "Başarıyla giriş yaptınız",
		ar: "لقد سجلت الدخول بنجاح",
		zh: "您已成功登录",
		fa: "با موفقیت وارد شدید",
		he: "נכנסת בהצלחה",
		fr: "Vous vous êtes connecté avec succès",
		es: "Has iniciado sesión con éxito",
		pt: "Você fez login com sucesso",
		hy: "Դուք հաջողությամբ մուտք եք գործել",
		ru: "Вы успешно вошли в систему"
	},
	developedBy: {
		en: "Developed by",
		tr: "Geliştirici",
		ar: "تم تطويره من قبل",
		zh: "由...开发",
		fa: "توسعه یافته توسط",
		he: "פותח על ידי",
		fr: "Développé par",
		es: "Desarrollado por",
		pt: "Desenvolvido por",
		hy: "Մշակված է",
		ru: "Разработано"
	},
	loginFailed: {
		en: "Login failed",
		tr: "Giriş başarısız",
		ar: "فشل تسجيل الدخول",
		zh: "登录失败",
		fa: "ورود ناموفق",
		he: "הכניסה נכשלה",
		fr: "Échec de la connexion",
		es: "Inicio de sesión fallido",
		pt: "Falha no login",
		hy: "Մուտքը ձախողվեց",
		ru: "Ошибка входа"
	},
	loginFailedMessage: {
		en: "Please check your login credentials",
		tr: "Lütfen giriş bilgilerinizi kontrol edin",
		ar: "يرجى التحقق من بيانات تسجيل الدخول الخاصة بك",
		zh: "请检查您的登录凭据",
		fa: "لطفاً اطلاعات ورود خود را بررسی کنید",
		he: "אנא בדוק את פרטי הכניסה שלך",
		fr: "Veuillez vérifier vos identifiants de connexion",
		es: "Por favor, verifique sus credenciales de inicio de sesión",
		pt: "Por favor, verifique suas credenciais de login",
		hy: "Խնդրում ենք ստուգել ձեր մուտքի տվյալները",
		ru: "Пожалуйста, проверьте свои учетные данные"
	},
	permissions: {
		en: "Permissions",
		tr: "İzinler",
		ar: "الأذونات",
		zh: "权限",
		fa: "مجوزها",
		he: "הרשאות",
		fr: "Permissions",
		es: "Permisos",
		pt: "Permissões",
		hy: "Թույլտվություններ",
		ru: "Разрешения"
	},
	macAddress: {
		en: "MAC Address",
		tr: "MAC Adresi",
		ar: "عنوان MAC",
		zh: "MAC地址",
		fa: "آدرس MAC",
		he: "כתובת MAC",
		fr: "Adresse MAC",
		es: "Dirección MAC",
		pt: "Endereço MAC",
		hy: "MAC հասցե",
		ru: "MAC-адрес"
	},
	ipAddress: {
		en: "IP Address",
		tr: "IP Adresi",
		ar: "عنوان IP",
		zh: "IP地址",
		fa: "آدرس IP",
		he: "כתובת IP",
		fr: "Adresse IP",
		es: "Dirección IP",
		pt: "Endereço IP",
		hy: "IP հասցե",
		ru: "IP-адрес"
	},
	deadline: {
		en: "Deadline",
		tr: "Son Tarih",
		ar: "الموعد النهائي",
		zh: "截止日期",
		fa: "مهلت",
		he: "מועד אחרון",
		fr: "Date limite",
		es: "Fecha límite",
		pt: "Prazo",
		hy: "Ժամկետ",
		ru: "Крайний срок"
	},
	printedCount: {
		en: "Printed Count",
		tr: "Baskı Sayısı",
		ar: "عدد المطبوعات",
		zh: "打印数量",
		fa: "تعداد چاپ‌شده",
		he: "מספר הדפסות",
		fr: "Nombre imprimé",
		es: "Conteo impreso",
		pt: "Contagem impressa",
		hy: "Տպված քանակ",
		ru: "Количество распечаток"
	},
	uploadDate: {
		en: "Upload Date",
		tr: "Yükleme Tarihi",
		ar: "تاريخ الرفع",
		zh: "上传日期",
		fa: "تاریخ بارگذاری",
		he: "תאריך העלאה",
		fr: "Date de téléchargement",
		es: "Fecha de subida",
		pt: "Data de envio",
		hy: "Վերբեռնման ամսաթիվ",
		ru: "Дата загрузки"
	},
	seconds: {
		en: "seconds",
		tr: "saniye",
		ar: "ثوانٍ",
		zh: "秒",
		fa: "ثانیه",
		he: "שניות",
		fr: "secondes",
		es: "segundos",
		pt: "segundos",
		hy: "վայրկյան",
		ru: "секунды"
	},
	rightNow: {
		en: "Right now",
		tr: "Hemen şimdi",
		ar: "الآن",
		zh: "现在",
		fa: "همین حالا",
		he: "עכשיו",
		fr: "À l'instant",
		es: "Ahora mismo",
		pt: "Agora mesmo",
		hy: "Այժմ",
		ru: "Прямо сейчас"
	},
	completed: {
		en: "Completed",
		tr: "Tamamlandı",
		ar: "اكتمل",
		zh: "已完成",
		fa: "تکمیل شد",
		he: "הושלם",
		fr: "Terminé",
		es: "Completado",
		pt: "Concluído",
		hy: "Ավարտված է",
		ru: "Завершено"
	},
	update: {
		en: "Update",
		tr: "Güncelle",
		ar: "تحديث",
		zh: "更新",
		fa: "به‌روزرسانی",
		he: "עדכון",
		fr: "Mise à jour",
		es: "Actualizar",
		pt: "Atualizar",
		hy: "Թարմացնել",
		ru: "Обновить"
	},
	ago: {
		en: "ago",
		tr: "önce",
		ar: "منذ",
		zh: "以前",
		fa: "پیش",
		he: "לפני",
		fr: "il y a",
		es: "hace",
		pt: "atrás",
		hy: "առաջ",
		ru: "назад"
	},
	starts: {
		en: "Starts",
		tr: "Başlıyor",
		ar: "يبدأ",
		zh: "开始",
		fa: "شروع می‌شود",
		he: "מתחיל",
		fr: "Commence",
		es: "Comienza",
		pt: "Começa",
		hy: "Սկսվում է",
		ru: "Начинается"
	},
	ends: {
		en: "Ends",
		tr: "Biter",
		ar: "ينتهي",
		zh: "结束",
		fa: "پایان می‌یابد",
		he: "נגמר",
		fr: "Se termine",
		es: "Termina",
		pt: "Termina",
		hy: "Վերջանում է",
		ru: "Заканчивается"
	},
	userVisited: {
		en: "User visited",
		tr: "Kullanıcı ziyaret etti",
		ar: "قام المستخدم بزيارة",
		zh: "用户访问了",
		fa: "کاربر بازدید کرد",
		he: "המשתמש ביקר",
		fr: "L'utilisateur a visité",
		es: "El usuario visitó",
		pt: "O usuário visitou",
		hy: "Օգտատերը այցելեց",
		ru: "Пользователь посетил"
	},
	userEntered: {
		en: "User entered",
		tr: "Kullanıcı girdi",
		ar: "دخل المستخدم",
		zh: "用户进入了",
		fa: "کاربر وارد شد",
		he: "המשתמש נכנס",
		fr: "L'utilisateur est entré",
		es: "El usuario ingresó",
		pt: "O usuário entrou",
		hy: "Օգտատերը մուտք գործեց",
		ru: "Пользователь вошел"
	},
	timeline: {
		en: "Timeline",
		tr: "Zaman Çizelgesi",
		ar: "الجدول الزمني",
		zh: "时间线",
		fa: "خط زمانی",
		he: "ציר זמן",
		fr: "Chronologie",
		es: "Cronología",
		pt: "Linha do tempo",
		hy: "Ժամանակացույց",
		ru: "Лента времени"
	},
	error: {
		en: "Error",
		tr: "Hata",
		ar: "خطأ",
		zh: "错误",
		fa: "خطا",
		he: "שגיאה",
		fr: "Erreur",
		es: "Error",
		pt: "Erro",
		hy: "Սխալ",
		ru: "Ошибка"
	},
	previousStep: {
		en: "Previous step",
		tr: "Önceki adım",
		ar: "الخطوة السابقة",
		zh: "上一步",
		fa: "مرحله قبلی",
		he: "שלב קודם",
		fr: "Étape précédente",
		es: "Paso anterior",
		pt: "Passo anterior",
		hy: "Նախորդ քայլ",
		ru: "Предыдущий шаг"
	},
	nextStep: {
		en: "Next step",
		tr: "Sonraki adım",
		ar: "الخطوة التالية",
		zh: "下一步",
		fa: "مرحله بعدی",
		he: "שלב הבא",
		fr: "Étape suivante",
		es: "Próximo paso",
		pt: "Próximo passo",
		hy: "Հաջորդ քայլ",
		ru: "Следующий шаг"
	},
	yesNextStep: {
		en: "Yes, go to the next step",
		tr: "Evet, bir sonraki adıma geç",
		ar: "نعم، انتقل إلى الخطوة التالية",
		zh: "是的，进行下一步",
		fa: "بله، به مرحله بعد بروید",
		he: "כן, עבור לשלב הבא",
		fr: "Oui, passez à l'étape suivante",
		es: "Sí, vaya al siguiente paso",
		pt: "Sim, vá para o próximo passo",
		hy: "Այո, գնացեք հաջորդ քայլին",
		ru: "Да, перейдите к следующему шагу"
	},
	yesPreviousStep: {
		en: "Yes, go to the previous step",
		tr: "Evet, bir önceki adıma git",
		ar: "نعم، عد إلى الخطوة السابقة",
		zh: "是的，返回上一步",
		fa: "بله، به مرحله قبلی بروید",
		he: "כן, עבור לשלב הקודם",
		fr: "Oui, retournez à l'étape précédente",
		es: "Sí, regrese al paso anterior",
		pt: "Sim, volte para o passo anterior",
		hy: "Այո, վերադարձեք նախորդ քայլին",
		ru: "Да, вернитесь к предыдущему шагу"
	},
	youWontBeAbleToRevert: {
		en: "You won't be able to revert this!",
		tr: "Bunu geri alamazsınız!",
		ar: "لن تتمكن من التراجع عن هذا!",
		zh: "您将无法恢复此操作！",
		fa: "نمی‌توانید این را برگردانید!",
		he: "לא תוכל לבטל את זה!",
		fr: "Vous ne pourrez pas revenir en arrière !",
		es: "¡No podrás revertir esto!",
		pt: "Você não poderá reverter isso!",
		hy: "Դուք չեք կարողանա վերականգնել սա!",
		ru: "Вы не сможете это отменить!"
	},
	somethingWentWrong: {
		en: "Something went wrong",
		tr: "Bir şeyler ters gitti",
		ar: "حدث خطأ ما",
		zh: "出现了问题",
		fa: "چیزی اشتباه پیش رفت",
		he: "משהו השתבש",
		fr: "Quelque chose s'est mal passé",
		es: "Algo salió mal",
		pt: "Algo deu errado",
		hy: "Ինչ-որ բան անհաջող է",
		ru: "Что-то пошло не так"
	},
	showAllSteps: {
		en: "Show all steps",
		tr: "Tüm adımları göster",
		ar: "إظهار جميع الخطوات",
		zh: "显示所有步骤",
		fa: "نمایش همه مراحل",
		he: "הצג את כל השלבים",
		fr: "Afficher toutes les étapes",
		es: "Mostrar todos los pasos",
		pt: "Mostrar todos os passos",
		hy: "Ցուցադրել բոլոր քայլերը",
		ru: "Показать все шаги"
	},
	hideAllSteps: {
		en: "Hide all steps",
		tr: "Tüm adımları gizle",
		ar: "إخفاء جميع الخطوات",
		zh: "隐藏所有步骤",
		fa: "پنهان کردن همه مراحل",
		he: "הסתר את כל השלבים",
		fr: "Masquer toutes les étapes",
		es: "Ocultar todos los pasos",
		pt: "Ocultar todos os passos",
		hy: "Թաքցնել բոլոր քայլերը",
		ru: "Скрыть все шаги"
	},
	hideTimeline: {
		en: "Hide timeline",
		tr: "Zaman çizelgesini gizle",
		ar: "إخفاء الجدول الزمني",
		zh: "隐藏时间线",
		fa: "پنهان کردن خط زمانی",
		he: "הסתר ציר זמן",
		fr: "Masquer la chronologie",
		es: "Ocultar cronología",
		pt: "Ocultar linha do tempo",
		hy: "Թաքցնել ժամանակագրը",
		ru: "Скрыть временную шкалу"
	},
	showTimeline: {
		en: "Show timeline",
		tr: "Zaman çizelgesini göster",
		ar: "إظهار الجدول الزمني",
		zh: "显示时间线",
		fa: "نمایش خط زمانی",
		he: "הצג ציר זמן",
		fr: "Afficher la chronologie",
		es: "Mostrar cronología",
		pt: "Mostrar linha do tempo",
		hy: "Ցուցադրել ժամանակագրը",
		ru: "Показать временную шкалу"
	},
	pickEmoji: {
		en: "Pick emoji",
		ru: "Выберите эмодзи",
		hy: "Ընտրեք էմոջին",
		tr: "Emoji seç",
		ar: "اختر إيموجي",
		zh: "选择表情符号",
		fa: "انتخاب اموجی",
		he: "בחר אמוג'י",
		fr: "Choisissez un emoji",
		es: "Elige un emoji",
		pt: "Escolha um emoji"
	},
	stopRecording: {
		en: "Stop recording",
		ru: "Остановить запись",
		hy: "Կանգնեցնել ձայնագրությունը",
		tr: "Kaydı durdur",
		ar: "توقف عن التسجيل",
		zh: "停止录制",
		fa: "توقف از ضبط",
		he: "עצור את ההקלטה",
		fr: "Arrêter l'enregistrement",
		es: "Detener la grabación",
		pt: "Parar de gravar"
	},
	responsible: {
		en: "Responsible",
		ru: "Ответственный",
		hy: "Պատասխանատու",
		tr: "Sorumlu",
		ar: "مسؤول",
		zh: "负责人",
		fa: "مسئولیت",
		he: "אחראי",
		fr: "Responsable",
		es: "Responsable",
		pt: "Responsável"
	},
	startRecording: {
		en: "Start recording",
		ru: "Начать запись",
		hy: "Սկսել ձայնագրությունը",
		tr: "Kayda başla",
		ar: "ابدأ التسجيل",
		zh: "开始录制",
		fa: "شروع ضبط",
		he: "התחל את ההקלטה",
		fr: "Commencer l'enregistrement",
		es: "Comenzar a grabar",
		pt: "Começar a gravar"
	},
	noResponsible: {
		en: "No responsible",
		ru: "Нет ответственного",
		hy: "Չկա պատասխանատու",
		tr: "Sorumlu yok",
		ar: "لا يوجد مسؤول",
		zh: "没有负责人",
		fa: "هیچ مسئولیتی ندارد",
		he: "אין אחראי",
		fr: "Pas de responsable",
		es: "Sin responsable",
		pt: "Sem responsável"
	},

	attachFile: {
		en: "Attach file",
		ru: "Прикрепить файл",
		hy: "Կցել ֆայլ",
		tr: "Dosya ekle",
		ar: "إرفاق ملف",
		zh: "附加文件",
		fa: "پیوستن فایل",
		he: "צרף קובץ",
		fr: "Joindre un fichier",
		es: "Adjuntar archivo",
		pt: "Anexar arquivo"
	},
	play: {
		en: "Play",
		ru: "Воспроизвести",
		hy: "Միացնել",
		tr: "Oynat",
		ar: "تشغيل",
		zh: "播放",
		fa: "پخش",
		he: "נגן",
		fr: "Jouer",
		es: "Reproducir",
		pt: "Jogar"
	},
	yourFileHasBeenNotSaved: {
		en: "Your file has not been saved.",
		ru: "Ваш файл не был сохранен.",
		hy: "Ձեր ֆայլը չի պահպանվել",
		tr: "Dosyanız kaydedilmedi.",
		ar: "لم يتم حفظ ملفك.",
		zh: "您的文件未保存。",
		fa: "فایل شما ذخیره نشده است.",
		he: "הקובץ שלך לא נשמר.",
		fr: "Votre fichier n'a pas été enregistré.",
		es: "Tu archivo no ha sido guardado.",
		pt: "Seu arquivo não foi salvo."
	},
	showProjectDetails: {
		en: "Show project details",
		ru: "Показать детали проекта",
		hy: "Ցուցադրել նախագծի մանրամասները",
		tr: "Proje ayrıntılarını göster",
		ar: "عرض تفاصيل المشروع",
		zh: "显示项目详情",
		fa: "نمایش جزئیات پروژه",
		he: "הצג פרטי פרויקט",
		fr: "Afficher les détails du projet",
		es: "Mostrar detalles del proyecto",
		pt: "Mostrar detalhes do projeto"
	},
	hideProjectDetails: {
		en: "Hide project details",
		ru: "Скрыть детали проекта",
		hy: "Թաքցնել նախագծի մանրամասները",
		tr: "Proje ayrıntılarını gizle",
		ar: "إخفاء تفاصيل المشروع",
		zh: "隐藏项目详情",
		fa: "مخفی کردن جزئیات پروژه",
		he: "הסתר פרטי פרויקט",
		fr: "Masquer les détails du projet",
		es: "Ocultar detalles del proyecto",
		pt: "Ocultar detalhes do projeto"
	},
	step: {
		en: "Step",
		ru: "Шаг",
		hy: "Քայլ",
		tr: "Adım",
		ar: "خطوة",
		zh: "步骤",
		fa: "گام",
		he: "שלב",
		fr: "Étape",
		es: "Paso",
		pt: "Passo"
	},
	somethingWentWrongInCreatingSteps: {
		en: "Something went wrong in creating steps! Please try again",
		ru: "Что-то пошло не так при создании шагов! Пожалуйста, попробуйте еще раз",
		hy: "Ինչ-որ բան անհաջող է ստեղծել քայլերը։ Խնդրում եմ կրկին փորձեք",
		tr: "Adımlar oluşturulurken bir şeyler ters gitti! Lütfen tekrar deneyin",
		ar: "حدث خطأ أثناء إنشاء الخطوات! حاول مرة أخرى من فضلك",
		zh: "创建步骤时出现问题！请再试一次",
		fa: "در ایجاد مراحل مشکلی پیش آمد! لطفاً دوباره تلاش کنید",
		he: "משהו השתבש ביצירת השלבים! אנא נסה שוב",
		fr: "Une erreur s'est produite lors de la création des étapes ! Veuillez réessayer",
		es: "¡Algo salió mal al crear los pasos! Por favor, inténtalo de nuevo",
		pt: "Algo deu errado ao criar os passos! Por favor, tente novamente"
	},
	errorPageTitle: {
		en: "You have found a secret place.",
		ru: "Вы нашли секретное место.",
		hy: "Դուք գտել եք գաղտնի վայրը։",
		tr: "Gizli bir yer buldunuz.",
		ar: "لقد وجدت مكانًا سريًا.",
		zh: "你发现了一个秘密的地方。",
		fa: "شما یک مکان مخفی پیدا کرده‌اید.",
		he: "מצאת מקום סודי.",
		fr: "Vous avez trouvé un endroit secret.",
		es: "Has encontrado un lugar secreto.",
		pt: "Você encontrou um lugar secreto."
	},
	errorPageText: {
		en: "Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.",
		ru: "К сожалению, это только страница 404. Возможно, вы ошиблись в адресе или страница была перемещена на другой URL.",
		hy: "Ներողություններով, սա միայն 404 էջն է։ Դուք կարող եք սխալ գրել հասցեն, կամ էջը տեղափոխվել է այլ URL-ի վրա։",
		tr: "Maalesef, bu yalnızca bir 404 sayfasıdır. Adresi yanlış yazmış olabilirsiniz veya sayfa başka bir URL'ye taşınmış olabilir.",
		ar: "للأسف، هذه مجرد صفحة 404. ربما أخطأت في كتابة العنوان أو تم نقل الصفحة إلى عنوان آخر.",
		zh: "不幸的是，这只是一个404页面。您可能输入错误地址，或者页面已移至其他URL。",
		fa: "متأسفانه این فقط یک صفحه 404 است. ممکن است آدرس را اشتباه وارد کرده باشید یا صفحه به URL دیگری منتقل شده باشد.",
		he: "לצערנו, זהו רק דף 404. ייתכן שהקלדת את הכתובת באופן שגוי, או שהדף הועבר לכתובת URL אחרת.",
		fr: "Malheureusement, ceci n'est qu'une page 404. Vous avez peut-être mal tapé l'adresse ou la page a été déplacée vers une autre URL.",
		es: "Desafortunadamente, esta es solo una página 404. Puede que hayas escrito mal la dirección o que la página se haya movido a otra URL.",
		pt: "Infelizmente, esta é apenas uma página 404. Você pode ter digitado o endereço errado ou a página foi movida para outro URL."
	},
	errorpageButton: {
		en: "Take me back to home page",
		ru: "Верните меня на домашнюю страницу",
		hy: "Վերադարձեք ինձ դեպի գլխավոր էջ",
		tr: "Beni ana sayfaya geri götür",
		ar: "خذني إلى الصفحة الرئيسية",
		zh: "带我回到主页",
		fa: "مرا به صفحه اصلی بازگردانید",
		he: "קח אותי בחזרה לדף הבית",
		fr: "Ramenez-moi à la page d'accueil",
		es: "Llévame de vuelta a la página de inicio",
		pt: "Leve-me de volta para a página inicial"
	},
	interview1: {
		en: "Interview",
		ru: "Собеседование",
		hy: "Հարցազրույց",
		tr: "Mülakat",
		ar: "مقابلة",
		zh: "面试",
		fa: "مصاحبه",
		he: "ראיון",
		fr: "Entretien",
		es: "Entrevista",
		pt: "Entrevista"
	},
	areYouSureToDelete: {
		en: "Are you sure you want to delete this?",
		ru: "Вы уверены, что хотите удалить это?",
		hy: "Դուք վստա՞հ եք, որ ուզում եք ջնջել սա",
		tr: "Bunu silmek istediğinizden emin misiniz?",
		ar: "هل أنت متأكد أنك تريد حذف هذا؟",
		zh: "您确定要删除此内容吗？",
		fa: "آیا مطمئنید که می‌خواهید این را حذف کنید؟",
		he: "האם אתה בטוח שברצונך למחוק את זה?",
		fr: "Êtes-vous sûr de vouloir supprimer ceci ?",
		es: "¿Estás seguro de que deseas eliminar esto?",
		pt: "Tem certeza de que deseja excluir isso?"
	},
	interview2: {
		en: "Interview",
		ru: "Знакомство",
		hy: "Ծանոթություն",
		tr: "Tanışma",
		ar: "تعرف",
		zh: "见面",
		fa: "آشنایی",
		he: "היכרות",
		fr: "Présentation",
		es: "Entrevista",
		pt: "Entrevista"
	},
	interview3: {
		en: "Training",
		ru: "Обучение",
		hy: "Ուսուցում",
		tr: "Eğitim",
		ar: "تدريب",
		zh: "培训",
		fa: "آموزش",
		he: "הכשרה",
		fr: "Formation",
		es: "Entrenamiento",
		pt: "Treinamento"
	},
	interview4: {
		en: "Internship",
		ru: "Стажирование",
		hy: "Փորձաշրջան",
		tr: "Staj",
		ar: "تدريب عملي",
		zh: "实习",
		fa: "کارآموزی",
		he: "התמחות",
		fr: "Stage",
		es: "Pasantía",
		pt: "Estágio"
	},
	yourFileHasBeenSaved: {
		en: "Your file has been saved.",
		ru: "Ваш файл был сохранен.",
		hy: "Ձեր ֆայլը պահպանված է",
		tr: "Dosyanız kaydedildi.",
		ar: "تم حفظ ملفك.",
		zh: "您的文件已保存。",
		fa: "فایل شما ذخیره شده است.",
		he: "הקובץ שלך נשמר.",
		fr: "Votre fichier a été enregistré.",
		es: "Tu archivo ha sido guardado.",
		pt: "Seu arquivo foi salvo."
	},
	readOnly: {
		en: "Read only",
		ru: "Только чтение",
		hy: "Միայն կարդալ",
		tr: "Yalnızca okunabilir",
		ar: "للقراءة فقط",
		zh: "只读",
		fa: "فقط خواندنی",
		he: "קריאה בלבד",
		fr: "Lecture seule",
		es: "Solo lectura",
		pt: "Somente leitura"
	},
	privateChat: {
		en: "Private chat",
		ru: "Приватный чат",
		hy: "Անձնական խոսք"
	},
	deleted: {
		en: "Deleted",
		ru: "Удален",
		hy: "Ջնջված է"
	},
	yourFileHasBeenDeleted: {
		en: "Your file has been deleted.",
		ru: "Ваш файл был удален.",
		hy: "Ձեր ֆայլը ջնջվել է",
		tr: "Dosyanız silindi.",
		ar: "تم حذف ملفك.",
		zh: "您的文件已被删除。",
		fa: "فایل شما حذف شده است.",
		he: "הקובץ שלך נמחק.",
		fr: "Votre fichier a été supprimé.",
		es: "Tu archivo ha sido eliminado.",
		pt: "Seu arquivo foi deletado."
	},
	table: {
		en: "Table",
		ru: "Таблица",
		hy: "Աղյուսակ",
		tr: "Tablo",
		ar: "جدول",
		zh: "表格",
		fa: "جدول",
		he: "טבלה",
		fr: "Table",
		es: "Tabla",
		pt: "Tabela"
	},
	toDo: {
		en: "To Do",
		ru: "Сделать",
		hy: "Կատարել",
		tr: "Yapılacak",
		ar: "للقيام",
		zh: "待办",
		fa: "برای انجام دادن",
		he: "לעשות",
		fr: "À faire",
		es: "Por hacer",
		pt: "A fazer"
	},
	done: {
		en: "Done",
		ru: "Готово",
		hy: "Ավարտված է",
		tr: "Tamamlandı",
		ar: "تم",
		zh: "完成",
		fa: "انجام شده",
		he: "בוצע",
		fr: "Terminé",
		es: "Hecho",
		pt: "Feito"
	},
	slider: {
		en: "Slider",
		ru: "Слайдер",
		hy: "Սլայդեր",
		tr: "Kaydırıcı",
		ar: "شريط تمرير",
		zh: "滑块",
		fa: "اسلایدر",
		he: "סליידר",
		fr: "Curseur",
		es: "Deslizador",
		pt: "Controle deslizante"
	},
	projectPrice: {
		en: "Project price",
		ru: "Цена проекта",
		hy: "Նախագծի արժեք",
		tr: "Proje fiyatı",
		ar: "سعر المشروع",
		zh: "项目价格",
		fa: "قیمت پروژه",
		he: "מחיר הפרויקט",
		fr: "Prix du projet",
		es: "Precio del proyecto",
		pt: "Preço do projeto"
	},
	rpaPrice: {
		en: "RPA department work price",
		ru: "Стоимость работы отдела РПА",
		hy: "ՌՊԱ բաժնի աշխատանքի արժեք",
		tr: "RPA departmanı iş fiyatı",
		ar: "سعر عمل قسم RPA",
		zh: "RPA部门工作价格",
		fa: "قیمت کار بخش RPA",
		he: "מחיר עבודת מחלקת RPA",
		fr: "Prix du travail du département RPA",
		es: "Precio del trabajo del departamento de RPA",
		pt: "Preço do trabalho do departamento RPA"
	},
	historyTable: {
		en: "History table",
		ru: "Таблица истории",
		hy: "Պատմության աղյուսակ",
		tr: "Geçmiş tablosu",
		ar: "جدول التاريخ",
		zh: "历史表",
		fa: "جدول تاریخچه",
		he: "טבלת היסטוריה",
		fr: "Tableau historique",
		es: "Tabla de historial",
		pt: "Tabela de histórico"
	},
	minutes: {
		en: "Minutes",
		ru: "Минуты",
		hy: "Րոպե",
		tr: "Dakika",
		ar: "دقائق",
		zh: "分钟",
		fa: "دقیقه",
		he: "דקות",
		fr: "Minutes",
		es: "Minutos",
		pt: "Minutos"
	},
	closed: {
		en: "Closed",
		ru: "Закрыто",
		hy: "Փակված է",
		tr: "Kapalı",
		ar: "مغلق",
		zh: "关闭",
		fa: "بسته",
		he: "סגור",
		fr: "Fermé",
		es: "Cerrado",
		pt: "Fechado"
	},
	loading: {
		en: "Loading",
		ru: "Загрузка",
		hy: "Բեռնում",
		tr: "Yükleniyor",
		ar: "جارٍ التحميل",
		zh: "加载中",
		fa: "در حال بارگذاری",
		he: "טוען",
		fr: "Chargement",
		es: "Cargando",
		pt: "Carregando"
	},
	swal: {
		error: {
			en: "Something went wrong!",
			ru: "Что-то пошло не так!",
			hy: "Ինչ-որ բան անհաջող է!"
		},
		success: {
			en: "Success",
			ru: "Успех",
			hy: "Հաջողություն"
		},
		warning: {
			en: "Warning",
			ru: "Предупреждение",
			hy: "Զգուշացում"
		},
		info: {
			en: "Info",
			ru: "Информация",
			hy: "Տեղեկություն"
		}
	},

	selectFiles: {
		en: "Select files",
		ru: "Выберите файлы",
		hy: "Ընտրեք ֆայլերը",
		tr: "Dosyaları seç",
		ar: "حدد الملفات",
		zh: "选择文件",
		fa: "انتخاب فایل‌ها",
		he: "בחר קבצים",
		fr: "Sélectionner des fichiers",
		es: "Seleccionar archivos",
		pt: "Selecionar arquivos"
	},
	socialCardImages: {
		en: "Social card images",
		ru: "Изображения социальной карты",
		hy: "Սոցիալական քարտի նկարներ",
		tr: "Sosyal kart resimleri",
		ar: "صور بطاقة اجتماعية",
		zh: "社交卡图片",
		fa: "تصاویر کارت اجتماعی",
		he: "תמונות כרטיס חברתי",
		fr: "Images de carte sociale",
		es: "Imágenes de tarjeta social",
		pt: "Imagens de cartão social"
	},
	passportImages: {
		en: "Passport images",
		ru: "Изображения паспорта",
		hy: "Անձանց անձնագրի նկարներ",
		tr: "Pasaport resimleri",
		ar: "صور جواز السفر",
		zh: "护照图片",
		fa: "تصاویر پاسپورت",
		he: "תמונות דרכון",
		fr: "Images de passeport",
		es: "Imágenes de pasaporte",
		pt: "Imagens de passaporte"
	},
	diplomaImages: {
		en: "Diploma images",
		ru: "Изображения диплома",
		hy: "Դիպլոմի նկարներ",
		tr: "Diploma resimleri",
		ar: "صور الشهادات",
		zh: "学位证书图片",
		fa: "تصاویر دیپلم",
		he: "תמונות תעודה",
		fr: "Images de diplôme",
		es: "Imágenes de diploma",
		pt: "Imagens de diploma"
	},
	IDCardImages: {
		en: "ID card images",
		ru: "Изображения удостоверения личности",
		hy: "ID Card փաստաթղթեր",
		tr: "Kimlik kartı resimleri",
		ar: "صور بطاقة الهوية",
		zh: "身份证图片",
		fa: "تصاویر کارت شناسایی",
		he: "תמונות כרטיס זהות",
		fr: "Images de carte d'identité",
		es: "Imágenes de tarjeta de identificación",
		pt: "Imagens de cartão de identificação"
	},
	maxSize: {
		en: "Max size",
		ru: "Максимальный размер",
		hy: "Մաքսիմալ չափ",
		tr: "Maksimum boyut",
		ar: "الحجم الأقصى",
		zh: "最大尺寸",
		fa: "حداکثر اندازه",
		he: "גודל מרבי",
		fr: "Taille max",
		es: "Tamaño máximo",
		pt: "Tamanho máximo"
	},
	attach: {
		en: "Attach",
		ru: "Прикрепить",
		hy: "Կցել",
		tr: "Ekle",
		ar: "إرفاق",
		zh: "附加",
		fa: "ضمیمه کردن",
		he: "צרף",
		fr: "Joindre",
		es: "Adjuntar",
		pt: "Anexar"
	},
	image: {
		en: "Image",
		ru: "Изображение",
		hy: "Նկար",
		tr: "Görüntü",
		ar: "صورة",
		zh: "图像",
		fa: "تصویر",
		he: "תמונה",
		fr: "Image",
		es: "Imagen",
		pt: "Imagem"
	},
	pdf: {
		en: "PDF",
		ru: "PDF",
		hy: "PDF",
		tr: "PDF",
		ar: "PDF",
		zh: "PDF",
		fa: "PDF",
		he: "PDF",
		fr: "PDF",
		es: "PDF",
		pt: "PDF"
	},
	files: {
		en: "Files",
		ru: "Файлы",
		hy: "Ֆայլեր",
		tr: "Dosyalar",
		ar: "ملفات",
		zh: "文件",
		fa: "فایل‌ها",
		he: "קבצים",
		fr: "Fichiers",
		es: "Archivos",
		pt: "Arquivos"
	},
	file: {
		en: "File",
		ru: "Файл",
		hy: "Ֆայլ",
		tr: "Dosya",
		ar: "ملف",
		zh: "文件",
		fa: "فایل",
		he: "קובץ",
		fr: "Fichier",
		es: "Archivo",
		pt: "Arquivo"
	},
	visitation: {
		en: "Visitation",
		ru: "Визитация",
		hy: "Այցելում",
		tr: "Ziyaret",
		ar: "زيارة",
		zh: "访问",
		fa: "بازدید",
		he: "ביקור",
		fr: "Visite",
		es: "Visita",
		pt: "Visitação"
	},
	dropzoneText: {
		en: "Drag images here or click to select files",
		ru: "Перетащите изображения сюда или нажмите, чтобы выбрать файлы",
		hy: "Քաշեք նկարները այստեղ կամ սեղմեք, որ ընտրեք ֆայլերը",
		tr: "Görüntüleri buraya sürükleyin veya dosya seçmek için tıklayın",
		ar: "اسحب الصور هنا أو انقر لتحديد الملفات",
		zh: "将图片拖到此处或点击选择文件",
		fa: "تصاویر را اینجا بکشید یا برای انتخاب فایل‌ها کلیک کنید",
		he: "גרור תמונות לכאן או לחץ לבחירת קבצים",
		fr: "Faites glisser les images ici ou cliquez pour sélectionner des fichiers",
		es: "Arrastra las imágenes aquí o haz clic para seleccionar archivos",
		pt: "Arraste imagens aqui ou clique para selecionar arquivos"
	},
	inputNumber: {
		en: "Input number",
		ru: "Ввод номера",
		hy: "Համարի մուտք",
		tr: "Sayı girin",
		ar: "إدخال الرقم",
		zh: "输入号码",
		fa: "ورود شماره",
		he: "הכנס מספר",
		fr: "Saisir un numéro",
		es: "Introducir número",
		pt: "Inserir número"
	},
	inputInLatin: {
		en: "Input in Latin",
		ru: "Ввод на латинице",
		hy: "Մուտքագրում լատինատառերով",
		tr: "Latince giriş",
		ar: "الإدخال باللاتينية",
		zh: "输入拉丁文",
		fa: "ورود به لاتین",
		he: "הקלד בלטינית",
		fr: "Saisir en latin",
		es: "Introducir en latín",
		pt: "Inserir em latim"
	},
	inputInArmenian: {
		en: "Input in Armenian",
		ru: "Ввод на армянском",
		hy: "Մուտքագրում հայերեն",
		tr: "Ermenice giriş",
		ar: "الإدخال باللغة الأرمنية",
		zh: "输入亚美尼亚语",
		fa: "ورود به ارمنی",
		he: "הכנס בארמנית",
		fr: "Saisir en arménien",
		es: "Introducir en armenio",
		pt: "Inserir em armênio"
	},
	inputInRussian: {
		en: "Input in Russian",
		ru: "Ввод на русском",
		hy: "Մուտքագրում ռուսերեն",
		tr: "Rusça giriş",
		ar: "الإدخال باللغة الروسية",
		zh: "输入俄语",
		fa: "ورود به روسی",
		he: "הכנס ברוסית",
		fr: "Saisir en russe",
		es: "Introducir en ruso",
		pt: "Inserir em russo"
	},
	software: {
		en: "Software",
		ru: "Программное обеспечение",
		hy: "Համակարգային ծրագիր",
		tr: "Yazılım",
		ar: "برنامج",
		zh: "软件",
		fa: "نرم‌افزار",
		he: "תוכנה",
		fr: "Logiciel",
		es: "Software",
		pt: "Software"
	},
	fired: {
		en: "Fired",
		ru: "Уволен",
		hy: "Պայմանագրի լուծում",
		tr: "Kovuldu",
		ar: "تم فصله",
		zh: "解雇",
		fa: "اخراج شده",
		he: "פוטר",
		fr: "Renvoyé",
		es: "Despedido",
		pt: "Demitido"
	},
	contract: {
		en: "Contract",
		ru: "Контракт",
		hy: "Պայմանագիր",
		tr: "Sözleşme",
		ar: "عقد",
		zh: "合同",
		fa: "قرارداد",
		he: "חוזה",
		fr: "Contrat",
		es: "Contrato",
		pt: "Contrato"
	},
	maternityLeave: {
		en: "Maternity",
		ru: "Декрет",
		hy: "Դեկրետ",
		tr: "Doğum İzni",
		ar: "إجازة الأمومة",
		zh: "产假",
		fa: "مرخصی زایمان",
		he: "חופשת לידה",
		fr: "Congé maternité",
		es: "Maternidad",
		pt: "Licença maternidade"
	},
	registeredAddress: {
		en: "Registered Address",
		ru: "Зарегистрированный адрес",
		hy: "Գրանցման հասցե",
		tr: "Kayıtlı Adres",
		ar: "العنوان المسجل",
		zh: "注册地址",
		fa: "آدرس ثبت‌شده",
		he: "כתובת רשומה",
		fr: "Adresse enregistrée",
		es: "Dirección registrada",
		pt: "Endereço registrado"
	},
	workingStatus: {
		en: "Working Status",
		ru: "Рабочий статус",
		hy: "Աշխատակցի Կարգավիճակ",
		tr: "Çalışma Durumu",
		ar: "حالة العمل",
		zh: "工作状态",
		fa: "وضعیت کاری",
		he: "סטטוס עבודה",
		fr: "Statut de travail",
		es: "Estado laboral",
		pt: "Status de trabalho"
	},
	working: {
		en: "Working",
		ru: "Работает",
		hy: "Աշխատում է",
		tr: "Çalışıyor",
		ar: "يعمل",
		zh: "工作中",
		fa: "مشغول به کار",
		he: "עובד",
		fr: "En cours",
		es: "Trabajando",
		pt: "Trabalhando"
	},
	cpu: {
		en: "CPU",
		ru: "Процессор",
		hy: "Պրոցեսսոր",
		tr: "CPU",
		ar: "وحدة المعالجة المركزية",
		zh: "中央处理器",
		fa: "پردازنده",
		he: "מעבד",
		fr: "Processeur",
		es: "CPU",
		pt: "Processador"
	},
	ram: {
		en: "RAM",
		ru: "ОЗУ",
		hy: "Հիշողություն",
		tr: "RAM",
		ar: "ذاكرة الوصول العشوائي",
		zh: "内存",
		fa: "رم",
		he: "זיכרון",
		fr: "RAM",
		es: "RAM",
		pt: "Memória RAM"
	},
	rom: {
		en: "ROM",
		ru: "ПЗУ",
		hy: "Հիշողություն",
		tr: "ROM",
		ar: "ذاكرة القراءة فقط",
		zh: "只读存储器",
		fa: "حافظه فقط خواندنی",
		he: "זיכרון לקריאה בלבד",
		fr: "ROM",
		es: "ROM",
		pt: "Memória ROM"
	},
	gpu: {
		en: "GPU",
		ru: "Видеокарта",
		hy: "Տեսաքարտ",
		tr: "GPU",
		ar: "وحدة معالجة الرسومات",
		zh: "图形处理器",
		fa: "کارت گرافیک",
		he: "מעבד גרפי",
		fr: "GPU",
		es: "GPU",
		pt: "Placa Gráfica"
	},
	ip: {
		en: "IP",
		ru: "IP",
		hy: "IP",
		tr: "IP",
		ar: "IP",
		zh: "IP",
		fa: "IP",
		he: "IP",
		fr: "IP",
		es: "IP",
		pt: "IP"
	},
	mac: {
		en: "MAC",
		ru: "MAC",
		hy: "MAC",
		tr: "MAC",
		ar: "MAC",
		zh: "MAC",
		fa: "MAC",
		he: "MAC",
		fr: "MAC",
		es: "MAC",
		pt: "MAC"
	},
	printer: {
		en: "Printer",
		ru: "Принтер",
		hy: "Տպիչ",
		tr: "Yazıcı",
		ar: "طابعة",
		zh: "打印机",
		fa: "چاپگر",
		he: "מדפסת",
		fr: "Imprimante",
		es: "Impresora",
		pt: "Impressora"
	},
	description: {
		en: "Description",
		ru: "Описание",
		hy: "Նկարագրություն",
		tr: "Açıklama",
		ar: "وصف",
		zh: "描述",
		fa: "توضیحات",
		he: "תיאור",
		fr: "Description",
		es: "Descripción",
		pt: "Descrição"
	},
	closedDate: {
		en: "Closed Date",
		ru: "Дата закрытия",
		hy: "Փակված է",
		tr: "Kapanış Tarihi",
		ar: "تاريخ الإغلاق",
		zh: "关闭日期",
		fa: "تاریخ بسته شدن",
		he: "תאריך סגירה",
		fr: "Date de fermeture",
		es: "Fecha de cierre",
		pt: "Data de encerramento"
	},
	selectClosedDate: {
		en: "Select closed date",
		ru: "Выберите дату закрытия",
		hy: "Ընտրեք փակված ամսաթիվ",
		tr: "Kapanış tarihini seçin",
		ar: "حدد تاريخ الإغلاق",
		zh: "选择关闭日期",
		fa: "تاریخ بسته‌شدن را انتخاب کنید",
		he: "בחר תאריך סגירה",
		fr: "Sélectionnez une date de fermeture",
		es: "Seleccionar fecha de cierre",
		pt: "Selecionar data de encerramento"
	},
	languagePickerLabel: {
		en: "Language",
		ru: "Язык",
		hy: "Լեզու",
		tr: "Dil",
		ar: "لغة",
		zh: "语言",
		fa: "زبان",
		he: "שפה",
		fr: "Langue",
		es: "Idioma",
		pt: "Idioma"
	},
	themePickerLabel: {
		en: "Theme",
		ru: "Тема",
		hy: "Թեմա",
		tr: "Tema",
		ar: "موضوع",
		zh: "主题",
		fa: "تم",
		he: "ערכת נושא",
		fr: "Thème",
		es: "Tema",
		pt: "Tema"
	},
	settings: {
		en: "Settings",
		ru: "Настройки",
		hy: "Կարգավորումներ",
		tr: "Ayarlar",
		ar: "الإعدادات",
		zh: "设置",
		fa: "تنظیمات",
		he: "הגדרות",
		fr: "Paramètres",
		es: "Configuración",
		pt: "Configurações"
	},
	logOut: {
		en: "Log out",
		ru: "Выйти",
		hy: "Ելք",
		tr: "Çıkış Yap",
		ar: "تسجيل الخروج",
		zh: "登出",
		fa: "خروج",
		he: "התנתק",
		fr: "Déconnexion",
		es: "Cerrar sesión",
		pt: "Sair"
	},
	community: {
		en: "Community",
		ru: "Сообщество",
		hy: "Համայնք",
		tr: "Topluluk",
		ar: "مجتمع",
		zh: "社区",
		fa: "جامعه",
		he: "קהילה",
		fr: "Communauté",
		es: "Comunidad",
		pt: "Comunidade"
	},
	leader: {
		en: "Leader",
		ru: "Руководитель",
		hy: "Ղեկավար",
		tr: "Lider",
		ar: "قائد",
		zh: "领导",
		fa: "رهبر",
		he: "מַנהִיג",
		fr: "Leader",
		es: "Líder",
		pt: "Líder"
	},
	contractor: {
		en: "Contractor",
		ru: "Подрядчик",
		hy: "Կապալառու",
		tr: "Yüklenici",
		ar: "مقاول",
		zh: "承包商",
		fa: "پیمانکار",
		he: "קבלן",
		fr: "Entrepreneur",
		es: "Contratista",
		pt: "Empreiteiro"
	},
	linkCopied: {
		en: "Link copied",
		ru: "Ссылка скопирована",
		hy: "Լինկը պատճենվել է",
		tr: "Bağlantı kopyalandı",
		ar: "تم نسخ الرابط",
		zh: "链接已复制",
		fa: "لینک کپی شد",
		he: "הקישור הועתק",
		fr: "Lien copié",
		es: "Enlace copiado",
		pt: "Link copiado"
	},
	cart: {
		en: "Employees",
		ru: "Сотрудники",
		hy: "Աշխատակիցներ",
		tr: "Çalışanlar",
		ar: "الموظفين",
		zh: "员工",
		fa: "کارمندان",
		he: "עובדים",
		fr: "Employés",
		es: "Empleados",
		pt: "Funcionários"
	},
	passportNo: {
		en: "Passport No",
		ru: "Паспорт №",
		hy: "Անձնագրի №",
		tr: "Pasaport No",
		ar: "رقم جواز السفر",
		zh: "护照编号",
		fa: "شماره گذرنامه",
		he: "מספר דרכון",
		fr: "N° de passeport",
		es: "Nº de pasaporte",
		pt: "Nº do passaporte"
	},
	idCart: {
		en: "ID Cart",
		ru: "ID Карта",
		hy: "ID քարտ",
		tr: "Kimlik Kartı",
		ar: "بطاقة الهوية",
		zh: "身份证",
		fa: "کارت شناسایی",
		he: "כרטיס זיהוי",
		fr: "Carte d'identité",
		es: "Tarjeta de identificación",
		pt: "Cartão de identificação"
	},
	idCardStartDate: {
		en: "ID Card Start Date",
		ru: "Дата начала ID карты",
		hy: "ID քարտի սկիզբի ամսաթիվ",
		tr: "Kimlik Kartı Başlangıç Tarihi",
		ar: "تاريخ بدء بطاقة الهوية",
		zh: "身份证开始日期",
		fa: "تاریخ شروع کارت شناسایی",
		he: "תאריך תחילת כרטיס זיהוי",
		fr: "Date de début de la carte d'identité",
		es: "Fecha de inicio de la tarjeta de identificación",
		pt: "Data de início do cartão de identificação"
	},
	idCardNo: {
		en: "ID Card No",
		ru: "ID Карта №",
		hy: "ID քարտի №",
		tr: "Kimlik Kartı No",
		ar: "رقم بطاقة الهوية",
		zh: "身份证号",
		fa: "شماره کارت شناسایی",
		he: "מספר כרטיס זיהוי",
		fr: "N° de carte d'identité",
		es: "Nº de tarjeta de identificación",
		pt: "Nº do cartão de identificação"
	},
	idCardEndDate: {
		en: "ID Card End Date",
		ru: "Дата окончания ID карты",
		hy: "ID քարտի ավարտի ամսաթիվ",
		tr: "Kimlik Kartı Bitiş Tarihi",
		ar: "تاريخ انتهاء بطاقة الهوية",
		zh: "身份证结束日期",
		fa: "تاریخ پایان کارت شناسایی",
		he: "תאריך סיום כרטיס זיהוי",
		fr: "Date de fin de la carte d'identité",
		es: "Fecha de finalización de la tarjeta de identificación",
		pt: "Data de término do cartão de identificação"
	},
	bank: {
		en: "Bank",
		ru: "Банк",
		hy: "Բանկ",
		tr: "Banka",
		ar: "بنك",
		zh: "银行",
		fa: "بانک",
		he: "בנק",
		fr: "Banque",
		es: "Banco",
		pt: "Banco"
	},
	bankCart: {
		en: "Bank Card",
		ru: "Банковская карта",
		hy: "Բանկային քարտ",
		tr: "Banka Kartı",
		ar: "بطاقة بنكية",
		zh: "银行卡",
		fa: "کارت بانکی",
		he: "כרטיס בנק",
		fr: "Carte bancaire",
		es: "Tarjeta bancaria",
		pt: "Cartão bancário"
	},
	bankEmitet: {
		en: "Bank",
		ru: "Банк",
		hy: "Բանկ",
		tr: "Banka",
		ar: "بنك",
		zh: "银行",
		fa: "بانک",
		he: "בנק",
		fr: "Banque",
		es: "Banco",
		pt: "Banco"
	},
	bankAccount: {
		en: "Bank Account",
		ru: "Банковский счёт",
		hy: "Բանկային հաշիվ",
		tr: "Banka Hesabı",
		ar: "حساب بنكي",
		zh: "银行账户",
		fa: "حساب بانکی",
		he: "חשבון בנק",
		fr: "Compte bancaire",
		es: "Cuenta bancaria",
		pt: "Conta bancária"
	},
	contractStartDate: {
		en: "Contract Start Date",
		ru: "Дата начала договора",
		hy: "Պայմանագրի սկիզբի ամսաթիվ",
		tr: "Sözleşme Başlangıç Tarihi",
		ar: "تاريخ بدء العقد",
		zh: "合同开始日期",
		fa: "تاریخ شروع قرارداد",
		he: "תאריך תחילת החוזה",
		fr: "Date de début du contrat",
		es: "Fecha de inicio del contrato",
		pt: "Data de início do contrato"
	},
	socialCart: {
		en: "Social Card",
		ru: "Социальная карта",
		hy: "Սոցիալական քարտ",
		tr: "Sosyal Kart",
		ar: "بطاقة اجتماعية",
		zh: "社会卡",
		fa: "کارت اجتماعی",
		he: "כרטיס חברתי",
		fr: "Carte sociale",
		es: "Tarjeta social",
		pt: "Cartão social"
	},
	contractEndDate: {
		en: "Contract End Date",
		ru: "Дата окончания договора",
		hy: "Պայմանագրի ավարտի ամսաթիվ",
		tr: "Sözleşme Bitiş Tarihi",
		ar: "تاريخ انتهاء العقد",
		zh: "合同结束日期",
		fa: "تاریخ پایان قرارداد",
		he: "תאריך סיום החוזה",
		fr: "Date de fin du contrat",
		es: "Fecha de finalización del contrato",
		pt: "Data de término do contrato"
	},
	passportEndDate: {
		en: "Passport End Date",
		ru: "Дата окончания паспорта",
		hy: "Անձնագրի ավարտի ամսաթիվ",
		tr: "Pasaport Bitiş Tarihi",
		ar: "تاريخ انتهاء جواز السفر",
		zh: "护照到期日",
		fa: "تاریخ انقضای گذرنامه",
		he: "תאריך פקיעת דרכון",
		fr: "Date d'expiration du passeport",
		es: "Fecha de vencimiento del pasaporte",
		pt: "Data de validade do passaporte"
	},
	passportStartDate: {
		en: "Passport Start Date",
		ru: "Дата выдачи паспорта",
		hy: "Անձնագրի տրման ամսաթիվ",
		tr: "Pasaport Başlangıç Tarihi",
		ar: "تاريخ إصدار جواز السفر",
		zh: "护照开始日期",
		fa: "تاریخ شروع گذرنامه",
		he: "תאריך הנפקת דרכון",
		fr: "Date de début du passeport",
		es: "Fecha de inicio del pasaporte",
		pt: "Data de início do passaporte"
	},
	changedDate: {
		en: "Change Date",
		ru: "Дата изменения",
		hy: "Փոփոխության ամսաթիվը",
		tr: "Değişiklik Tarihi",
		ar: "تاريخ التغيير",
		zh: "更改日期",
		fa: "تاریخ تغییر",
		he: "תאריך שינוי",
		fr: "Date de modification",
		es: "Fecha de cambio",
		pt: "Data de alteração"
	},
	email: {
		en: "Email",
		ru: "Эл. адрес",
		hy: "Էլ. հասցե",
		tr: "E-posta",
		ar: "البريد الإلكتروني",
		zh: "电子邮件",
		fa: "ایمیل",
		he: "אימייל",
		fr: "E-mail",
		es: "Correo electrónico",
		pt: "E-mail"
	},
	timeRange: {
		en: "Time Range",
		ru: "Временной диапазон",
		hy: "Ժամային հասցեն",
		tr: "Zaman Aralığı",
		ar: "النطاق الزمني",
		zh: "时间范围",
		fa: "بازه زمانی",
		he: "טווח זמן",
		fr: "Plage horaire",
		es: "Rango de tiempo",
		pt: "Intervalo de tempo"
	},
	department: {
		en: "Department",
		ru: "Отделение",
		hy: "Բաժին",
		tr: "Bölüm",
		ar: "قسم",
		zh: "部门",
		fa: "بخش",
		he: "מחלקה",
		fr: "Département",
		es: "Departamento",
		pt: "Departamento"
	},
	time: {
		en: "Time",
		ru: "Время",
		hy: "Ժամ",
		tr: "Zaman",
		ar: "وقت",
		zh: "时间",
		fa: "زمان",
		he: "זמן",
		fr: "Temps",
		es: "Hora",
		pt: "Hora"
	},
	pc: {
		en: "Computer",
		ru: "Компьютер",
		hy: "Համակարգիչ",
		tr: "Bilgisayar",
		ar: "كمبيوتر",
		zh: "电脑",
		fa: "رایانه",
		he: "מחשב",
		fr: "Ordinateur",
		es: "Computadora",
		pt: "Computador"
	},
	position: {
		en: "Position",
		ru: "Должность",
		hy: "Հաստիք",
		tr: "Pozisyon",
		ar: "منصب",
		zh: "职位",
		fa: "موقعیت شغلی",
		he: "עמדה",
		fr: "Poste",
		es: "Posición",
		pt: "Posição"
	},
	user: {
		en: "User",
		ru: "Пользователь",
		hy: "Օգտատեր",
		tr: "Kullanıcı",
		ar: "مستخدم",
		zh: "用户",
		fa: "کاربر",
		he: "משתמש",
		fr: "Utilisateur",
		es: "Usuario",
		pt: "Usuário"
	},
	banner: {
		en: "Banner",
		ru: "Баннер",
		hy: "Բաններ",
		tr: "Afiş",
		ar: "لافتة",
		zh: "横幅",
		fa: "بنر",
		he: "באנר",
		fr: "Bannière",
		es: "Banner",
		pt: "Banner"
	},
	ordersCart: {
		en: "Orders Chart",
		ru: "График заказов",
		hy: "Պատվերների գծապատկեր",
		tr: "Sipariş Grafiği",
		ar: "رسم بياني للطلبات",
		zh: "订单图表",
		fa: "نمودار سفارشات",
		he: "תרשים הזמנות",
		fr: "Tableau des commandes",
		es: "Gráfico de pedidos",
		pt: "Gráfico de pedidos"
	},
	quantity: {
		en: "Quantity",
		ru: "Количество",
		hy: "Քանակ",
		tr: "Miktar",
		ar: "كمية",
		zh: "数量",
		fa: "تعداد",
		he: "כמות",
		fr: "Quantité",
		es: "Cantidad",
		pt: "Quantidade"
	},
	color: {
		en: "Color",
		ru: "Цвет",
		hy: "Գույն",
		tr: "Renk",
		ar: "لون",
		zh: "颜色",
		fa: "رنگ",
		he: "צבע",
		fr: "Couleur",
		es: "Color",
		pt: "Cor"
	},
	addToList: {
		en: "Add to list",
		ru: "Добавить в лист",
		hy: "Ավելացնել լիստում",
		tr: "Listeye ekle",
		ar: "أضف إلى القائمة",
		zh: "添加到列表",
		fa: "اضافه به لیست",
		he: "הוסף לרשימה",
		fr: "Ajouter à la liste",
		es: "Agregar a la lista",
		pt: "Adicionar à lista"
	},
	noContent: {
		en: "There is no content",
		ru: "Нет контента",
		hy: "Բովանդակություն չկա",
		tr: "İçerik yok",
		ar: "لا يوجد محتوى",
		zh: "没有内容",
		fa: "محتوایی وجود ندارد",
		he: "אין תוכן",
		fr: "Il n'y a pas de contenu",
		es: "No hay contenido",
		pt: "Não há conteúdo"
	},
	paymentMethod: {
		en: "Payment method",
		ru: "Метод оплаты",
		hy: "Վճարման եղանակ",
		tr: "Ödeme Yöntemi",
		ar: "طريقة الدفع",
		zh: "支付方式",
		fa: "روش پرداخت",
		he: "שיטת תשלום",
		fr: "Méthode de paiement",
		es: "Método de pago",
		pt: "Método de pagamento"
	},
	paymentSystem: {
		en: "Payment System",
		ru: "Платежная система",
		hy: "Վճարային համակարգ",
		tr: "Ödeme Sistemi",
		ar: "نظام الدفع",
		zh: "支付系统",
		fa: "سیستم پرداخت",
		he: "מערכת תשלום",
		fr: "Système de paiement",
		es: "Sistema de pago",
		pt: "Sistema de pagamento"
	},
	deliveryMethod: {
		en: "Delivery method",
		ru: "Способ доставки",
		hy: "Առաքման եղանակը",
		tr: "Teslimat Yöntemi",
		ar: "طريقة التوصيل",
		zh: "配送方式",
		fa: "روش تحویل",
		he: "שיטת משלוח",
		fr: "Méthode de livraison",
		es: "Método de entrega",
		pt: "Método de entrega"
	},
	deliveryAddress: {
		en: "Delivery Address",
		ru: "Адресс доставки",
		hy: "Առաքման հասցե",
		tr: "Teslimat Adresi",
		ar: "عنوان التوصيل",
		zh: "送货地址",
		fa: "آدرس تحویل",
		he: "כתובת משלוח",
		fr: "Adresse de livraison",
		es: "Dirección de entrega",
		pt: "Endereço de entrega"
	},
	placeholder: {
		en: "Placeholder",
		ru: "Ввод на кирилице",
		hy: "Մուտքագրում հայատառ",
		tr: "Yer Tutucu",
		ar: "نص العنصر النائب",
		zh: "占位符",
		fa: "متن نمایشی",
		he: "מַקום מְשׁוּחר",
		fr: "Espace réservé",
		es: "Marcador de posición",
		pt: "Marcador de posição"
	},
	username: {
		en: "Username",
		ru: "Имя пользователя",
		hy: "Օգտատիրոջ անուն",
		tr: "Kullanıcı Adı",
		ar: "اسم المستخدم",
		zh: "用户名",
		fa: "نام کاربری",
		he: "שם משתמש",
		fr: "Nom d'utilisateur",
		es: "Nombre de usuario",
		pt: "Nome de usuário"
	},
	login: {
		en: "Login",
		ru: "Логин",
		hy: "Մուտք",
		tr: "Giriş",
		ar: "تسجيل الدخول",
		zh: "登录",
		fa: "ورود",
		he: "התחברות",
		fr: "Connexion",
		es: "Iniciar sesión",
		pt: "Entrar"
	},
	loginToStartSession: {
		en: "Sign in to start a session",
		ru: "Войдите, чтобы начать сеанс",
		hy: "Մուտք գործեք՝ սկսելու համար",
		tr: "Oturum açmak için giriş yapın",
		ar: "تسجيل الدخول لبدء الجلسة",
		zh: "登录以开始会话",
		fa: "وارد شوید تا جلسه آغاز شود",
		he: "התחבר כדי להתחיל סשן",
		fr: "Connectez-vous pour commencer une session",
		es: "Inicie sesión para comenzar una sesión",
		pt: "Faça login para iniciar uma sessão"
	},
	loginButton: {
		en: "Log in",
		ru: "Войти",
		hy: "Մուտք",
		tr: "Giriş Yap",
		ar: "تسجيل الدخول",
		zh: "登录",
		fa: "ورود",
		he: "התחברות",
		fr: "Se connecter",
		es: "Iniciar sesión",
		pt: "Entrar"
	},
	chatID: {
		en: "Chat ID",
		ru: "ID чата",
		hy: "Չատի ID",
		tr: "Sohbet ID",
		ar: "معرف الدردشة",
		zh: "聊天ID",
		fa: "شناسه چت",
		he: "מזהה צ'אט",
		fr: "ID de chat",
		es: "ID de chat",
		pt: "ID de chat"
	},
	minute: {
		en: "minute",
		ru: "минута",
		hy: "րոպե",
		tr: "dakika",
		ar: "دقيقة",
		zh: "分钟",
		fa: "دقیقه",
		he: "דקה",
		fr: "minute",
		es: "minuto",
		pt: "minuto"
	},
	reset: {
		en: "Reset",
		ru: "Збросить",
		hy: "Չեղարկել",
		tr: "Sıfırlamak",
		ar: "إعادة تعيين",
		zh: "重置",
		fa: "بازنشانی",
		he: "איפוס",
		fr: "Réinitialiser",
		es: "Restablecer",
		pt: "Redefinir"
	},
	notifications: {
		en: "Notifications",
		ru: "Уведомления",
		hy: "Ծանուցումներ",
		tr: "Bildirimler",
		ar: "الإشعارات",
		zh: "通知",
		fa: "اطلاعات",
		he: "הודעות",
		fr: "Notifications",
		es: "Notificaciones",
		pt: "Notificações"
	},
	supportText: {
		en: "For all questions write",
		ru: "По всем вопросам пишите",
		hy: "Բոլոր հարցերի համար գրեք",
		tr: "Tüm sorular için yazın",
		ar: "لجميع الأسئلة اكتب",
		zh: "对于所有问题写",
		fa: "برای تمام سوالات بنویسید",
		he: "לכל השאלות כתוב",
		fr: "Pour toutes questions, écrivez",
		es: "Para todas las preguntas escriba",
		pt: "Para todas as perguntas escreva"
	},
	incorrectLogin: {
		en: "You entered an incorrect username or password",
		ru: "Вы ввели неверный логин или пароль",
		hy: "Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ",
		tr: "Yanlış kullanıcı adı veya şifre girdiniz",
		ar: "أدخلت اسم مستخدم أو كلمة مرور غير صحيحة",
		zh: "您输入了错误的用户名或密码",
		fa: "نام کاربری یا رمز عبور اشتباهی وارد کردید",
		he: "הכנסת שם משתמש או סיסמה לא נכונה",
		fr: "Vous avez entré un nom d'utilisateur ou un mot de passe incorrect",
		es: "Ingresó un nombre de usuario o una contraseña incorrectos",
		pt: "Você digitou um nome de usuário ou senha incorretos"
	},
	address: {
		en: "Address",
		ru: "Адрес",
		hy: "Հասցե",
		tr: "Adres",
		ar: "عنوان",
		zh: "地址",
		fa: "آدرس",
		he: "כתובת",
		fr: "Adresse",
		es: "Dirección",
		pt: "Endereço"
	},
	dashboard: {
		en: "Dashboard",
		ru: "Доска",
		hy: "Վահանակ",
		tr: "Gösterge Paneli",
		ar: "لوحة القيادة",
		zh: "仪表板",
		fa: "پانل مدیریت",
		he: "לוח בקרה",
		fr: "Tableau de bord",
		es: "Tablero",
		pt: "Painel"
	},
	password: {
		en: "Password",
		ru: "Пароль",
		hy: "Գաղտնաբառ",
		tr: "Şifre",
		ar: "كلمة المرور",
		zh: "密码",
		fa: "رمز عبور",
		he: "סיסמה",
		fr: "Mot de passe",
		es: "Contraseña",
		pt: "Senha"
	},
	href: {
		en: "Href",
		ru: "Ссылка",
		hy: "Հղում",
		tr: "Href",
		ar: "رابط",
		zh: "链接",
		fa: "لینک",
		he: "קישור",
		fr: "Lien",
		es: "Enlace",
		pt: "Link"
	},
	firstName: {
		en: "First Name",
		ru: "Имя",
		hy: "Անուն",
		tr: "Ad",
		ar: "الاسم الأول",
		zh: "名字",
		fa: "نام",
		he: "שם פרטי",
		fr: "Prénom",
		es: "Nombre",
		pt: "Primeiro Nome"
	},
	name: {
		en: "Name",
		ru: "Имя",
		hy: "Անուն",
		tr: "Ad",
		ar: "الاسم",
		zh: "姓名",
		fa: "نام",
		he: "שם",
		fr: "Nom",
		es: "Nombre",
		pt: "Nome"
	},
	lasts: {
		en: "Lasts",
		ru: "Длилось",
		hy: "Տևեց",
		tr: "Sürdü",
		ar: "استمر",
		zh: "持续",
		fa: "طول کشید",
		he: "נמשך",
		fr: "Durée",
		es: "Duración",
		pt: "Duração"
	},
	lastName: {
		en: "Last Name",
		ru: "Фамилия",
		hy: "Ազգանուն",
		tr: "Soyad",
		ar: "اللقب",
		zh: "姓氏",
		fa: "نام خانوادگی",
		he: "שם משפחה",
		fr: "Nom de famille",
		es: "Apellido",
		pt: "Sobrenome"
	},
	dateOfBirth: {
		en: "Date Of Birth",
		ru: "Дата рождения",
		hy: "Ծննդյան ամսաթիվ",
		tr: "Doğum Tarihi",
		ar: "تاريخ الميلاد",
		zh: "出生日期",
		fa: "تاریخ تولد",
		he: "תאריך לידה",
		fr: "Date de naissance",
		es: "Fecha de nacimiento",
		pt: "Data de nascimento"
	},
	desc: {
		en: "Description",
		ru: "Оисание",
		hy: "Նկարագրություն",
		tr: "Açıklama",
		ar: "وصف",
		zh: "描述",
		fa: "توضیحات",
		he: "תיאור",
		fr: "Description",
		es: "Descripción",
		pt: "Descrição"
	},
	subDesc: {
		en: "Sub Description",
		ru: "Под описание",
		hy: "Ենթա նկարագրություն",
		tr: "Alt Açıklama",
		ar: "وصف فرعي",
		zh: "子描述",
		fa: "توضیحات فرعی",
		he: "תת תיאור",
		fr: "Sous-description",
		es: "Subdescripción",
		pt: "Subdescrição"
	},
	cannotPayOnline: {
		en: "Cannot pay online",
		ru: "Невозможно оплатить онлайн",
		hy: "Հնարավոր չէ վճարել առցանց",
		tr: "Online ödeme yapılamaz",
		ar: "لا يمكن الدفع عبر الإنترنت",
		zh: "无法在线支付",
		fa: "نمی توان به صورت آنلاین پرداخت کرد",
		he: "לא ניתן לשלם אונליין",
		fr: "Impossible de payer en ligne",
		es: "No se puede pagar en línea",
		pt: "Não é possível pagar online"
	},
	notAvailable: {
		en: "Product not available",
		ru: "Продукт недоступен",
		hy: "Ապրանքը հասանելի չէ",
		tr: "Ürün mevcut değil",
		ar: "المنتج غير متاح",
		zh: "产品不可用",
		fa: "محصول در دسترس نیست",
		he: "המוצר אינו זמין",
		fr: "Produit non disponible",
		es: "Producto no disponible",
		pt: "Produto não disponível"
	},
	role: {
		en: "Role",
		ru: "Роль",
		hy: "Դեր",
		tr: "Rol",
		ar: "دور",
		zh: "角色",
		fa: "نقش",
		he: "תפקיד",
		fr: "Rôle",
		es: "Rol",
		pt: "Função"
	},
	rate: {
		en: "Rate",
		ru: "Показатель",
		hy: "Գործակից",
		tr: "Oran",
		ar: "معدل",
		zh: "比率",
		fa: "نرخ",
		he: "שיעור",
		fr: "Taux",
		es: "Tasa",
		pt: "Taxa"
	},
	price: {
		en: "Price",
		ru: "Цена",
		hy: "Գին",
		tr: "Fiyat",
		ar: "سعر",
		zh: "价格",
		fa: "قیمت",
		he: "מחיר",
		fr: "Prix",
		es: "Precio",
		pt: "Preço"
	},
	company: {
		en: "Company",
		ru: "Компания",
		hy: "Ընկերություն",
		tr: "Şirket",
		ar: "شركة",
		zh: "公司",
		fa: "شرکت",
		he: "חברה",
		fr: "Société",
		es: "Compañía",
		pt: "Empresa"
	},
	socialNetworkLink: {
		en: "Social Network Link",
		ru: "Ссылка на социальную сеть",
		hy: "Սոցիալական ցանցի հղումը",
		tr: "Sosyal Ağ Bağlantısı",
		ar: "رابط الشبكة الاجتماعية",
		zh: "社交网络链接",
		fa: "لینک شبکه اجتماعی",
		he: "קישור לרשת החברתית",
		fr: "Lien de réseau social",
		es: "Enlace a la red social",
		pt: "Link de rede social"
	},
	phone: {
		en: "Phone",
		ru: "Телефон",
		hy: "Հեռախոս",
		tr: "Telefon",
		ar: "هاتف",
		zh: "电话",
		fa: "تلفن",
		he: "טלפון",
		fr: "Téléphone",
		es: "Teléfono",
		pt: "Telefone"
	},
	priceBasic: {
		en: "Price Basic",
		ru: "Базовая цена",
		hy: "Հիմնական գինը",
		tr: "Temel Fiyat",
		ar: "السعر الأساسي",
		zh: "基本价格",
		fa: "قیمت پایه",
		he: "מחיר בסיסי",
		fr: "Prix de base",
		es: "Precio básico",
		pt: "Preço básico"
	},
	priceB2b: {
		en: "Price Corporate",
		ru: "Цена Корпоративный",
		hy: "Գին Կորպորատիվ",
		tr: "Kurumsal Fiyat",
		ar: "السعر المؤسسي",
		zh: "企业价格",
		fa: "قیمت شرکتی",
		he: "מחיר תאגידי",
		fr: "Prix d'entreprise",
		es: "Precio corporativo",
		pt: "Preço corporativo"
	},
	startDate: {
		en: "Start Date",
		ru: "Дата начала",
		hy: "Մեկնարկի ամսաթիվ",
		tr: "Başlangıç Tarihi",
		ar: "تاريخ البدء",
		zh: "开始日期",
		fa: "تاریخ شروع",
		he: "תאריך התחלה",
		fr: "Date de début",
		es: "Fecha de inicio",
		pt: "Data de início"
	},
	endDate: {
		en: "End Date",
		ru: "Дата окончания",
		hy: "Ավարտի ամսաթիվ",
		tr: "Bitiş Tarihi",
		ar: "تاريخ الانتهاء",
		zh: "结束日期",
		fa: "تاریخ پایان",
		he: "תאריך סיום",
		fr: "Date de fin",
		es: "Fecha de finalización",
		pt: "Data de término"
	},
	status: {
		en: "Status",
		ru: "Статус",
		hy: "Ստատուս",
		tr: "Durum",
		ar: "الحالة",
		zh: "状态",
		fa: "وضعیت",
		he: "סטטוס",
		fr: "Statut",
		es: "Estado",
		pt: "Status"
	},
	paidStatus: {
		en: "Paid Status",
		ru: "Статус оплаты",
		hy: "Վճարման ստատուս",
		tr: "Ödenmiş Durum",
		ar: "حالة الدفع",
		zh: "支付状态",
		fa: "وضعیت پرداخت",
		he: "סטטוס תשלום",
		fr: "Statut de paiement",
		es: "Estado de pago",
		pt: "Status de pagamento"
	},
	client: {
		en: "Client",
		ru: "Клиент",
		hy: "Հաճախորդ",
		tr: "Müşteri",
		ar: "عميل",
		zh: "客户",
		fa: "مشتری",
		he: "לקוח",
		fr: "Client",
		es: "Cliente",
		pt: "Cliente"
	},
	roles: [
		{
			_id: "admin",
			name: { en: "Admin", ru: "Администратор", hy: "Ադմին" }
		},
		{
			_id: "manager",
			name: { en: "Manager", ru: "Управляющий", hy: "Մենեջեր" }
		},
		{
			_id: "editor",
			name: { en: "Editor", ru: "Редактор", hy: "Խմբագիր" }
		}
	],
	genders: [
		{
			name: {
				en: "Male",
				ru: "Мужчина",
				hy: "Արական"
			}
		},
		{
			name: {
				en: "Female",
				ru: "Женщина",
				hy: "իգական"
			}
		}
	],
	gender: {
		en: "Gender",
		ru: "Пол",
		hy: "Սեռ"
	},
	familyStatuses: [
		{
			name: {
				en: "Single",
				ru: "Одинокий",
				hy: "Միայնակ"
			}
		},
		{
			name: {
				en: "Married",
				ru: "В браке",
				hy: "Ամուսնացած"
			}
		}
	],

	more: {
		en: "Additional",
		ru: "Дополнительный",
		hy: "Լրացուցիչ",
		tr: "Ekstra",
		ar: "إضافي",
		zh: "附加",
		fa: "اضافی",
		he: "נוסף",
		fr: "Supplémentaire",
		es: "Adicional",
		pt: "Adicional"
	},
	addNew: {
		en: "Add New",
		ru: "Добавить",
		hy: "Ավելացնել",
		tr: "Yeni Ekle",
		ar: "إضافة جديدة",
		zh: "添加新",
		fa: "افزودن جدید",
		he: "הוסף חדש",
		fr: "Ajouter nouveau",
		es: "Agregar nuevo",
		pt: "Adicionar novo"
	},
	create: {
		en: "Order",
		ru: "Заказ",
		hy: "Պատվեր",
		tr: "Sipariş",
		ar: "طلب",
		zh: "订单",
		fa: "سفارش",
		he: "הזמנה",
		fr: "Commande",
		es: "Orden",
		pt: "Pedido"
	},
	save: {
		en: "Save",
		ru: "Сохранить",
		hy: "Պահպանել",
		tr: "Kaydet",
		ar: "حفظ",
		zh: "保存",
		fa: "ذخیره",
		he: "שמור",
		fr: "Sauvegarder",
		es: "Guardar",
		pt: "Salvar"
	},

	village: {
		en: "Village",
		ru: "Деревня",
		hy: "Գյուղ",
		tr: "Köy",
		ar: "قرية",
		zh: "村",
		fa: "روستا",
		he: "כפר",
		fr: "Village",
		es: "Pueblo",
		pt: "Aldeia"
	},

	sent: {
		en: "Sent",
		ru: "Отправленно",
		hy: "Ուղարկված է",
		tr: "Gönderildi",
		ar: "تم الإرسال",
		zh: "已发送",
		fa: "ارسال شده",
		he: "נשלח",
		fr: "Envoyé",
		es: "Enviado",
		pt: "Enviado"
	},
	send: {
		en: "Send",
		ru: "Отправить",
		hy: "Ուղարկել",
		tr: "Gönder",
		ar: "إرسال",
		zh: "发送",
		fa: "ارسال",
		he: "שלח",
		fr: "Envoyer",
		es: "Enviar",
		pt: "Enviar"
	},
	open: {
		en: "Open",
		ru: "Открыть",
		hy: "Բացել",
		tr: "Aç",
		ar: "افتح",
		zh: "打开",
		fa: "باز کردن",
		he: "פתח",
		fr: "Ouvrir",
		es: "Abrir",
		pt: "Abrir"
	},
	remove: {
		en: "Remove",
		ru: "Удалить",
		hy: "Ջնջել",
		tr: "Kaldır",
		ar: "إزالة",
		zh: "移除",
		fa: "حذف",
		he: "הסר",
		fr: "Retirer",
		es: "Eliminar",
		pt: "Remover"
	},
	thereIsNoResponsible: {
		en: "There is no responsible",
		ru: "Ответственного нет",
		hy: "Չկա պատասխանատու",
		tr: "Sorumlu yok",
		ar: "لا يوجد مسؤول",
		zh: "没有负责人",
		fa: "هیچ مسئولی وجود ندارد",
		he: "אין אחראי",
		fr: "Il n'y a pas de responsable",
		es: "No hay responsable",
		pt: "Não há responsável"
	},
	thePriceOfProject: {
		en: "The price of the project",
		ru: "Стоимость проекта",
		hy: "Աշխատանքի գինը",
		tr: "Proje fiyatı",
		ar: "سعر المشروع",
		zh: "项目价格",
		fa: "قیمت پروژه",
		he: "מחיר הפרויקט",
		fr: "Le prix du projet",
		es: "El precio del proyecto",
		pt: "O preço do projeto"
	},
	stepIsNotStartedYet: {
		en: "Step is not started yet",
		ru: "Шаг еще не начат",
		hy: "Քայլը դեռ սկսված չէ",
		tr: "Adım henüz başlamadı",
		ar: "الخطوة لم تبدأ بعد",
		zh: "步骤尚未开始",
		fa: "مرحله هنوز شروع نشده است",
		he: "השלב עדיין לא התחיל",
		fr: "L'étape n'a pas encore commencé",
		es: "El paso aún no ha comenzado",
		pt: "O passo ainda não começou"
	},
	theNumberOfAct: {
		en: "The number of act",
		ru: "Количество актов",
		hy: "Գործողության թիվը",
		tr: "Eylem sayısı",
		ar: "عدد الأفعال",
		zh: "行为数量",
		fa: "تعداد عمل",
		he: "מספר הפעולות",
		fr: "Le nombre d'actes",
		es: "El número de actos",
		pt: "O número de atos"
	},
	theConstructionDepPrice: {
		en: "The construction department price",
		ru: "Стоимость строительного отдела",
		hy: "Շինանյութի գինը",
		tr: "İnşaat bölümü fiyatı",
		ar: "سعر قسم البناء",
		zh: "建筑部门价格",
		fa: "قیمت بخش ساخت و ساز",
		he: "מחיר מחלקת הבנייה",
		fr: "Le prix du département de construction",
		es: "El precio del departamento de construcción",
		pt: "O preço do departamento de construção"
	},
	cancel: {
		en: "Cancel",
		ru: "Отменить",
		hy: "Չեղարկել",
		tr: "İptal et",
		ar: "إلغاء",
		zh: "取消",
		fa: "لغو",
		he: "ביטול",
		fr: "Annuler",
		es: "Cancelar",
		pt: "Cancelar"
	},
	mediator: {
		en: "Mediator",
		ru: "Посредник",
		hy: "Միջնորդ",
		tr: "Aracı",
		ar: "وسيط",
		zh: "调解员",
		fa: "واسطه",
		he: "בינות",
		fr: "Médiateur",
		es: "Mediador",
		pt: "Mediador"
	},
	edit: {
		en: "Edit",
		ru: "Редактировать",
		hy: "Խմբագրել",
		tr: "Düzenle",
		ar: "تعديل",
		zh: "编辑",
		fa: "ویرایش",
		he: "ערוך",
		fr: "Modifier",
		es: "Editar",
		pt: "Editar"
	},
	write: {
		en: "Write",
		ru: "Написать",
		hy: "Գրել",
		tr: "Yaz",
		ar: "اكتب",
		zh: "写",
		fa: "بنویس",
		he: "כתוב",
		fr: "Écrire",
		es: "Escribir",
		pt: "Escrever"
	},
	selectSomething: {
		en: "Select something",
		ru: "Выберите что-нибудь",
		hy: "Ընտրեք մի բան",
		tr: "Bir şey seçin",
		ar: "اختر شيئًا",
		zh: "选择一些东西",
		fa: "چیزی انتخاب کنید",
		he: "בחר משהו",
		fr: "Sélectionner quelque chose",
		es: "Selecciona algo",
		pt: "Selecione algo"
	},
	close: {
		en: "Close",
		ru: "Закрыть",
		hy: "Փակել",
		tr: "Kapat",
		ar: "إغلاق",
		zh: "关闭",
		fa: "بستن",
		he: "סגור",
		fr: "Fermer",
		es: "Cerrar",
		pt: "Fechar"
	},
	closeTicket: {
		en: "Close Ticket",
		ru: "Закрыть билет",
		hy: "Փակել տոմսը",
		tr: "Bileti Kapat",
		ar: "إغلاق التذكرة",
		zh: "关闭票",
		fa: "بستن تیکت",
		he: "סגור כרטיס",
		fr: "Fermer le ticket",
		es: "Cerrar ticket",
		pt: "Fechar o ticket"
	},
	index: {
		en: "#",
		ru: "#",
		hy: "#",
		tr: "#",
		ar: "#",
		zh: "#",
		fa: "#",
		he: "#",
		fr: "#",
		es: "#",
		pt: "#"
	},
	developed: {
		en: "Developed",
		ru: "Разработанно",
		hy: "Ստեղծված է",
		tr: "Geliştirilmiş",
		ar: "مطور",
		zh: "开发",
		fa: "توسعه یافته",
		he: "פותח",
		fr: "Développé",
		es: "Desarrollado",
		pt: "Desenvolvido"
	},
	version: {
		en: "Version",
		ru: "Версия",
		hy: "Տարբերակ",
		tr: "Sürüm",
		ar: "الإصدار",
		zh: "版本",
		fa: "نسخه",
		he: "גרסה",
		fr: "Version",
		es: "Versión",
		pt: "Versão"
	},
	mainInformation: {
		en: "Basic information",
		ru: "Основная информация",
		hy: "Հիմնական տեղեկատվություն",
		tr: "Temel bilgi",
		ar: "معلومات أساسية",
		zh: "基本信息",
		fa: "اطلاعات پایه",
		he: "מידע בסיסי",
		fr: "Informations de base",
		es: "Información básica",
		pt: "Informações básicas"
	},
	city: {
		en: "City",
		ru: "Город",
		hy: "Քաղաք",
		tr: "Şehir",
		ar: "مدينة",
		zh: "城市",
		fa: "شهر",
		he: "עיר",
		fr: "Ville",
		es: "Ciudad",
		pt: "Cidade"
	},
	voltage: {
		en: "Voltage",
		ru: "Напряжение",
		hy: "Լարման մակարդակ",
		tr: "Gerilim",
		ar: "جهد",
		zh: "电压",
		fa: "ولتاژ",
		he: "מתח",
		fr: "Tension",
		es: "Voltaje",
		pt: "Voltagem"
	},
	workingMinutes: {
		en: "Working Minutes",
		ru: "Рабочие минуты",
		hy: "Աշխատանքային րոպեները",
		tr: "Çalışma Dakikaları",
		ar: "دقائق العمل",
		zh: "工作分钟",
		fa: "دقایق کاری",
		he: "דקות עבודה",
		fr: "Minutes de travail",
		es: "Minutos de trabajo",
		pt: "Minutos de trabalho"
	},
	processTime: {
		en: "Process Time",
		ru: "Время обработки",
		hy: "Ընթացիկ ժամանակը",
		tr: "İşlem Süresi",
		ar: "وقت المعالجة",
		zh: "处理时间",
		fa: "زمان پردازش",
		he: "זמן תהליך",
		fr: "Temps de traitement",
		es: "Tiempo de proceso",
		pt: "Tempo de processamento"
	},
	stepStatus: {
		en: "Step Status",
		ru: "Статус шага",
		hy: "Քայլի կարգավիճակ",
		tr: "Adım Durumu",
		ar: "حالة الخطوة",
		zh: "步骤状态",
		fa: "وضعیت مرحله",
		he: "מצב שלב",
		fr: "État de l'étape",
		es: "Estado del paso",
		pt: "Status da etapa"
	},
	selectedSteps: {
		en: "Selected Steps",
		ru: "Выбранные шаги",
		hy: "Ընտրված քայլեր",
		tr: "Seçilen Adımlar",
		ar: "الخطوات المختارة",
		zh: "选定的步骤",
		fa: "مراحل انتخاب شده",
		he: "שלבים שנבחרו",
		fr: "Étapes sélectionnées",
		es: "Pasos seleccionados",
		pt: "Etapas selecionadas"
	},
	detailedSteps: {
		en: "Detailed Steps",
		ru: "Детальные шаги",
		hy: "Մանրամասն քայլերը",
		tr: "Ayrıntılı Adımlar",
		ar: "الخطوات التفصيلية",
		zh: "详细步骤",
		fa: "مراحل دقیق",
		he: "שלבים מפורטים",
		fr: "Étapes détaillées",
		es: "Pasos detallados",
		pt: "Passos detalhados"
	},
	inProgress: {
		en: "In Progress",
		ru: "В процессе",
		hy: "Ընթացիկ",
		tr: "Devam Ediyor",
		ar: "قيد التقدم",
		zh: "进行中",
		fa: "در حال انجام",
		he: "בתהליך",
		fr: "En cours",
		es: "En progreso",
		pt: "Em andamento"
	},
	id: {
		en: "ID",
		ru: "ID",
		hy: "ID",
		tr: "ID",
		ar: "معرف",
		zh: "ID",
		fa: "شناسه",
		he: "מזהה",
		fr: "ID",
		es: "ID",
		pt: "ID"
	},
	updatedAt: {
		en: "Updated At",
		ru: "Обновлено",
		hy: "Թարմացված է",
		tr: "Güncellenme Zamanı",
		ar: "تم التحديث في",
		zh: "更新时间",
		fa: "تاریخ بروزرسانی",
		he: "עודכן ב",
		fr: "Mis à jour à",
		es: "Actualizado en",
		pt: "Atualizado em"
	},
	projectDetails: {
		en: "Project Details",
		ru: "Детали проекта",
		hy: "Նախագծի մանրամասներ",
		tr: "Proje Detayları",
		ar: "تفاصيل المشروع",
		zh: "项目详情",
		fa: "جزئیات پروژه",
		he: "פרטי פרויקט",
		fr: "Détails du projet",
		es: "Detalles del proyecto",
		pt: "Detalhes do projeto"
	},
	createdByUser: {
		en: "Created By User",
		ru: "Создано пользователем",
		hy: "Ստեղծված է օգտվողի կողմից",
		tr: "Kullanıcı Tarafından Oluşturuldu",
		ar: "تم الإنشاء بواسطة المستخدم",
		zh: "由用户创建",
		fa: "ایجاد شده توسط کاربر",
		he: "נוצר על ידי משתמש",
		fr: "Créé par l'utilisateur",
		es: "Creado por el usuario",
		pt: "Criado pelo usuário"
	},
	project: {
		en: "Project",
		ru: "Проект",
		hy: "Նախագիծ",
		tr: "Proje",
		ar: "مشروع",
		zh: "项目",
		fa: "پروژه",
		he: "פרויקט",
		fr: "Projet",
		es: "Proyecto",
		pt: "Projeto"
	},
	addFiles: {
		en: "Add Files",
		ru: "Добавить файлы",
		hy: "Ավելացնել ֆայլեր",
		tr: "Dosya Ekle",
		ar: "إضافة ملفات",
		zh: "添加文件",
		fa: "افزودن فایل‌ها",
		he: "הוסף קבצים",
		fr: "Ajouter des fichiers",
		es: "Agregar archivos",
		pt: "Adicionar arquivos"
	},
	projectTypes: {
		en: "Project Types",
		ru: "Типы проектов",
		hy: "Նախագծի տեսակներ",
		tr: "Proje Türleri",
		ar: "أنواع المشاريع",
		zh: "项目类型",
		fa: "انواع پروژه‌ها",
		he: "סוגי פרויקטים",
		fr: "Types de projets",
		es: "Tipos de proyectos",
		pt: "Tipos de projetos"
	},
	projectType: {
		en: "Project Type",
		ru: "Тип проекта",
		hy: "Նախագծի տեսակ",
		tr: "Proje Türü",
		ar: "نوع المشروع",
		zh: "项目类型",
		fa: "نوع پروژه",
		he: "סוג פרויקט",
		fr: "Type de projet",
		es: "Tipo de proyecto",
		pt: "Tipo de projeto"
	},
	low: {
		en: "Low",
		ru: "Низкий",
		hy: "Ցածր",
		tr: "Düşük",
		ar: "منخفض",
		zh: "低",
		fa: "کم",
		he: "נמוך",
		fr: "Bas",
		es: "Bajo",
		pt: "Baixo"
	},
	high: {
		en: "High",
		ru: "Высокий",
		hy: "Բարձր",
		tr: "Yüksek",
		ar: "عالي",
		zh: "高",
		fa: "بالا",
		he: "גבוה",
		fr: "Haut",
		es: "Alto",
		pt: "Alto"
	},
	undefined: {
		en: "Undefined",
		ru: "Не определено",
		hy: "Սահմանված չէ",
		tr: "Tanımsız",
		ar: "غير معرف",
		zh: "未定义",
		fa: "تعریف نشده",
		he: "לא מוגדר",
		fr: "Indéfini",
		es: "Indefinido",
		pt: "Indefinido"
	},
	cancelBack: {
		en: "Cancel back",
		ru: "Отменить обратно",
		hy: "Հետ",
		tr: "Geri İptal",
		ar: "إلغاء العودة",
		zh: "取消返回",
		fa: "لغو بازگشت",
		he: "בטל חזרה",
		fr: "Annuler retour",
		es: "Cancelar vuelta",
		pt: "Cancelar volta"
	},
	removeFromList: {
		en: "Remove from list",
		ru: "Удалить из списка",
		hy: "Ջնջել ցուցակից",
		tr: "Listeden Kaldır",
		ar: "إزالة من القائمة",
		zh: "从列表中移除",
		fa: "حذف از لیست",
		he: "הסר מהרשימה",
		fr: "Retirer de la liste",
		es: "Eliminar de la lista",
		pt: "Remover da lista"
	},
	users: {
		en: "Human Resources",
		ru: "Кадры",
		hy: "Անձնակազմ",
		tr: "İnsan Kaynakları",
		ar: "الموارد البشرية",
		zh: "人力资源",
		fa: "منابع انسانی",
		he: "משאבי אנוש",
		fr: "Ressources humaines",
		es: "Recursos humanos",
		pt: "Recursos humanos"
	},
	workingDays: {
		en: "Working Days",
		ru: "Рабочие дни",
		hy: "Աշխատանքային օրեր",
		tr: "Çalışma Günleri",
		ar: "أيام العمل",
		zh: "工作日",
		fa: "روزهای کاری",
		he: "ימי עבודה",
		fr: "Jours ouvrés",
		es: "Días laborales",
		pt: "Dias úteis"
	},
	visitationDeadline: {
		en: "Visitation Dead Line",
		ru: "Срок рассмотрения",
		hy: "Վերջնաժամկետ տեղազննման",
		tr: "Ziyaret Son Tarihi",
		ar: "الموعد النهائي للزيارة",
		zh: "访问截止日期",
		fa: "مهلت بازدید",
		he: "מועד סיום ביקור",
		fr: "Date limite de visite",
		es: "Fecha límite de visita",
		pt: "Prazo de visita"
	},
	steps: {
		en: "Steps",
		ru: "Шаги",
		hy: "Քայլեր",
		tr: "Adımlar",
		ar: "خطوات",
		zh: "步骤",
		fa: "مراحل",
		he: "שלבים",
		fr: "Étapes",
		es: "Pasos",
		pt: "Passos"
	},
	stepsLeft: {
		en: "Steps Left",
		ru: "Осталось шагов",
		hy: "Քայլերի քանակը",
		tr: "Kalan Adımlar",
		ar: "الخطوات المتبقية",
		zh: "剩余步骤",
		fa: "مراحل باقی‌مانده",
		he: "שלבים נשארים",
		fr: "Étapes restantes",
		es: "Pasos restantes",
		pt: "Passos restantes"
	},
	substation: {
		en: "Substation",
		ru: "Подстанция",
		hy: "Սնման կետ",
		tr: "Trafo Merkezi",
		ar: "محطة فرعية",
		zh: "变电站",
		fa: "ایستگاه فرعی",
		he: "תחנת משנה",
		fr: "Poste électrique",
		es: "Subestación",
		pt: "Subestação"
	},
	deadLine: {
		en: "Dead Line",
		ru: "Срок",
		hy: "Վերջնաժամկետ",
		tr: "Son Tarih",
		ar: "الموعد النهائي",
		zh: "截止日期",
		fa: "مهلت",
		he: "מועד סיום",
		fr: "Date limite",
		es: "Fecha límite",
		pt: "Prazo final"
	},
	history: {
		en: "History",
		ru: "История",
		hy: "Պատմություն",
		tr: "Geçmiş",
		ar: "التاريخ",
		zh: "历史",
		fa: "تاریخچه",
		he: "היסטוריה",
		fr: "Histoire",
		es: "Historia",
		pt: "Histórico"
	},
	edited: {
		en: "Edited",
		ru: "Редактировано",
		hy: "Խմբագրված է",
		tr: "Düzenlendi",
		ar: "تم التعديل",
		zh: "已编辑",
		fa: "ویرایش شده",
		he: "נערך",
		fr: "Modifié",
		es: "Editado",
		pt: "Editado"
	},
	added: {
		en: "Added",
		ru: "Добавлено",
		hy: "Ավելացված է",
		tr: "Eklendi",
		ar: "تم الإضافة",
		zh: "已添加",
		fa: "اضافه شد",
		he: "הוסף",
		fr: "Ajouté",
		es: "Añadido",
		pt: "Adicionado"
	},
	technicalFilesUrl: {
		en: "Technical Files Url",
		ru: "URL Технические файлы",
		hy: "Տեխնիկական ֆայլերի Url",
		tr: "Teknik Dosyalar URL",
		ar: "رابط الملفات التقنية",
		zh: "技术文件网址",
		fa: "آدرس فایل‌های فنی",
		he: "כתובת קבצים טכניים",
		fr: "URL des fichiers techniques",
		es: "URL de archivos técnicos",
		pt: "URL dos arquivos técnicos"
	},
	end: {
		en: "End",
		ru: "Конец",
		hy: "Ավարտ",
		tr: "Son",
		ar: "نهاية",
		zh: "结束",
		fa: "پایان",
		he: "סיום",
		fr: "Fin",
		es: "Fin",
		pt: "Fim"
	},
	openDate: {
		en: "Date opened",
		ru: "Дата открытия",
		hy: "Ստեղծման ամսաթիվը",
		tr: "Açılış Tarihi",
		ar: "تاريخ الافتتاح",
		zh: "打开日期",
		fa: "تاریخ باز شدن",
		he: "תאריך פתיחה",
		fr: "Date d'ouverture",
		es: "Fecha de apertura",
		pt: "Data de abertura"
	},
	selectOpenedDate: {
		en: "Select opened date",
		ru: "Выберите дату открытия",
		hy: "Ընտրեք ստեղծման ամսաթիվը",
		tr: "Açılış tarihini seçin",
		ar: "حدد تاريخ الافتتاح",
		zh: "选择打开日期",
		fa: "تاریخ باز شدن را انتخاب کنید",
		he: "בחר תאריך פתיחה",
		fr: "Sélectionner la date d'ouverture",
		es: "Seleccione la fecha de apertura",
		pt: "Selecione a data de abertura"
	},
	next: {
		en: "Next",
		ru: "Следующий",
		hy: "Հաջորդ",
		tr: "Sonraki",
		ar: "التالي",
		zh: "下一个",
		fa: "بعدی",
		he: "הבא",
		fr: "Suivant",
		es: "Siguiente",
		pt: "Próximo"
	},
	substationNumber: {
		en: "Substation number",
		ru: "Номер подстанции",
		hy: "Սնման կետի համարը",
		tr: "Trafo Merkezi Numarası",
		ar: "رقم المحطة الفرعية",
		zh: "变电站编号",
		fa: "شماره ایستگاه فرعی",
		he: "מספר תחנת משנה",
		fr: "Numéro de sous-station",
		es: "Número de subestación",
		pt: "Número da subestação"
	},
	voltageLevel: {
		en: "Voltage level",
		ru: "Уровень напряжения",
		hy: "Լարման մակարդակ",
		tr: "Gerilim Seviyesi",
		ar: "مستوى الجهد",
		zh: "电压等级",
		fa: "سطح ولتاژ",
		he: "רמת מתח",
		fr: "Niveau de tension",
		es: "Nivel de voltaje",
		pt: "Nível de voltagem"
	},
	projectPartition: {
		en: "Project Partition",
		ru: "Раздел проекта",
		hy: "Նախագծի մաս",
		tr: "Proje Bölümü",
		ar: "تقسيم المشروع",
		zh: "项目分区",
		fa: "بخش پروژه",
		he: "חלק מפרויקט",
		fr: "Partition du projet",
		es: "Partición del proyecto",
		pt: "Partição do projeto"
	},
	bypassSteps: {
		en: "Bypass Steps",
		ru: "Обход шагов",
		hy: "Քայլերի բաց թողնում",
		tr: "Adımları Atla",
		ar: "تجاوز الخطوات",
		zh: "绕过步骤",
		fa: "مرحله‌های کنار گذاشتن",
		he: "דילוג על שלבים",
		fr: "Passer les étapes",
		es: "Omitir pasos",
		pt: "Pular etapas"
	},
	abbreviation: {
		en: "Abbreviation",
		ru: "Аббревиатура",
		hy: "Աբրեվիատուրա",
		tr: "Kısaltma",
		ar: "اختصار",
		zh: "缩写",
		fa: "اختصار",
		he: "קיצור",
		fr: "Abréviation",
		es: "Abreviatura",
		pt: "Abreviação"
	},
	orderNumber: {
		en: "Order number",
		ru: "Номер заказа",
		hy: "Պատվերի համար",
		tr: "Sipariş Numarası",
		ar: "رقم الطلب",
		zh: "订单号",
		fa: "شماره سفارش",
		he: "מספר הזמנה",
		fr: "Numéro de commande",
		es: "Número de orden",
		pt: "Número do pedido"
	},
	propertyNumber: {
		en: "Property number",
		ru: "Номер свойства",
		hy: "Գույքահամար",
		tr: "Mülk Numarası",
		ar: "رقم الملكية",
		zh: "财产编号",
		fa: "شماره ملکیت",
		he: "מספר נכס",
		fr: "Numéro de propriété",
		es: "Número de propiedad",
		pt: "Número do imóvel"
	},
	branch: {
		en: "Branch",
		ru: "Филиал электронной сети",
		hy: "էլեկտրական ցանցի մասնաճյուղ",
		tr: "Elektrik Şebekesi Şubesi",
		ar: "فرع الشبكة الكهربائية",
		zh: "电网分支",
		fa: "شعبه شبکه برق",
		he: "סניף רשת חשמל",
		fr: "Branche du réseau électrique",
		es: "Sucursal de red eléctrica",
		pt: "Filial da rede elétrica"
	},
	writingNumber: {
		en: "Writing number",
		ru: "Написание номера",
		hy: "Գրության համար",
		tr: "Yazı Numarası",
		ar: "كتابة الرقم",
		zh: "写作编号",
		fa: "شماره نوشتن",
		he: "מספר כתיבה",
		fr: "Numéro d'écriture",
		es: "Número de escritura",
		pt: "Número de redação"
	},
	writingContent: {
		en: "Writing content",
		ru: "Написание контента",
		hy: "Գրության բովանդակություն",
		tr: "Yazı İçeriği",
		ar: "محتوى الكتابة",
		zh: "写作内容",
		fa: "محتوای نوشتن",
		he: "תוכן הכתיבה",
		fr: "Contenu de l'écriture",
		es: "Contenido de la escritura",
		pt: "Conteúdo da redação"
	},
	noNeedStep45: {
		en: "No Need Step 4-5",
		ru: "Не нужно шаг 4-5",
		hy: "Չի պահանջվում 4-5 քայլերը",
		tr: "Adım 4-5 Gerekmiyor",
		ar: "لا حاجة للخطوة 4-5",
		zh: "不需要步骤4-5",
		fa: "نیازی به مرحله 4-5 نیست",
		he: "לא צריך שלב 4-5",
		fr: "Pas besoin de l'étape 4-5",
		es: "No se necesitan los pasos 4-5",
		pt: "Não é necessário o Passo 4-5"
	},
	priority: {
		en: "Priority",
		ru: "Приоритет",
		hy: "Առաջնահերթություն",
		tr: "Öncelik",
		ar: "أولوية",
		zh: "优先级",
		fa: "اولویت",
		he: "עדיפות",
		fr: "Priorité",
		es: "Prioridad",
		pt: "Prioridade"
	},
	importance: {
		en: "Importance",
		ru: "Важность",
		hy: "Կարևորություն",
		tr: "Önem",
		ar: "أهمية",
		zh: "重要性",
		fa: "اهمیت",
		he: "חשיבות",
		fr: "Importance",
		es: "Importancia",
		pt: "Importância"
	},
	actSendingDate: {
		en: "Act sending date",
		ru: "Дата отправки «Акт»",
		hy: "Ակտի հանձնման ամսաթիվ",
		tr: "Akt Gönderme Tarihi",
		ar: 'تاريخ إرسال "العمل"',
		zh: "操作发送日期",
		fa: 'تاریخ ارسال "عمل"',
		he: "תאריך שליחת הפעולה",
		fr: "Date d'envoi de l'acte",
		es: "Fecha de envío del acto",
		pt: "Data de envio do ato"
	},
	projectEndDate: {
		en: "Project End Date",
		ru: "Дата окончания проекта",
		hy: "Նախագծի ավարտի ամսաթիվ",
		tr: "Proje Bitiş Tarihi",
		ar: "تاريخ نهاية المشروع",
		zh: "项目结束日期",
		fa: "تاریخ پایان پروژه",
		he: "תאריך סיום הפרויקט",
		fr: "Date de fin de projet",
		es: "Fecha de finalización del proyecto",
		pt: "Data de término do projeto"
	},
	projectStartDate: {
		en: "Project Start Date",
		ru: "Дата начала проекта",
		hy: "Նախագծի սկիզբի ամսաթիվ",
		tr: "Proje Başlangıç Tarihi",
		ar: "تاريخ بدء المشروع",
		zh: "项目开始日期",
		fa: "تاریخ شروع پروژه",
		he: "תאריך תחילת הפרויקט",
		fr: "Date de début de projet",
		es: "Fecha de inicio del proyecto",
		pt: "Data de início do projeto"
	},
	saved: {
		en: "Saved",
		ru: "Сохранено",
		hy: "Պահպանվել է",
		tr: "Kaydedildi",
		ar: "تم الحفظ",
		zh: "已保存",
		fa: "ذخیره شد",
		he: "נשמר",
		fr: "Sauvegardé",
		es: "Guardado",
		pt: "Salvo"
	},
	projectDeadline: {
		en: "Project Deadline",
		ru: "Срок проекта",
		hy: "Նախագծի վերջնաժամկետ",
		tr: "Proje Teslim Tarihi",
		ar: "الموعد النهائي للمشروع",
		zh: "项目截止日期",
		fa: "مهلت پروژه",
		he: "מועד סיום פרויקט",
		fr: "Date limite du projet",
		es: "Fecha límite del proyecto",
		pt: "Prazo do projeto"
	},
	yes: {
		en: "Yes",
		ru: "Да",
		hy: "Այո",
		tr: "Evet",
		ar: "نعم",
		zh: "是",
		fa: "بله",
		he: "כן",
		fr: "Oui",
		es: "Sí",
		pt: "Sim"
	},
	removed: {
		en: "Removed",
		ru: "Удалено",
		hy: "Ջնջելվել է",
		tr: "Kaldırıldı",
		ar: "تم الإزالة",
		zh: "已移除",
		fa: "حذف شد",
		he: "הוסרה",
		fr: "Supprimé",
		es: "Eliminado",
		pt: "Removido"
	},
	areYouSure: {
		en: "Are you sure?",
		ru: "Вы уверены ?",
		hy: "Դուք համոզված եք ?",
		tr: "Emin misiniz?",
		ar: "هل أنت متأكد؟",
		zh: "你确定吗？",
		fa: "آیا مطمئن هستید؟",
		he: "אתה בטוח?",
		fr: "Êtes-vous sûr ?",
		es: "¿Estás seguro?",
		pt: "Tem certeza?"
	},
	accept: {
		en: "Accept",
		ru: "Принять",
		hy: "Ընդունել",
		tr: "Kabul Et",
		ar: "قبول",
		zh: "接受",
		fa: "پذیرفتن",
		he: "קבל",
		fr: "Accepter",
		es: "Aceptar",
		pt: "Aceitar"
	},
	decline: {
		en: "Decline",
		ru: "Отклонить",
		hy: "Մերժել",
		tr: "Reddet",
		ar: "رفض",
		zh: "拒绝",
		fa: "رد کردن",
		he: "דחה",
		fr: "Refuser",
		es: "Rechazar",
		pt: "Recusar"
	},
	download: {
		en: "Download",
		ru: "Скачать",
		hy: "Ներբեռնել",
		tr: "İndir",
		ar: "تحميل",
		zh: "下载",
		fa: "دانلود",
		he: "הורד",
		fr: "Télécharger",
		es: "Descargar",
		pt: "Baixar"
	},
	upload: {
		en: "Upload",
		ru: "Загрузить",
		hy: "Վերբեռնել",
		tr: "Yükle",
		ar: "رفع",
		zh: "上传",
		fa: "آپلود",
		he: "העלה",
		fr: "Téléverser",
		es: "Subir",
		pt: "Enviar"
	},
	pending: {
		en: "Pending",
		ru: "Ожидает",
		hy: "Մնացած է",
		tr: "Bekliyor",
		ar: "معلق",
		zh: "待处理",
		fa: "معلق",
		he: "ממתין",
		fr: "En attente",
		es: "Pendiente",
		pt: "Pendente"
	},
	active: {
		en: "Active",
		ru: "Активный",
		hy: "Սկսված",
		tr: "Aktif",
		ar: "نشط",
		zh: "活跃",
		fa: "فعال",
		he: "פעיל",
		fr: "Actif",
		es: "Activo",
		pt: "Ativo"
	},
	inactive: {
		en: "Inactive",
		ru: "Неактивный",
		hy: "Անգործուն",
		tr: "Pasif",
		ar: "غير نشط",
		zh: "不活跃",
		fa: "غیرفعال",
		he: "לא פעיל",
		fr: "Inactif",
		es: "Inactivo",
		pt: "Inativo"
	},
	viewDetails: {
		en: "View Details",
		ru: "Просмотреть детали",
		hy: "Տեսնել մանրամասները",
		tr: "Detayları Görüntüle",
		ar: "عرض التفاصيل",
		zh: "查看详情",
		fa: "مشاهده جزئیات",
		he: "צפה בפרטים",
		fr: "Voir les détails",
		es: "Ver detalles",
		pt: "Ver detalhes"
	},
	help: {
		en: "Help",
		ru: "Помощь",
		hy: "Օգնություն",
		tr: "Yardım",
		ar: "مساعدة",
		zh: "帮助",
		fa: "کمک",
		he: "עזרה",
		fr: "Aide",
		es: "Ayuda",
		pt: "Ajuda"
	},
	support: {
		en: "Support",
		ru: "Поддержка",
		hy: "Հաջողություն",
		tr: "Destek",
		ar: "دعم",
		zh: "支持",
		fa: "پشتیبانی",
		he: "תמיכה",
		fr: "Support",
		es: "Soporte",
		pt: "Suporte"
	},
	language: {
		en: "Language",
		ru: "Язык",
		hy: "Լեզու",
		tr: "Dil",
		ar: "اللغة",
		zh: "语言",
		fa: "زبان",
		he: "שפה",
		fr: "Langue",
		es: "Idioma",
		pt: "Idioma"
	},
	logout: {
		en: "Logout",
		ru: "Выход",
		hy: "Դուրս գալ",
		tr: "Çıkış",
		ar: "تسجيل الخروج",
		zh: "登出",
		fa: "خروج",
		he: "התנתק",
		fr: "Se déconnecter",
		es: "Cerrar sesión",
		pt: "Sair"
	},
	signIn: {
		en: "Sign In",
		ru: "Войти",
		hy: "Մուտք գործել",
		tr: "Giriş Yap",
		ar: "تسجيل الدخول",
		zh: "登录",
		fa: "ورود",
		he: "התחבר",
		fr: "Se connecter",
		es: "Iniciar sesión",
		pt: "Entrar"
	},
	signUp: {
		en: "Sign Up",
		ru: "Зарегистрироваться",
		hy: "Գրանցվել",
		tr: "Kayıt Ol",
		ar: "إنشاء حساب",
		zh: "注册",
		fa: "ثبت نام",
		he: "הרשם",
		fr: "S'inscrire",
		es: "Registrarse",
		pt: "Cadastrar"
	},
	forgotPassword: {
		en: "Forgot Password?",
		ru: "Забыли пароль?",
		hy: "Մոռացա գաղտնաբառը",
		tr: "Şifrenizi mi unuttunuz?",
		ar: "هل نسيت كلمة المرور؟",
		zh: "忘记密码?",
		fa: "فراموش کرده‌اید رمز عبور را؟",
		he: "שכחת סיסמה?",
		fr: "Mot de passe oublié ?",
		es: "¿Olvidaste tu contraseña?",
		pt: "Esqueceu sua senha?"
	},
	youCannotRevertThisAction: {
		en: "You cannot revert this action!",
		ru: "Вы не можете вернуть это действие!",
		hy: "Դուք չեք կարող հետ բերել այս գործողությունը հետագայում!"
	},
	confirm: {
		en: "Confirm",
		ru: "Подтвердить",
		hy: "Հաստատել",
		tr: "Onayla",
		ar: "تأكيد",
		zh: "确认",
		fa: "تأیید",
		he: "אשר",
		fr: "Confirmer",
		es: "Confirmar",
		pt: "Confirmar"
	},
	cancelAction: {
		en: "Cancel Action",
		ru: "Отменить действие",
		hy: "Չեղարկել գործողությունը",
		tr: "Eylemi İptal Et",
		ar: "إلغاء الإجراء",
		zh: "取消操作",
		fa: "لغو اقدام",
		he: "ביטול פעולה",
		fr: "Annuler l'action",
		es: "Cancelar acción",
		pt: "Cancelar ação"
	},
	search: {
		en: "Search",
		ru: "Поиск",
		hy: "Որոնել",
		tr: "Ara",
		ar: "بحث",
		zh: "搜索",
		fa: "جستجو",
		he: "חפש",
		fr: "Rechercher",
		es: "Buscar",
		pt: "Pesquisar"
	},
	clear: {
		en: "Clear",
		ru: "Очистить",
		hy: "Մաքրել",
		tr: "Temizle",
		ar: "مسح",
		zh: "清除",
		fa: "پاک کردن",
		he: "נקה",
		fr: "Effacer",
		es: "Borrar",
		pt: "Limpar"
	},
	submit: {
		en: "Submit",
		ru: "Отправить",
		hy: "Ուղարկել",
		tr: "Gönder",
		ar: "إرسال",
		zh: "提交",
		fa: "ارسال",
		he: "שלח",
		fr: "Soumettre",
		es: "Enviar",
		pt: "Enviar"
	},
	viewMore: {
		en: "View More",
		ru: "Посмотреть больше",
		hy: "Տեսնել ավելին",
		tr: "Daha Fazla Gör",
		ar: "عرض المزيد",
		zh: "查看更多",
		fa: "مشاهده بیشتر",
		he: "הצג עוד",
		fr: "Voir plus",
		es: "Ver más",
		pt: "Ver mais"
	},
	total: {
		en: "Total",
		ru: "Итого",
		hy: "Ընդամենը",
		tr: "Toplam",
		ar: "الإجمالي",
		zh: "总计",
		fa: "مجموع",
		he: "סך הכל",
		fr: "Total",
		es: "Total",
		pt: "Total"
	},
	from: {
		en: "From",
		ru: "От",
		hy: "Աπό",
		tr: "Den",
		ar: "من",
		zh: "从",
		fa: "از",
		he: "מ",
		fr: "De",
		es: "De",
		pt: "De"
	},
	to: {
		en: "To",
		ru: "До",
		hy: "Մինչ",
		tr: "e",
		ar: "إلى",
		zh: "到",
		fa: "تا",
		he: "אל",
		fr: "À",
		es: "A",
		pt: "Para"
	},
	agreementAquipment: {
		hy: "Շահագրգիռների համաձայնեցում",
		en: "Agreement on equipment",
		ru: "Согласование оборудования",
		tr: "Ekipman üzerinde anlaşma",
		ar: "اتفاق على المعدات",
		zh: "设备协议",
		fa: "توافق بر روی تجهیزات",
		he: "הסכם על ציוד",
		fr: "Accord sur l'équipement",
		es: "Acuerdo sobre equipos",
		pt: "Acordo sobre equipamentos"
	},
	date: {
		en: "Date",
		ru: "Дата",
		hy: "Ամսաթիվ",
		tr: "Tarih",
		ar: "تاريخ",
		zh: "日期",
		fa: "تاریخ",
		he: "תאריך",
		fr: "Date",
		es: "Fecha",
		pt: "Data"
	},
	statusUpdate: {
		en: "Status Update",
		ru: "Обновление статуса",
		hy: "Ստատուսի թարմացում",
		tr: "Durum Güncelleme",
		ar: "تحديث الحالة",
		zh: "状态更新",
		fa: "بروزرسانی وضعیت",
		he: "עדכון סטטוס",
		fr: "Mise à jour du statut",
		es: "Actualización de estado",
		pt: "Atualização de status"
	},
	removeItem: {
		en: "Remove Item",
		ru: "Удалить элемент",
		hy: "Հեռացնել տարրը",
		tr: "Öğeyi Kaldır",
		ar: "إزالة العنصر",
		zh: "移除项",
		fa: "حذف مورد",
		he: "הסר פריט",
		fr: "Supprimer l'élément",
		es: "Eliminar elemento",
		pt: "Remover item"
	},
	view: {
		en: "View",
		ru: "Просмотр",
		hy: "Տեսնել",
		tr: "Görünüm",
		ar: "عرض",
		zh: "查看",
		fa: "مشاهده",
		he: "צפה",
		fr: "Voir",
		es: "Ver",
		pt: "Ver"
	},
	editItem: {
		en: "Edit Item",
		ru: "Редактировать элемент",
		hy: "Խմբագրել տարրը",
		tr: "Öğeyi Düzenle",
		ar: "تعديل العنصر",
		zh: "编辑项",
		fa: "ویرایش مورد",
		he: "ערוך פריט",
		fr: "Modifier l'élément",
		es: "Editar elemento",
		pt: "Editar item"
	},
	confirmAction: {
		en: "Confirm Action",
		ru: "Подтвердить действие",
		hy: "Հաստատել գործողությունը",
		tr: "Eylemi Onayla",
		ar: "تأكيد الإجراء",
		zh: "确认操作",
		fa: "تأیید اقدام",
		he: "אשר פעולה",
		fr: "Confirmer l'action",
		es: "Confirmar acción",
		pt: "Confirmar ação"
	},
	warning: {
		en: "Warning",
		ru: "Предупреждение",
		hy: "Հ предупреждение",
		tr: "Uyarı",
		ar: "تحذير",
		zh: "警告",
		fa: "هشدار",
		he: "אזהרה",
		fr: "Avertissement",
		es: "Advertencia",
		pt: "Aviso"
	},
	success: {
		en: "Success",
		ru: "Успех",
		hy: "Հաջողություն",
		tr: "Başarı",
		ar: "نجاح",
		zh: "成功",
		fa: "موفقیت",
		he: "הצלחה",
		fr: "Succès",
		es: "Éxito",
		pt: "Sucesso"
	},
	errorOccurred: {
		en: "An error occurred",
		ru: "Произошла ошибка",
		hy: "Պատահեց սխալ",
		tr: "Bir hata oluştu",
		ar: "حدث خطأ",
		zh: "发生错误",
		fa: "خطایی رخ داده است",
		he: "התרחשה שגיאה",
		fr: "Une erreur s'est produite",
		es: "Ocurrió un error",
		pt: "Ocorreu um erro"
	},
	noResultsFound: {
		en: "No results found",
		ru: "Результатов не найдено",
		hy: "Չկա արդյունքներ",
		tr: "Sonuç bulunamadı",
		ar: "لم يتم العثور على نتائج",
		zh: "未找到结果",
		fa: "نتایجی یافت نشد",
		he: "לא נמצאו תוצאות",
		fr: "Aucun résultat trouvé",
		es: "No se encontraron resultados",
		pt: "Nenhum resultado encontrado"
	},
	tryAgain: {
		en: "Try Again",
		ru: "Попробовать снова",
		hy: "Փորձել նորից",
		tr: "Tekrar deneyin",
		ar: "حاول مرة أخرى",
		zh: "再试一次",
		fa: "دوباره تلاش کنید",
		he: "נסה שוב",
		fr: "Réessayer",
		es: "Intenta de nuevo",
		pt: "Tente novamente"
	},
	back: {
		en: "Back",
		ru: "Назад",
		hy: "Հետ",
		tr: "Geri",
		ar: "عودة",
		zh: "返回",
		fa: "بازگشت",
		he: "חזור",
		fr: "Retour",
		es: "Volver",
		pt: "Voltar"
	},
	previous: {
		en: "Previous",
		ru: "Предыдущий",
		hy: "Նախորդ",
		tr: "Önceki",
		ar: "السابق",
		zh: "上一个",
		fa: "قبلی",
		he: "קודם",
		fr: "Précédent",
		es: "Anterior",
		pt: "Anterior"
	},
	finish: {
		en: "Finish",
		ru: "Закончить",
		hy: "Ավարտել",
		tr: "Bitir",
		ar: "إنهاء",
		zh: "完成",
		fa: "پایان",
		he: "סיים",
		fr: "Terminer",
		es: "Terminar",
		pt: "Concluir"
	},
	noData: {
		en: "No data available",
		ru: "Данные отсутствуют",
		hy: "Չկա տվյալներ",
		tr: "Veri mevcut değil",
		ar: "لا توجد بيانات",
		zh: "没有数据",
		fa: "هیچ داده‌ای موجود نیست",
		he: "אין נתונים",
		fr: "Aucune donnée disponible",
		es: "No hay datos disponibles",
		pt: "Nenhum dado disponível"
	},
	errorMessage: {
		en: "An error occurred, please try again.",
		ru: "Произошла ошибка, пожалуйста, попробуйте снова.",
		hy: "Պատահեց սխալ, խնդրում ենք փորձել նորից:",
		tr: "Bir hata oluştu, lütfen tekrar deneyin.",
		ar: "حدث خطأ، يرجى المحاولة مرة أخرى.",
		zh: "发生错误，请重试。",
		fa: "خطایی رخ داده است، لطفا دوباره تلاش کنید.",
		he: "התרחשה שגיאה, אנא נסה שוב.",
		fr: "Une erreur s'est produite, veuillez réessayer.",
		es: "Ocurrió un error, por favor intente nuevamente.",
		pt: "Ocorreu um erro, por favor tente novamente."
	},
	pcs: {
		en: "PCs",
		ru: "ПК",
		hy: "Համակարգիչներ",
		tr: "Bilgisayarlar",
		ar: "أجهزة الكمبيوتر",
		zh: "电脑",
		fa: "کامپیوترها",
		he: "מחשבים",
		fr: "PC",
		es: "PCs",
		pt: "PCs"
	},
	moreDetailed: {
		en: "More detailed",
		ru: "Подробнее",
		hy: "Մանրամասն",
		tr: "Daha ayrıntılı",
		ar: "أكثر تفصيلاً",
		zh: "更详细",
		fa: "بیشتر توضیح داده شده",
		he: "יותר מפורט",
		fr: "Plus détaillé",
		es: "Más detallado",
		pt: "Mais detalhado"
	},
	relatedList: {
		en: "Related List",
		ru: "Связанный список",
		hy: "Համապատասխան ցուցակը",
		tr: "İlgili Liste",
		ar: "القائمة ذات الصلة",
		zh: "相关列表",
		fa: "فهرست مرتبط",
		he: "רשימה קשורה",
		fr: "Liste associée",
		es: "Lista relacionada",
		pt: "Lista relacionada"
	},
	duration: {
		en: "Duration",
		ru: "Продолжительность",
		hy: "Տևողություն",
		tr: "Süre",
		ar: "المدة",
		zh: "持续时间",
		fa: "مدت زمان",
		he: "משך זמן",
		fr: "Durée",
		es: "Duración",
		pt: "Duração"
	},
	problemDescription: {
		en: "Problem Description",
		ru: "Описание проблемы",
		hy: "Խնդիրի նկարագիր",
		tr: "Sorun Açıklaması",
		ar: "وصف المشكلة",
		zh: "问题描述",
		fa: "توضیحات مشکل",
		he: "תיאור הבעיה",
		fr: "Description du problème",
		es: "Descripción del problema",
		pt: "Descrição do problema"
	},
	parent: {
		en: "Parent",
		ru: "Родитель",
		hy: "Մայր",
		tr: "Ebeveyn",
		ar: "الأب",
		zh: "父母",
		fa: "والدین",
		he: "הורה",
		fr: "Parent",
		es: "Padre",
		pt: "Pai"
	},
	routers: {
		en: "Routers",
		ru: "Маршрутизаторы",
		hy: "Հեռահաղորդակցություն",
		tr: "Yönlendiriciler",
		ar: "أجهزة التوجيه",
		zh: "路由器",
		fa: "روترها",
		he: "ראוטרים",
		fr: "Routeurs",
		es: "Enrutadores",
		pt: "Roteadores"
	},
	monitors: {
		en: "Monitors",
		ru: "Мониторы",
		hy: "Մոնիտորներ",
		tr: "Monitörler",
		ar: "شاشات",
		zh: "显示器",
		fa: "مانیتورها",
		he: "מוניטורים",
		fr: "Moniteurs",
		es: "Monitores",
		pt: "Monitores"
	},
	keyboards: {
		en: "Keyboards",
		ru: "Клавиатуры",
		hy: "Ստեղնաշարներ",
		tr: "Klavye",
		ar: "لوحات المفاتيح",
		zh: "键盘",
		fa: "صفحه‌کلید",
		he: "מקלדות",
		fr: "Claviers",
		es: "Teclados",
		pt: "Teclados"
	},
	mouses: {
		en: "Mouses",
		ru: "Мышки",
		hy: "Մկնրկներ",
		tr: "Fareler",
		ar: "فأرات",
		zh: "鼠标",
		fa: "ماوس‌ها",
		he: "עכברים",
		fr: "Souris",
		es: "Ratones",
		pt: "Ratos"
	},
	switches: {
		en: "Printers",
		ru: "Принтеры",
		hy: "Տպիչներ",
		tr: "Yazıcılar",
		ar: "طابعات",
		zh: "打印机",
		fa: "پرینترها",
		he: "מדפסות",
		fr: "Imprimantes",
		es: "Impresoras",
		pt: "Impressoras"
	},
	smb: {
		en: "Server folder",
		ru: "Папки сервера",
		hy: "Սերվերի պանակ",
		tr: "Sunucu klasörü",
		ar: "مجلد الخادم",
		zh: "服务器文件夹",
		fa: "پوشه سرور",
		he: "תיקיית השרת",
		fr: "Dossier du serveur",
		es: "Carpeta del servidor",
		pt: "Pasta do servidor"
	},
	printers: {
		en: "Printers",
		ru: "Принтеры",
		hy: "Տպիչներ",
		tr: "Yazıcılar",
		ar: "طابعات",
		zh: "打印机",
		fa: "پرینترها",
		he: "מדפסות",
		fr: "Imprimantes",
		es: "Impresoras",
		pt: "Impressoras"
	},
	switchers: {
		en: "Switchers",
		ru: "Коммутаторы",
		hy: "Անջատիչներ",
		tr: "Anahtarlayıcılar",
		ar: "مبدلات",
		zh: "切换器",
		fa: "سوئیچ‌ها",
		he: "מתגים",
		fr: "Commutateurs",
		es: "Conmutadores",
		pt: "Comutadores"
	},
	openedDate: {
		en: "Open Date",
		ru: "Дата открытия",
		hy: "Բացման ամսաթիվը",
		tr: "Açılış Tarihi",
		ar: "تاريخ الفتح",
		zh: "开放日期",
		fa: "تاریخ باز شدن",
		he: "תאריך פתיחה",
		fr: "Date d'ouverture",
		es: "Fecha de apertura",
		pt: "Data de Abertura"
	},
	problem: {
		en: "Problem",
		ru: "Проблема",
		hy: "Խնդիր",
		tr: "Sorun",
		ar: "مشكلة",
		zh: "问题",
		fa: "مشکل",
		he: "בעיה",
		fr: "Problème",
		es: "Problema",
		pt: "Problema"
	},
	toChat: {
		en: "To chat",
		ru: "В чат",
		hy: "Դեպի չատ",
		tr: "Sohbete git",
		ar: "إلى الدردشة",
		zh: "去聊天",
		fa: "به چت",
		he: "לצ'אט",
		fr: "Aller au chat",
		es: "Ir al chat",
		pt: "Ir para o chat"
	},
	room: {
		en: "Room",
		ru: "Комната",
		hy: "Սենյակ",
		tr: "Oda",
		ar: "غرفة",
		zh: "房间",
		fa: "اتاق",
		he: "חדר",
		fr: "Chambre",
		es: "Sala",
		pt: "Sala"
	},
	permission: {
		en: "Permission",
		ru: "Разрешение",
		hy: "Թույլատրություն",
		tr: "İzin",
		ar: "إذن",
		zh: "许可",
		fa: "مجوز",
		he: "הרשאה",
		fr: "Permission",
		es: "Permiso",
		pt: "Permissão"
	},
	monitor: {
		en: "Monitor",
		ru: "Монитор",
		hy: "Մոնիտոր",
		tr: "Monitör",
		ar: "شاشة",
		zh: "显示器",
		fa: "مانیتور",
		he: "מסך",
		fr: "Moniteur",
		es: "Monitor",
		pt: "Monitor"
	},
	switch: {
		en: "Switch",
		ru: "Коммутатор",
		hy: "Անջատիչ",
		tr: "Anahtar",
		ar: "مفتاح",
		zh: "开关",
		fa: "سوئیچ",
		he: "מתג",
		fr: "Commutateur",
		es: "Interruptor",
		pt: "Interruptor"
	},
	ticket: {
		en: "Ticket",
		ru: "Билет",
		hy: "Տոմս",
		tr: "Bilet",
		ar: "تذكرة",
		zh: "票",
		fa: "بلیت",
		he: "כרטיס",
		fr: "Ticket",
		es: "Boleto",
		pt: "Bilhete"
	},
	"office-map": {
		en: "Office Map",
		ru: "Карта офиса",
		hy: "Օֆիսի քարտեզ",
		tr: "Ofis Haritası",
		ar: "خريطة المكتب",
		zh: "办公室地图",
		fa: "نقشه دفتر",
		he: "מפת משרד",
		fr: "Carte du bureau",
		es: "Mapa de la oficina",
		pt: "Mapa do escritório"
	},
	commentTicket: {
		en: "Comment Ticket",
		ru: "Комментарий к билету",
		hy: "Մեկնաբանություն տոմսի մասին",
		tr: "Bilet Yorumları",
		ar: "تعليق التذكرة",
		zh: "评论票",
		fa: "نظر بلیت",
		he: "הערת כרטיס",
		fr: "Commentaire de ticket",
		es: "Comentario de ticket",
		pt: "Comentário de ticket"
	},
	comment: {
		en: "Comment",
		ru: "Комментарий",
		hy: "Մեկնաբանություն",
		tr: "Yorum",
		ar: "تعليق",
		zh: "评论",
		fa: "نظر",
		he: "הערה",
		fr: "Commentaire",
		es: "Comentario",
		pt: "Comentário"
	},
	humanResources: {
		en: "Human Resources",
		ru: "Кадры",
		hy: "Անձնակազմ",
		tr: "İnsan Kaynakları",
		ar: "الموارد البشرية",
		zh: "人力资源",
		fa: "منابع انسانی",
		he: "משאבי אנוש",
		fr: "Ressources humaines",
		es: "Recursos humanos",
		pt: "Recursos Humanos"
	},
	database: {
		en: "Database",
		ru: "База данных",
		hy: "Տվյալների բազա",
		tr: "Veritabanı",
		ar: "قاعدة البيانات",
		zh: "数据库",
		fa: "پایگاه داده",
		he: "בסיס נתונים",
		fr: "Base de données",
		es: "Base de datos",
		pt: "Banco de dados"
	},
	IT: {
		en: "IT",
		ru: "ИТ",
		hy: "ՏՏ",
		tr: "BT",
		ar: "تكنولوجيا المعلومات",
		zh: "信息技术",
		fa: "فناوری اطلاعات",
		he: "הנדסת מחשבים",
		fr: "TI",
		es: "TI",
		pt: "TI"
	},
	userProfile: {
		en: "User Profile",
		ru: "Профиль пользователя",
		hy: "Օգտատերի պրոֆիլ",
		tr: "Kullanıcı Profili",
		ar: "ملف المستخدم",
		zh: "用户资料",
		fa: "پروفایل کاربر",
		he: "פרופיל משתמש",
		fr: "Profil utilisateur",
		es: "Perfil de usuario",
		pt: "Perfil de usuário"
	},
	downloadAsContact: {
		en: "Download as contact",
		ru: "Скачать как контакт",
		hy: "Ներբեռնել որպես կոնտակտ",
		tr: "Kişi olarak indir",
		ar: "تنزيل كجهة اتصال",
		zh: "下载为联系人",
		fa: "دانلود به عنوان تماس",
		he: "הורד כאיש קשר",
		fr: "Télécharger comme contact",
		es: "Descargar como contacto",
		pt: "Baixar como contato"
	},
	additionalDetails: {
		en: "Additional Details",
		ru: "Дополнительные детали",
		hy: "Լրացուցիչ տեղեկություն",
		tr: "Ek Detaylar",
		ar: "تفاصيل إضافية",
		zh: "附加细节",
		fa: "جزئیات اضافی",
		he: "פרטים נוספים",
		fr: "Détails supplémentaires",
		es: "Detalles adicionales",
		pt: "Detalhes adicionais"
	},
	favorites: {
		en: "Favorites",
		ru: "Избранное",
		hy: "Նախընտրելի",
		tr: "Favoriler",
		ar: "المفضلة",
		zh: "收藏夹",
		fa: "مورد علاقه",
		he: "מועדפים",
		fr: "Favoris",
		es: "Favoritos",
		pt: "Favoritos"
	},
	certificateImages: {
		en: "Certificate Images",
		ru: "Изображения сертификатов",
		hy: "Վկայագրերի պատկերներ",
		tr: "Sertifika Resimleri",
		ar: "صور الشهادات",
		zh: "证书图片",
		fa: "تصاویر گواهی",
		he: "תמונות תעודות",
		fr: "Images de certificat",
		es: "Imágenes de certificado",
		pt: "Imagens de certificado"
	},
	basicInformation: {
		en: "Basic Information",
		ru: "Основная информация",
		hy: "Հիմնական տեղեկատվույթուն",
		tr: "Temel Bilgiler",
		ar: "معلومات أساسية",
		zh: "基本信息",
		fa: "اطلاعات پایه",
		he: "מידע בסיסי",
		fr: "Informations de base",
		es: "Información básica",
		pt: "Informações básicas"
	}
};

export const T = (key, lang) => {
	return Translations[key]?.[lang || "en"] || key;
};
