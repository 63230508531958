import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { T } from "../../helpers/translator";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import Swal from "sweetalert2";
import NProgress from "nprogress";

import SinglePage from "../SinglePage";

export default function ProjectPage() {
	const [isBusy, setBusy] = useState(true);
	let id = useLocation().pathname.split("/")[3];
	const route = config.api.project;
	const { user } = useUser();
	const { lang } = useLang();

	//! global variables
	const [ID, setID] = useState("");
	const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
	const [importance, setImportance] = useState(0); //  0 less , 1 mid , 2 high
	const [mediator, setMediator] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState("");
	const [projectType, setProjectType] = useState(null);
	const [community, setCommunity] = useState(null);
	const [city, setCity] = useState(null);
	const [village, setVillage] = useState(null);
	const [branch, setBranch] = useState(null);
	const [voltage, setVoltage] = useState(null);
	const [orderNumber, setOrderNumber] = useState("");
	const [oldProject, setOldProject] = useState(false);
	const [contractor, setContractor] = useState("");
	const [allResponsible, setAllResponsible] = useState([]);
	const [address, setAddress] = useState({
		en: "",
		ru: "",
		hy: ""
	});
	const [workingDays, setWorkingDays] = useState("");
	const [step, setStep] = useState(1); // current selected step
	const [activeStep, setActiveStep] = useState(undefined); // current selected step
	// all possible steps
	const [steps, setSteps] = useState([]);
	// local valid steps
	const [history, setHistory] = useState([{}]);
	let modelSendToServer = {};
	const [permissions, setPermissions] = useState([]);
	const [isMobile, setIsMobile] = useState("");
	const [projectPartition, setProjectPartition] = useState("");
	const [price, setPrice] = useState("");
	const [disabledSteps, setDisabledSteps] = useState([]);

	const [projectTypes, setProjectTypes] = useState([]);
	const [communities, setCommunities] = useState([]);
	const [cities, setCities] = useState([]);
	const [villages, setVillages] = useState([]);
	const [branches, setBranches] = useState([]);
	const [voltages, setVoltages] = useState([]);
	const [allProjects, setAllProjects] = useState([]);

	// files
	const [files, setFiles] = useState(undefined);

	function setEndDateWorkingDays() {
		let localEndDate;
		let localWorkingDays = 0;
		let StartDate = startDate ? new Date(startDate) : new Date();

		if (voltage?.projectDeadline) {
			// localeeddate is a number but we assign it to a date object
			localEndDate = new Date(StartDate.getTime() + voltage.projectDeadline * 24 * 60 * 60 * 1000);
			// Assuming StartDate and localEndDate are defined and are JavaScript Date objects
			// Calculate the difference in working days
		}
		while (StartDate < localEndDate) {
			// Skips Sunday and Saturday
			if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
				localWorkingDays++;
			}
			StartDate.setDate(StartDate.getDate() + 1);
		}
		setWorkingDays(localWorkingDays);
		setEndDate(localEndDate);
	}

	// hardcoded information
	const projectPartitions = [
		{
			_id: "0",
			name: {
				en: "Main",
				ru: "Основной",
				hy: "Հիմնական",
				tr: "Ana",
				ar: "رئيسي",
				zh: "主要",
				fa: "اصلی",
				he: "ראשי",
				fr: "Principal",
				es: "Principal",
				pt: "Principal"
			}
		},
		{
			_id: "1",
			name: {
				en: "Partition",
				ru: "Подразделение",
				hy: "Հատոր",
				tr: "Bölüm",
				ar: "تقسيم",
				zh: "分区",
				fa: "پارتیشن",
				he: "מחיצה",
				fr: "Partition",
				es: "Partición",
				pt: "Partição"
			}
		},
		{
			_id: "2",
			name: {
				en: "Project change",
				ru: "Изменение проекта",
				hy: "Նախագծի փոփոխություն",
				tr: "Proje değişikliği",
				ar: "تغيير المشروع",
				zh: "项目变更",
				fa: "تغییر پروژه",
				he: "שינוי פרויקט",
				fr: "Changement de projet",
				es: "Cambio de proyecto",
				pt: "Mudança de projeto"
			}
		}
	];

	const contractors = [
		{
			_id: "0",
			name: {
				en: "Arev Qnstrakshn",
				ru: "Арев Кнстркшн",
				hy: "Արև Քնստրաքշն",
				tr: "Arev İnşaat",
				ar: "أريف كنستركشن",
				zh: "阿雷夫建筑",
				fa: "آرو ساخت و ساز",
				he: "ארב קונסטרקשן",
				fr: "Arev Construction",
				es: "Arev Construcción",
				pt: "Arev Construção"
			}
		},
		{
			_id: "1",
			name: {
				en: "Lus Energy",
				ru: "Лус Энерджи",
				hy: "Լույս Էներջի",
				tr: "Lus Enerji",
				ar: "لوس إنرجي",
				zh: "卢斯能源",
				fa: "لوس انرژی",
				he: "לוס אנרגיה",
				fr: "Lus Énergie",
				es: "Lus Energía",
				pt: "Lus Energia"
			}
		},
		{
			_id: "2",
			name: {
				en: "Inteco Energo",
				ru: "Интеко Энерго",
				hy: "Ինտեկո Էներգո",
				tr: "Inteco Enerji",
				ar: "إنتيكو إنرغو",
				zh: "英特科能源",
				fa: "اینتکو انرژی",
				he: "אינטקו אנרגיה",
				fr: "Inteco Énergie",
				es: "Inteco Energía",
				pt: "Inteco Energia"
			}
		},
		{
			_id: "3",
			name: {
				en: "Cascade Energo Service",
				ru: "Каскад Энерго Сервис",
				hy: "Կասկադ Էներգո Սերվիս",
				tr: "Kaskad Enerji Servisi",
				ar: "خدمة الطاقة كاسكيد",
				zh: "级联能源服务",
				fa: "خدمات انرژی کاسکاد",
				he: "שירות אנרגיה קסקייד",
				fr: "Service Énergie Cascade",
				es: "Servicio de Energía en Cascada",
				pt: "Serviço de Energia em Cascata"
			}
		}
	];

	const priorities = [
		{
			_id: "0",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "2",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const importances = [
		{
			_id: "0",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "2",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const disabledStepsArray = [
		{
			name: {
				hy: "Տիմ մուտք պետք չէ",
				en: "No team entry required",
				ru: "Вход команды не требуется",
				tr: "Takım girişi gerekli değil",
				ar: "لا يلزم إدخال الفريق",
				zh: "不需要团队输入",
				fa: "ورود تیم لازم نیست",
				he: "אין צורך בכניסת צוות",
				fr: "Pas d'entrée d'équipe requise",
				es: "No se requiere entrada de equipo",
				pt: "Nenhuma entrada de equipe necessária"
			},
			steps: [10, 13, 14, 17, 18, 19, 20, 39, 40, 41, 42, 43, 44]
		},
		{
			name: {
				hy: "Տեղազննում պետք չէ",
				en: "No site inspection required",
				ru: "Осмотр сайта не требуется",
				tr: "Site denetimi gerekli değil",
				ar: "لا يلزم التفتيش على الموقع",
				zh: "不需要现场检查",
				fa: "بازرسی سایت لازم نیست",
				he: "אין צורך בבדיקת אתר",
				fr: "Pas d'inspection de site requise",
				es: "No se requiere inspección del sitio",
				pt: "Nenhuma inspeção no local necessária"
			},
			steps: [1]
		},
		{
			name: {
				hy: "Գրունտ պետք չէ",
				en: "No soil required",
				ru: "Почва не требуется",
				tr: "Toprak gerekli değil",
				ar: "لا يلزم التربة",
				zh: "不需要土壤",
				fa: "خاک لازم نیست",
				he: "אין צורך באדמה",
				fr: "Pas de sol requis",
				es: "No se requiere suelo",
				pt: "Nenhum solo necessário"
			},
			steps: [2]
		},
		{
			name: {
				hy: "Նախահաշիվ պետք չէ",
				en: "No preliminary estimate required",
				ru: "Предварительная оценка не требуется",
				tr: "Ön tahmin gerekli değil",
				ar: "لا يلزم تقدير أولي",
				zh: "不需要初步估计",
				fa: "برآورد اولیه لازم نیست",
				he: "אין צורך בהערכה מוקדמת",
				fr: "Pas d'estimation préliminaire requise",
				es: "No se requiere estimación preliminar",
				pt: "Nenhuma estimativa preliminar necessária"
			},
			steps: [28, 29, 30, 31, 32, 33]
		},
		{
			name: {
				hy: "Նախնական ՀԷՑ հանձնելու կարիք չկա",
				en: "No need to submit to preliminary HEC",
				ru: "Нет необходимости подавать в предварительный ГЭК",
				tr: "Ön HEC'ye sunmaya gerek yok",
				ar: "لا حاجة لتقديم إلى HEC الأولي",
				zh: "无需提交初步HEC",
				fa: "نیازی به ارسال به HEC اولیه نیست",
				he: "אין צורך להגיש ל-HEC ראשוני",
				fr: "Pas besoin de soumettre au HEC préliminaire",
				es: "No es necesario presentar al HEC preliminar",
				pt: "Não há necessidade de enviar ao HEC preliminar"
			},
			steps: [15, 16]
		},
		{
			name: {
				hy: "Գծագրում պետք չէ",
				en: "No drawing required",
				ru: "Чертеж не требуется",
				tr: "Çizim gerekli değil",
				ar: "لا يلزم الرسم",
				zh: "不需要绘图",
				fa: "نقشه کشی لازم نیست",
				he: "אין צורך בציור",
				fr: "Pas de dessin requis",
				es: "No se requiere dibujo",
				pt: "Nenhum desenho necessário"
			},
			steps: [4, 5, 6, 7, 8, 9, 11, 12]
		}
	];

	modelSendToServer = {
		ID,
		history: history ? history : [],
		priority,
		importance,
		mediator,
		startDate,
		endDate,
		projectType,
		community,
		city,
		village,
		branch,
		contractor,
		// substation,
		voltage,
		orderNumber,
		address,
		workingDays,
		projectPartition,
		price,
		oldProject,
		disabledSteps,
		steps: steps ? steps.filter((item) => item._id) : []
	};

	useEffect(() => {
		(async () => {
			NProgress.start();

			// get all project types
			try {
				let result = await API.get({
					route: config.api.projectType
				});
				setProjectTypes(result);
			} catch (error) {
				console.error(error);
			}

			// get all communities
			try {
				let result = await API.get({
					route: config.api.community
				});
				setCommunities(result);
			} catch (error) {
				console.error(error);
			}

			// get all cities
			try {
				let result = await API.get({
					route: config.api.city
				});
				setCities(result);
			} catch (error) {
				console.error(error);
			}

			// get all villages
			try {
				let result = await API.get({
					route: config.api.village
				});
				setVillages(result);
			} catch (error) {
				console.error(error);
			}

			// get all branches
			try {
				let result = await API.get({
					route: config.api.branch
				});
				setBranches(result);
			} catch (error) {
				console.error(error);
			}

			// get all voltages
			try {
				let result = await API.get({
					route: config.api.voltage
				});
				setVoltages(result);
			} catch (error) {
				console.error(error);
			}

			// get all projects
			try {
				let result = await API.get({
					route: config.api.project
				});
				setAllProjects(result);
			} catch (error) {
				console.error(error);
			}

			try {
				let currentProject = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					populate: [
						"steps",
						{
							path: "steps",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"city",
						"village",
						"branch",
						{
							path: "branch",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"voltage",
						{
							path: "voltage",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"projectType",
						{
							path: "projectType",
							populate: {
								path: "steps.responsible"
							}
						},
						"community"
					]
				});

				//! set All fields
				setID(currentProject[0]?.ID);
				// set all states from server
				currentProject[0]?.startDate && setStartDate(new Date(currentProject[0]?.startDate));
				currentProject[0]?.endDate && setEndDate(new Date(currentProject[0]?.endDate));
				currentProject[0]?.priority && setPriority(currentProject[0]?.priority);
				currentProject[0]?.importance && setImportance(currentProject[0]?.importance);
				currentProject[0]?.mediator && setMediator(currentProject[0]?.mediator);
				currentProject[0]?.address && setAddress(currentProject[0]?.address);
				currentProject[0]?.orderNumber && setOrderNumber(currentProject[0]?.orderNumber);
				currentProject[0]?.workingDays && setWorkingDays(currentProject[0]?.workingDays);
				currentProject[0]?.price && setPrice(currentProject[0]?.price);
				currentProject[0]?.disabledSteps && setDisabledSteps(currentProject[0]?.disabledSteps);
				currentProject[0]?.projectPartition &&
					setProjectPartition(currentProject[0]?.projectPartition);
				currentProject[0]?.community && setCommunity(currentProject[0]?.community);
				currentProject[0]?.branch && setBranch(currentProject[0]?.branch);
				currentProject[0]?.city && setCity(currentProject[0]?.city);
				currentProject[0]?.village && setVillage(currentProject[0]?.village);
				currentProject[0]?.voltage && setVoltage(currentProject[0]?.voltage);
				currentProject[0]?.history && setHistory(currentProject[0]?.history);
				currentProject[0]?.projectType && setProjectType(currentProject[0]?.projectType);
				currentProject[0]?.steps && setSteps(currentProject[0]?.steps);
				currentProject[0]?.oldProject && setOldProject(currentProject[0]?.oldProject);
				currentProject[0]?.contractor && setContractor(currentProject[0]?.contractor);

				let branchSteps = currentProject[0]?.branch?.steps;
				let voltageSteps = currentProject[0]?.voltage?.steps;
				let progectTypeSteps = currentProject[0]?.projectType?.steps;
				let ifThereIsStepsWithResponsibles = currentProject[0]?.steps?.flatMap(
					(step) => step.steps || []
				);
				let combinedArray = [
					...branchSteps,
					...voltageSteps,
					...progectTypeSteps,
					...ifThereIsStepsWithResponsibles
				];

				// remove duplicates and sort by index
				combinedArray = config.sortAndRemoveDuplicates(combinedArray);
				combinedArray.sort((a, b) => Number(a.index) - Number(b.index));

				if (combinedArray.length > allResponsible.length) {
					setAllResponsible(combinedArray);
				}

				currentProject[0]?.steps?.forEach((step, index) => {
					if (step.status === "inProgress" && step.bypass !== true) {
						setStep(step.queue);
						setActiveStep(step.queue);
					}
				});

				setFiles([]);
				currentProject[0]?.uploads?.map((item) => {
					if (item.destiny === "files") {
						setFiles((prev) => [...prev, item]);
					}
					return item;
				});

				setBusy(false);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: T("somethingWentWrong", lang)
				});
				history.push("/projects");
				setBusy(false);
			}
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "project") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		setIsMobile(window.innerWidth < 768 ? true : false);
	}, []);

	useEffect(() => {
		setEndDateWorkingDays();
		// eslint-disable-next-line
	}, [voltage]);

	// handle page resize
	useEffect(() => {
		// page resize event listener
		window.addEventListener("resize", () => {
			setIsMobile(window.innerWidth < 768 ? true : false);
		});
	});

	if (isBusy && !(allResponsible?.length > 0)) return <Loading />;
	return (
		<SinglePage
			isBusy={isBusy}
			setBusy={setBusy}
			route={route}
			id={id}
			permissions={permissions}
			modelSendToServer={modelSendToServer}
			connectedRoute={config.api.user}
			permissionModel="project"
			editPathName="projects"
			lang={lang}
			connectedData={{
				field: "project",
				value: id
			}}
			inputs={[
				{
					value: ID,
					setter: setID,
					type: "text",
					usageType: "textfield",
					label: T("ID", lang),
					placeholder: T("ID", lang),
					permissionModel: "ID"
				},
				{
					value: priority,
					setter: setPriority,
					type: "optionList-simple",
					usageType: "optionList-simple",
					optionListValue: priorities,
					label: T("priority", lang),
					placeholder: T("priority", lang),
					permissionModel: "priority"
				},
				{
					value: importance,
					setter: setImportance,
					type: "optionList-simple",
					usageType: "optionList-simple",
					optionListValue: importances,
					label: T("importance", lang),
					placeholder: T("importance", lang),
					permissionModel: "importance"
				},
				{
					value: mediator,
					setter: setMediator,
					type: "textfield",
					usageType: "textfield",
					label: T("mediator", lang),
					placeholder: T("mediator", lang),
					permissionModel: "mediator"
				},
				{
					value: startDate,
					setter: setStartDate,
					type: "datePicker",
					usageType: "datePicker",
					label: T("startDate", lang),
					placeholder: T("startDate", lang),
					permissionModel: "startDate"
				},
				{
					value: endDate,
					setter: setEndDate,
					type: "datePicker",
					usageType: "datePicker",
					label: T("endDate", lang),
					placeholder: T("endDate", lang),
					permissionModel: "endDate"
				},
				{
					value: projectType?._id,
					setter: setProjectType,
					optionListValue: projectTypes,
					type: "optionList",
					usageType: "optionList",
					label: T("projectType", lang),
					placeholder: T("projectType", lang),
					permissionModel: "projectType"
				},
				{
					value: community?._id,
					setter: setCommunity,
					optionListValue: communities,
					type: "optionList",
					usageType: "optionList",
					label: T("community", lang),
					placeholder: T("community", lang),
					permissionModel: "community"
				},
				{
					value: city?._id,
					setter: setCity,
					optionListValue: cities,
					type: "optionList",
					usageType: "optionList",
					label: T("city", lang),
					placeholder: T("city", lang),
					permissionModel: "city"
				},
				{
					value: village?._id,
					setter: setVillage,
					optionListValue: villages,
					type: "optionList",
					usageType: "optionList",
					label: T("village", lang),
					placeholder: T("village", lang),
					permissionModel: "village"
				},
				{
					value: branch?._id,
					setter: setBranch,
					optionListValue: branches,
					type: "optionList",
					usageType: "optionList",
					label: T("branch", lang),
					placeholder: T("branch", lang),
					permissionModel: "branch"
				},
				{
					value: voltage?._id,
					setter: setVoltage,
					optionListValue: voltages,
					type: "optionList",
					usageType: "optionList",
					label: T("voltage", lang),
					placeholder: T("voltage", lang),
					permissionModel: "voltage"
				},
				{
					value: orderNumber,
					setter: setOrderNumber,
					type: "textfield",
					usageType: "textfield",
					label: T("orderNumber", lang),
					placeholder: T("orderNumber", lang),
					permissionModel: "orderNumber"
				},
				{
					value: address,
					setter: setAddress,
					type: "textfield",
					usageType: "textfield",
					label: T("address", lang),
					placeholder: T("address", lang),
					permissionModel: "address"
				},
				{
					value: workingDays,
					setter: setWorkingDays,
					type: "number",
					usageType: "number",
					label: T("workingDays", lang),
					placeholder: T("workingDays", lang),
					permissionModel: "workingDays"
				},
				{
					value: projectPartition,
					setter: setProjectPartition,
					type: "optionList",
					usageType: "optionList",
					optionListValue: projectPartitions,
					label: T("projectPartition", lang),
					placeholder: T("projectPartition", lang),
					permissionModel: "projectPartition"
				},
				{
					value: price,
					setter: setPrice,
					type: "number",
					usageType: "number",
					label: T("price", lang),
					placeholder: T("price", lang),
					permissionModel: "price"
				},
				{
					value: oldProject,
					setter: setOldProject,
					type: "checkbox",
					usageType: "checkbox",
					label: T("oldProject", lang),
					placeholder: T("oldProject", lang),
					permissionModel: "oldProject"
				},
				{
					value: contractor,
					setter: setContractor,
					type: "optionList",
					usageType: "optionList",
					optionListValue: contractors,
					label: T("contractor", lang),
					placeholder: T("contractor", lang),
					permissionModel: "contractor"
				},
				{
					divider: {
						label: T("bypassSteps", lang)
					}
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[0],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[1],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[2],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[3],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[4],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				},
				{
					value: disabledStepsArray?.map((bypass, i) => {
						const allStepsIncluded = bypass.steps.every((step) => disabledSteps.includes(step));
						return allStepsIncluded ? `${bypass.name[lang]} - ( ${[...bypass.steps]} )` : null;
					})[5],
					disabled: true,
					type: "textfield",
					usageType: "textfield",
					label: T("bypassSteps", lang),
					placeholder: T("bypassSteps", lang),
					permissionModel: "bypassSteps"
				}
			]}
			filesComponent={[
				{
					destiny: "files",
					files: files,
					setFiles: setFiles,
					filesUploadLimit: 3,
					filesMaxSize: 5,
					adminMode: user.role !== "editor",
					acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
					height: 500,
					permissionModel: "files",
					placeholder: T("files", lang)
				}
			]}
		/>
	);
}
