import React from "react";
import { Button, Group } from "@mantine/core";
import { T, Translations } from "../../helpers/translator";
import { IconArrowBarLeft, IconArrowBarRight, IconDeviceFloppy } from "@tabler/icons-react";

const InProgressBypassButtons = ({
	step,
	localSteps,
	updateItem,
	modelSendToServer,
	otherFields,
	filesComponent,
	colorScheme,
	canGoForward,
	lang
}) => {
	const currentStep = localSteps[step];
	const previousStep = localSteps[step - 2];

	return (
		<Group justify="center" mb={20} grow>
			<Button
				variant="light"
				color="orange"
				disabled={!previousStep?.bypass || previousStep?.disabled}
				leftSection={
					<IconArrowBarLeft size={20} color={colorScheme === "dark" ? "white" : "black"} />
				}
				onClick={() =>
					updateItem({
						modelSendToServer,
						routeState: "backward",
						otherFields,
						orangeLine: true,
						filesComponent
					})
				}
			>
				{T("previousStep", lang)}
			</Button>

			{currentStep?.bypass || currentStep?.disabled ? (
				<Button
					variant="light"
					color="green"
					disabled={!currentStep?.bypass || !canGoForward || currentStep?.disabled}
					rightSection={
						<IconArrowBarRight size={20} color={colorScheme === "dark" ? "white" : "black"} />
					}
					onClick={() =>
						updateItem({
							modelSendToServer,
							routeState: "forward",
							otherFields,
							orangeLine: true,
							filesComponent
						})
					}
				>
					{T("nextStep", lang)}
				</Button>
			) : (
				<Button
					variant="light"
					color="green"
					disabled={currentStep?.bypass || currentStep?.disabled}
					rightSection={
						<IconDeviceFloppy size={20} color={colorScheme === "dark" ? "white" : "black"} />
					}
					onClick={() =>
						updateItem({
							modelSendToServer,
							routeState: "stay",
							otherFields,
							orangeLine: true,
							filesComponent
						})
					}
				>
					{T("update", lang)}
				</Button>
			)}
		</Group>
	);
};

export default InProgressBypassButtons;
