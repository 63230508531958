import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { T } from "../../helpers/translator";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import Stepper from "./modules/stepper";
import Swal from "sweetalert2";
import { useDisclosure } from "@mantine/hooks";
import NProgress from "nprogress";

import { Card, useMantineTheme, useMantineColorScheme, Title, Flex } from "@mantine/core";
import classes from "./style.module.css";

import Step1 from "./modules/step1";
import Step2 from "./modules/step2";
import Step3 from "./modules/step3";
import Step4 from "./modules/step4";
import Step5 from "./modules/step5";
import Step6 from "./modules/step6";
import Step7 from "./modules/step7";
import Step8 from "./modules/step8";
import Step9 from "./modules/step9";
import Step10 from "./modules/step10";
import Step11 from "./modules/step11";
import Step12 from "./modules/step12";
import Step13 from "./modules/step13";
import Step14 from "./modules/step14";
import Step15 from "./modules/step15";
import Step16 from "./modules/step16";
import Step17 from "./modules/step17";
import Step18 from "./modules/step18";
import Step19 from "./modules/step19";
import Step20 from "./modules/step20";
import Step21 from "./modules/step21";
import Step22 from "./modules/step22";
import Step23 from "./modules/step23";
import Step24 from "./modules/step24";
import Step25 from "./modules/step25";
import Step26 from "./modules/step26";
import Step27 from "./modules/step27";
import Step28 from "./modules/step28";
import Step29 from "./modules/step29";
import Step30 from "./modules/step30";
import Step31 from "./modules/step31";
import Step32 from "./modules/step32";
import Step33 from "./modules/step33";
import Step34 from "./modules/step34";
import Step35 from "./modules/step35";
import Step36 from "./modules/step36";
import Step37 from "./modules/step37";
import Step38 from "./modules/step38";
import Step39 from "./modules/step39";
import Step40 from "./modules/step40";
import Step41 from "./modules/step41";
import Step42 from "./modules/step42";
import Step43 from "./modules/step43";
import Step44 from "./modules/step44";
import Step45 from "./modules/step45";

export default function ProjectPage() {
	const [isBusy, setBusy] = useState(true);
	let id = useLocation().pathname.split("/")[3];
	const route = config.api.project;
	const { user } = useUser();
	const { lang } = useLang();
	const [openedInner, ToggleInner] = useDisclosure(true);
	//! global variables
	const [ID, setID] = useState("");
	const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
	const [importance, setImportance] = useState(0); //  0 less , 1 mid , 2 high
	const [mediator, setMediator] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState("");
	const [projectType, setProjectType] = useState(null);
	const [projectTypes, setProjectTypes] = useState("");
	const [community, setCommunity] = useState(null);
	const [city, setCity] = useState(null);
	const [village, setVillage] = useState(null);
	const [branch, setBranch] = useState(null);
	const [voltage, setVoltage] = useState(null);
	const [orderNumber, setOrderNumber] = useState("");
	const [oldProject, setOldProject] = useState(false);
	const [contractor, setContractor] = useState("");
	const [allResponsible, setAllResponsible] = useState([]);

	const [address, setAddress] = useState({
		en: "",
		ru: "",
		hy: ""
	});
	const [workingDays, setWorkingDays] = useState("");
	const [step, setStep] = useState(1); // current selected step
	const [activeStep, setActiveStep] = useState(undefined); // current selected step
	// all possible steps
	const [steps, setSteps] = useState([]);
	// local valid steps
	const [localSteps, setLocalSteps] = useState([]);
	const [history, setHistory] = useState([{}]);
	const [users, setUsers] = useState([{}]);
	let modelSendToServer = {};
	const theme = useMantineTheme();
	const { colorScheme } = useMantineColorScheme();
	const [permissions, setPermissions] = useState([]);
	const [isMobile, setIsMobile] = useState("");
	const [projectPartition, setProjectPartition] = useState("");
	const [price, setPrice] = useState("");
	const [disabledSteps, setDisabledSteps] = useState([]);

	function setEndDateWorkingDays() {
		let localEndDate;
		let localWorkingDays = 0;
		let StartDate = startDate ? new Date(startDate) : new Date();

		if (voltage?.projectDeadline) {
			// localeeddate is a number but we assign it to a date object
			localEndDate = new Date(StartDate.getTime() + voltage.projectDeadline * 24 * 60 * 60 * 1000);
			// Assuming StartDate and localEndDate are defined and are JavaScript Date objects
			// Calculate the difference in working days
		}
		while (StartDate < localEndDate) {
			// Skips Sunday and Saturday
			if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
				localWorkingDays++;
			}
			StartDate.setDate(StartDate.getDate() + 1);
		}
		setWorkingDays(localWorkingDays);
		setEndDate(localEndDate);
	}

	const handleStepChange = (step) => {
		setStep(step._id);
	};

	async function UpdateStep({ step, modelSendToServer, backward = false }) {
		let Next, Previous, Current;
		if (backward) {
			try {
				Previous = await API.update(config.api.step, modelSendToServer.steps[step - 2]._id, {
					status: "inProgress",
					startDate: new Date(),
					endDate: null,
					history: [
						...modelSendToServer.steps[step - 2].history,
						{
							status: "inProgress",
							startDate: new Date(),
							user: user._id
						}
					]
				});
				Current = await API.update(config.api.step, modelSendToServer.steps[step - 1]._id, {
					status: null,
					startDate: null,
					endDate: null,
					desc: {
						en: modelSendToServer.steps[step - 1].desc.en,
						ru: modelSendToServer.steps[step - 1].desc.ru,
						hy: modelSendToServer.steps[step - 1].desc.hy
					},
					history: [
						...modelSendToServer.steps[step - 1].history,
						{
							status: null,
							startDate: null,
							endDate: null,
							user: user._id
						}
					]
				});
				if (Previous && Current) {
					setStep(step - 1);
					return { Previous, Current };
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong!"
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!"
				});
			}
		} else {
			try {
				Current = await API.update(config.api.step, modelSendToServer.steps[step - 1]._id, {
					status: "done",
					endDate: new Date(),
					desc: {
						en: modelSendToServer.steps[step - 1].desc.en,
						ru: modelSendToServer.steps[step - 1].desc.ru,
						hy: modelSendToServer.steps[step - 1].desc.hy
					},
					history: [
						...modelSendToServer.steps[step - 1].history,
						{
							status: "done",
							endDate: new Date(),
							user: user._id
						}
					]
				});
				try {
					// test if next step exists
					let [ifStepDoesExists] = await API.get({
						route: config.api.step,
						query: {
							project_id: id,
							queue: step + 1
						}
					});
					// if exists update it, if not create it
					if (ifStepDoesExists) {
						Next = await API.update(config.api.step, ifStepDoesExists._id, {
							status: "inProgress",
							queue: step + 1,
							project_id: id,
							name: {
								en: localSteps[step].name.en,
								ru: localSteps[step].name.ru,
								hy: localSteps[step].name.hy
							},
							desc: {
								en: localSteps[step].desc.en,
								ru: localSteps[step].desc.ru,
								hy: localSteps[step].desc.hy
							},
							startDate: new Date(),
							createdByUser: user._id,
							history: [
								{
									status: "inProgress",
									startDate: new Date(),
									user: user._id
								}
							]
						});
					} else {
						Next = await API.post(config.api.step, {
							status: "inProgress",
							queue: step + 1,
							project_id: id,
							name: {
								en: localSteps[step].name.en,
								ru: localSteps[step].name.ru,
								hy: localSteps[step].name.hy
							},
							desc: {
								en: localSteps[step].desc.en,
								ru: localSteps[step].desc.ru,
								hy: localSteps[step].desc.hy
							},
							startDate: new Date(),
							createdByUser: user._id,
							history: [
								{
									status: "inProgress",
									startDate: new Date(),
									user: user._id
								}
							]
						});
					}
					if (Current && Next) {
						setStep(step + 1);
						return { Current, Next };
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong!"
						});
					}
				} catch (error) {
					console.error(error);
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!"
				});
			}
		}
	}

	modelSendToServer = {
		ID,
		history: history ? history : [],
		priority,
		importance,
		mediator,
		startDate,
		endDate,
		projectType,
		community,
		city,
		village,
		branch,
		contractor,
		// substation,
		voltage,
		orderNumber,
		address,
		workingDays,
		projectPartition,
		price,
		oldProject,
		disabledSteps,
		steps: steps ? steps.filter((item) => item._id) : []
	};

	const HandlePermissionCheck = (input) => {
		if (user?.ananunaki) {
			input.crud = {
				create: true,
				read: true,
				update: true,
				delete: true
			};
			return input;
		}
		let foundedPermission = user.role.permissions.find(
			(permission) => permission.name === "project"
		);
		if (foundedPermission) {
			let foundedInput = foundedPermission.inputs?.find(
				(inp) => inp.name === input?.permissionModel
			);
			if (foundedInput && foundedInput.read) {
				input.crud = {
					create: foundedInput.create !== undefined ? foundedInput.create : false,
					read: foundedInput.read !== undefined ? foundedInput.read : false,
					update: foundedInput.update !== undefined ? foundedInput.update : false,
					delete: foundedInput.delete !== undefined ? foundedInput.delete : false
				};
				return input;
			}
		} else {
			console.error("Permission not found");
		}
	};

	useEffect(() => {
		(async () => {
			NProgress.start();

			try {
				let currentProject = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					populate: [
						"steps",
						{
							path: "steps",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"city",
						"village",
						"branch",
						{
							path: "branch",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"voltage",
						{
							path: "voltage",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"projectType",
						{
							path: "projectType",
							populate: {
								path: "steps.responsible"
							}
						},
						"community"
					]
				});

				//! set All fields
				setID(currentProject[0]?.ID);
				// setting steps including logic both with static and dynamic steps
				const updatedSteps = config.steps.map((step) => ({
					...step,
					disabled: currentProject[0]?.disabledSteps.includes(step._id) // Add "disabled: true" if step is in disabledSteps
				}));
				setLocalSteps(updatedSteps);
				// set all states from server
				currentProject[0]?.startDate && setStartDate(new Date(currentProject[0]?.startDate));
				currentProject[0]?.endDate && setEndDate(new Date(currentProject[0]?.endDate));
				currentProject[0]?.priority && setPriority(currentProject[0]?.priority);
				currentProject[0]?.importance && setImportance(currentProject[0]?.importance);
				currentProject[0]?.mediator && setMediator(currentProject[0]?.mediator);
				currentProject[0]?.address && setAddress(currentProject[0]?.address);
				currentProject[0]?.orderNumber && setOrderNumber(currentProject[0]?.orderNumber);
				currentProject[0]?.workingDays && setWorkingDays(currentProject[0]?.workingDays);
				currentProject[0]?.price && setPrice(currentProject[0]?.price);
				currentProject[0]?.disabledSteps && setDisabledSteps(currentProject[0]?.disabledSteps);
				currentProject[0]?.projectPartition &&
					setProjectPartition(currentProject[0]?.projectPartition);
				currentProject[0]?.community && setCommunity(currentProject[0]?.community);
				currentProject[0]?.branch && setBranch(currentProject[0]?.branch);
				currentProject[0]?.city && setCity(currentProject[0]?.city);
				currentProject[0]?.village && setVillage(currentProject[0]?.village);
				currentProject[0]?.voltage && setVoltage(currentProject[0]?.voltage);
				currentProject[0]?.history && setHistory(currentProject[0]?.history);
				currentProject[0]?.projectType && setProjectType(currentProject[0]?.projectType);
				currentProject[0]?.steps && setSteps(currentProject[0]?.steps);
				currentProject[0]?.oldProject && setOldProject(currentProject[0]?.oldProject);
				currentProject[0]?.contractor && setContractor(currentProject[0]?.contractor);

				// currentProject[0]?.substation && setSubstation(currentProject[0]?.substation)

				let branchSteps = currentProject[0]?.branch?.steps;
				let voltageSteps = currentProject[0]?.voltage?.steps;
				let progectTypeSteps = currentProject[0]?.projectType?.steps;
				let ifThereIsStepsWithResponsibles = currentProject[0]?.steps?.flatMap(
					(step) => step.steps || []
				);
				let combinedArray = [
					...branchSteps,
					...voltageSteps,
					...progectTypeSteps,
					...ifThereIsStepsWithResponsibles
				];

				// remove duplicates and sort by index
				combinedArray = config.sortAndRemoveDuplicates(combinedArray);
				combinedArray.sort((a, b) => Number(a.index) - Number(b.index));

				if (combinedArray.length > allResponsible.length) {
					setAllResponsible(combinedArray);
				}

				currentProject[0]?.steps?.forEach((step, index) => {
					if (step.status === "inProgress" && step.bypass !== true) {
						setStep(step.queue);
						setActiveStep(step.queue);
					}
				});
				setBusy(false);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: T("somethingWentWrong", lang)
				});
				history.push("/projects");
				setBusy(false);
			}
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "project") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		setIsMobile(window.innerWidth < 768 ? true : false);
	}, []);

	useEffect(() => {
		setEndDateWorkingDays();
		// eslint-disable-next-line
	}, [voltage]);

	// handle page resize
	useEffect(() => {
		// page resize event listerner
		window.addEventListener("resize", () => {
			setIsMobile(window.innerWidth < 768 ? true : false);
		});
	});

	const stepComponents = {
		1: Step1,
		2: Step2,
		3: Step3,
		4: Step4,
		5: Step5,
		6: Step6,
		7: Step7,
		8: Step8,
		9: Step9,
		10: Step10,
		11: Step11,
		12: Step12,
		13: Step13,
		14: Step14,
		15: Step15,
		16: Step16,
		17: Step17,
		18: Step18,
		19: Step19,
		20: Step20,
		21: Step21,
		22: Step22,
		23: Step23,
		24: Step24,
		25: Step25,
		26: Step26,
		27: Step27,
		28: Step28,
		29: Step29,
		30: Step30,
		31: Step31,
		32: Step32,
		33: Step33,
		34: Step34,
		35: Step35,
		36: Step36,
		37: Step37,
		38: Step38,
		39: Step39,
		40: Step40,
		41: Step41,
		42: Step42,
		43: Step43,
		44: Step44,
		45: Step45
	};

	const Component = stepComponents[step];
	const hasPermission =
		user.ananunaki || permissions.some((p) => p.name === `step${step}` && p.read);

	if (isBusy && !(allResponsible?.length > 0)) return <Loading />;
	return (
		<>
			<Flex mb={20} direction={"column"}>
				<Title order={2} mb={10} mt={10} style={{ textAlign: "center" }}>
					{localSteps[step - 1]?.name[lang]}
				</Title>
				<Card shadow="sm" className={classes.card}>
					<Stepper
						localSteps={localSteps}
						activeStep={activeStep}
						handleStepChange={handleStepChange}
						modelSendToServer={modelSendToServer}
						step={step}
						lang={lang}
						user={user}
						permissions={permissions}
						toggleInner={ToggleInner}
						openedInner={openedInner}
					/>
				</Card>
			</Flex>
			{Component && hasPermission ? (
				<Component
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					importance={importance}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
					disabledSteps={disabledSteps}
					setDisabledSteps={setDisabledSteps}
					allResponsible={allResponsible}
					setAllResponsible={setAllResponsible}
				/>
			) : (
				<div>Step {step} is not found</div>
			)}
		</>
	);
}
